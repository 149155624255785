import React, { FC } from 'react'

const Logo: FC = () => {
  return (
    <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M24.2716 23.9473C22.6415 25.7552 19.8554 25.9034 18.0327 24.2733C16.2247 22.6431 16.0765 19.8571 17.7066 18.0343C19.3368 16.2263 22.1376 16.0781 23.9456 17.7231C25.7684 19.3532 25.9018 22.1393 24.2716 23.9473Z" fill="url(#paint0_radial_13250_146239)"/>
    <path d="M8.37268 4.2241C5.48289 6.40255 3.18587 9.30716 1.71875 12.6712C3.46744 13.1009 8.68388 14.8052 11.0846 21.2813C11.8108 21.0442 13.3668 20.1402 14.4338 16.6428C15.8861 11.9598 18.6277 7.45474 22.1844 5.94315C18.6425 2.41613 13.9596 1.58624 8.37268 4.2241Z" fill="url(#paint1_linear_13250_146239)"/>
    <path d="M37.1801 7.60237C33.327 2.94907 27.5178 0 20.9972 0C16.255 0 11.8833 1.57086 8.37109 4.22354C16.1513 0.577958 22.1383 3.5863 25.7987 11.1887C31.6376 12.226 35.6092 9.32142 37.1801 7.60237Z" fill="url(#paint2_linear_13250_146239)"/>
    <path d="M12.7871 40.3239C16.1214 41.7317 19.7818 42.28 23.4274 41.8651C22.9235 40.1312 21.7825 34.7666 26.1986 29.4464C25.6355 28.9425 24.0795 28.0534 20.508 28.8536C15.7361 29.9354 10.4604 29.8169 7.36315 27.4902C6.08868 32.3362 7.71882 36.7968 12.7871 40.3239Z" fill="url(#paint3_linear_13250_146239)"/>
    <path d="M1.31821 13.6777C-0.786145 19.3388 -0.430478 25.8593 2.81498 31.4907C5.18609 35.5957 8.74275 38.604 12.7885 40.3231C5.74922 35.4178 5.3491 28.7195 10.1061 21.7395C8.0907 16.1526 3.58559 14.1816 1.31821 13.6777Z" fill="url(#paint4_linear_13250_146239)"/>
    <path d="M41.8496 18.45C41.405 14.8637 40.0416 11.4108 37.8632 8.47656C36.6183 9.76585 32.5282 13.4559 25.7261 12.2852C25.563 13.0261 25.5779 14.8193 28.0675 17.5164C31.4019 21.1175 33.9212 25.7412 33.4618 29.5794C38.2633 28.2457 41.3161 24.6001 41.8496 18.45Z" fill="url(#paint5_linear_13250_146239)"/>
    <path d="M24.5088 41.702C30.4662 40.6943 35.9346 37.1377 39.18 31.4915C41.5511 27.3865 42.381 22.8073 41.8327 18.4355C41.1066 26.9864 35.5048 30.6764 27.0874 30.054C23.264 34.5887 23.7975 39.494 24.5088 41.702Z" fill="url(#paint6_linear_13250_146239)"/>
    <defs>
    <radialGradient id="paint0_radial_13250_146239" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(19.576 18.5303) scale(6.21032 6.21032)">
    <stop stopColor="#4495D1"/>
    <stop offset="1" stopColor="#15256D"/>
    </radialGradient>
    <linearGradient id="paint1_linear_13250_146239" x1="6.10662" y1="18.0303" x2="16.5499" y2="1.94905" gradientUnits="userSpaceOnUse">
    <stop stopColor="#4495D1"/>
    <stop offset="1" stopColor="#15256D"/>
    </linearGradient>
    <linearGradient id="paint2_linear_13250_146239" x1="10.2735" y1="-0.503638" x2="36.216" y2="9.97783" gradientUnits="userSpaceOnUse">
    <stop stopColor="#4495D1"/>
    <stop offset="1" stopColor="#15256D"/>
    </linearGradient>
    <linearGradient id="paint3_linear_13250_146239" x1="25.8756" y1="35.3849" x2="6.72879" y2="34.3817" gradientUnits="userSpaceOnUse">
    <stop stopColor="#4495D1"/>
    <stop offset="1" stopColor="#15256D"/>
    </linearGradient>
    <linearGradient id="paint4_linear_13250_146239" x1="7.74428" y1="41.0412" x2="3.84967" y2="13.3322" gradientUnits="userSpaceOnUse">
    <stop stopColor="#4495D1"/>
    <stop offset="1" stopColor="#15256D"/>
    </linearGradient>
    <linearGradient id="paint5_linear_13250_146239" x1="31.0215" y1="9.57591" x2="39.7262" y2="26.6604" gradientUnits="userSpaceOnUse">
    <stop stopColor="#4495D1"/>
    <stop offset="1" stopColor="#15256D"/>
    </linearGradient>
    <linearGradient id="paint6_linear_13250_146239" x1="44.985" y1="22.4526" x2="22.9359" y2="39.6788" gradientUnits="userSpaceOnUse">
    <stop stopColor="#4495D1"/>
    <stop offset="1" stopColor="#15256D"/>
    </linearGradient>
    </defs>
    </svg>
  )
}

export default Logo
