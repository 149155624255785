import { PayloadAction } from '@reduxjs/toolkit'
import { Modal } from 'antd'
import { accountActions } from '../../../../store/slices/accountSlice'
import { addCountlyEvent } from '../../../../utils/stats'
import * as Models from '../../../../api/model/models'
import { useDispatch, useSelector } from 'react-redux'
import useNeedCaptcha from '../../../../hooks/useNeedCaptcha'
import { Form } from '../../../common'
import { fullNameSplitting } from '../../../../utils/fullNameSplitting'
import { RootState } from '../../../../store/store'
import { useTranslation } from 'react-i18next'

const { useForm } = Form

interface PropsTypes<T> {
  selectTab: (tab: T) => void
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
  fieldOptions?: {
    roleId?: Models.RoleEnum,
  }
}

const useHandleSubmit = (props: PropsTypes<TabHeader>) => {
  const { selectTab, setLoading, fieldOptions } = props
  const { i18n } = useTranslation()

  interface Fields extends Models.RegistrationApplicationPost {
    confirm?: string
    fullName?: string
    parentSection?: string
  }

  const dispatch = useDispatch()
  const { academicDegree, isLecture } = useSelector((state: RootState) => state.account)
  const companyNotExistOption = i18n.resolvedLanguage === "Eng" ? 
    { id: 0, name: 'My organization is not in the list' } : 
    { id: 0, name: 'Моей организации нет в списке' }
  const { needCaptcha, checkCaptchaResponse } = useNeedCaptcha()
  const [form] = useForm()

  const handleSubmit = async (fields: Fields) => {
    if (fields.fullName) {
      const fullName = fullNameSplitting(fields.fullName)

      fields.lastName = fullName.lastName
      fields.firstName = fullName.firstName
      if (fullName.middleName) fields.middleName = fullName.middleName
    }

    if (!fields.role) {
      fields.role = fieldOptions?.roleId
    }

    if (!fields.academicDegree && fieldOptions?.roleId === "conference") {
      fields.academicDegree = academicDegree
    }

    if (!fields.isLecture && fieldOptions?.roleId === "conference") {
      fields.isLecture = isLecture
    }

    if (!fields.section && fields.parentSection) {
      fields.section = Number(fields.parentSection)
    }

    if(fields.section === 999 || fields.section === 0) {
      fields.section = null
    }

    setLoading(true)
    delete fields.confirm
    delete fields.fullName

    // clear company if user set customCompany
    if (fields.company && parseInt(fields.company as unknown as string) === companyNotExistOption.id) {
      delete fields.company
    }

    // sending request
    const payload = { ...fields } as Models.RegistrationApplicationPost
    const response = (await dispatch<unknown>(accountActions.registrationApplication(payload))) as PayloadAction<
      unknown,
      string,
      { payload: void; type: string },
      { message: string }
    >
    setLoading(false)

    if (
      checkCaptchaResponse(response, () => {
        form.scrollToField('captchaValue')
      })
    ) {
      return
    }

    addCountlyEvent(`Регистрация`, { Действие: 'Выполнена попытка регистрации', Результат: 'Неудачно' })

    if (response.type === 'registrationApplication/fulfilled') {
      form.resetFields()
      selectTab('hidden')
      addCountlyEvent(`Регистрация`, { Действие: 'Выполнена попытка регистрации', Результат: 'Удачно' })
      Modal.success({
        content:
          'Заявка на регистрацию успешно отправлена. После подтверждения администратором Вам на почту придет уведомление.',
      })
    }
  }

  return {
    handleSubmit,
    companyNotExistOption,
    needCaptcha,
  }
}

export default useHandleSubmit
