import React from 'react'

const ExhibitionApplications: React.FC<DefaultPropsIcon> = (props) => {
  const { size = 24, onClick, className, color = '#818C99' } = props
  return (
    <span className={['anticon', 'anticon-setting', className || ''].join(' ')} onClick={onClick}>
      <svg width={size} height={size} viewBox={`0 0 24 24`} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.5 0C3.94772 0 3.5 0.447715 3.5 1L3.5 3.5L1 3.5C0.447715 3.5 0 3.94772 0 4.5C0 5.05228 0.447715 5.5 1 5.5L3.5 5.5L3.5 8C3.5 8.55228 3.94772 9 4.5 9C5.05228 9 5.5 8.55228 5.5 8V5.5H8C8.55229 5.5 9 5.05228 9 4.5C9 3.94772 8.55229 3.5 8 3.5L5.5 3.5L5.5 1C5.5 0.447715 5.05228 0 4.5 0Z" fill={color}/>
        <path d="M17.5 7.63336V6.2C17.5 6.08954 17.4105 6 17.3 6H14.7C14.5895 6 14.5 6.08954 14.5 6.2V7.43336C14.5 7.54382 14.4105 7.63336 14.3 7.63336H8.2C8.08954 7.63336 8 7.7229 8 7.83336V9.06672C8 9.17718 8.08954 9.26672 8.2 9.26672H9.3C9.41046 9.26672 9.5 9.35626 9.5 9.46672V18.8669C9.5 18.9774 9.58954 19.0669 9.7 19.0669H14.4393L11.0939 22.7098C11.0237 22.7863 11.0237 22.9039 11.0939 22.9804L11.883 23.8396C11.9623 23.9259 12.0984 23.9259 12.1776 23.8396L16 19.6774L19.8224 23.8396C19.9016 23.9259 20.0378 23.9259 20.117 23.8396L20.9061 22.9804C20.9764 22.9039 20.9764 22.7863 20.9061 22.7098L17.5607 19.0669H22.3C22.4105 19.0669 22.5 18.9774 22.5 18.8669V9.46672C22.5 9.35626 22.5895 9.26672 22.7 9.26672H23.8C23.9105 9.26672 24 9.17718 24 9.06672V7.83336C24 7.7229 23.9105 7.63336 23.8 7.63336H17.5Z" fill={color}/>
      </svg>
    </span>
  )
}

export default ExhibitionApplications
