import { Dispatch, PayloadAction } from '@reduxjs/toolkit'
import { Col, Row, Spin } from 'antd'
import React, { FC, SyntheticEvent, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import * as Models from '../../../api/model/models'
import useNeedCaptcha from '../../../hooks/useNeedCaptcha'
import { fetchLogin, fetchProfile } from '../../../store/slices/accountSlice'
import { validateMessages } from '../../../utils/constants'
import { addCountlyEvent } from '../../../utils/stats'
import { Eye, EyeInvisible } from '../../common/icons'
import { Button, Form, Input } from '../../common/index'
import './login.ant.scss'
import styles from './login.module.scss'
import { useTranslation } from 'react-i18next'

const { Item, useForm } = Form

export interface Props<T> {
  tab: T
  selectTab: (tab: T) => void
  onSubmit?: (
    payload: Models.CustomTokenObtainPair,
    dispatch?: Dispatch<any>,
  ) => Promise<PayloadAction<unknown, string, { payload: void; type: string }, { message: string }> | undefined>
}

const Login: FC<Props<TabHeader>> = (props) => {
  const dispatch = useDispatch()
  const [form] = useForm()
  const [loading, setLoading] = useState(false)
  const { tab, selectTab, onSubmit } = props

  const { t } = useTranslation()

  const { needCaptcha, checkCaptchaResponse } = useNeedCaptcha()

  useEffect(() => {
    return () => {
      if (tab === 'login') form.resetFields()
    }
  }, [form, tab])

  const handleSubmit = async (fields: Models.CustomTokenObtainPair) => {
    setLoading(true)
    if (onSubmit) {
      onSubmit(fields, dispatch)
        .then(() => {
          selectTab('hidden')
          form.resetFields()
          addCountlyEvent(`Авторизация`, { Действие: 'Выполнена попытка авторизации', Результат: 'Удачно' })
        })
        .catch((response) => {
          checkCaptchaResponse(response, () => {
            form.scrollToField('captchaValue')
          })
          addCountlyEvent(`Авторизация`, { Действие: 'Выполнена попытка авторизации', Результат: 'Неудачно' })
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }

  const handleRecovery = (e: SyntheticEvent) => {
    e.preventDefault()
    selectTab('recovery')
  }

  return (
    <Spin spinning={loading} className={styles.spin}>
      <Row justify="start" className="form-login">
        <Col flex="328px" className={styles.form}>
          <Form
            form={form}
            name="logining"
            layout="vertical"
            onFinish={handleSubmit}
            validateMessages={validateMessages}
            autoComplete="off"
          >
            <Item label={t("login")} name="login" rules={[{ required: true, whitespace: true, max: 50 }]}>
              <Input autoComplete="username" placeholder="e-mail" />
            </Item>
            <div className={styles.passwordBox}>
              <Item
                className="login-password__error-and-link-together"
                name="password"
                label={t("password")}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input.Password
                  type="password"
                  autoComplete={'current-password'}
                  placeholder={t("enterThePassword")}
                  iconRender={(visible) => (visible ? <Eye color="#818C99" /> : <EyeInvisible color="#818C99" />)}
                />
              </Item>
              <span className={styles.recovery} onClick={handleRecovery}>
                {t("recoverYourPassword")}
              </span>
            </div>
            {needCaptcha && <Form.Captcha form={form} />}
            <Item>
              <Button type="primary" htmlType="submit" className={styles.button}>
                {t("enter")}
              </Button>
            </Item>
          </Form>
        </Col>
      </Row>
    </Spin>
  )
}

Login.defaultProps = {
  onSubmit: async (payload, dispatch) => {
    if (dispatch) {
      const response = (await dispatch<unknown>(fetchLogin(payload))) as PayloadAction<
        unknown,
        string,
        { payload: void; type: string },
        { message: string }
      >
      if (response.type === 'fetchLogin/fulfilled') {
        dispatch(fetchProfile())
        return Promise.resolve(response)
      } else {
        return Promise.reject(response)
      }
    }
  },
}

export default Login
