import React, { FC } from 'react'

const LogoMNTKEn: FC = () => {
  return (
    <svg width="116" height="42" viewBox="0 0 116 42" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M24.3422 23.9555C22.7049 25.7647 19.9088 25.9069 18.0947 24.2741C16.2806 22.6428 16.135 19.8513 17.7693 18.0421C19.4066 16.233 22.2042 16.0922 24.0183 17.7236C25.8324 19.3549 25.9765 22.1449 24.3422 23.9541V23.9555Z" fill="url(#paint0_radial_13990_38789)"/>
    <path d="M8.39157 4.22254C5.49736 6.39914 3.18407 9.30473 1.72656 12.6637C3.47676 13.0934 8.71546 14.7944 11.1194 21.2664C11.8429 21.0308 13.4015 20.1255 14.4816 16.6287C15.9317 11.9466 18.6818 7.44373 22.249 5.93092C18.6922 2.4208 14.0062 1.5955 8.39157 4.22105V4.22254Z" fill="url(#paint1_linear_13990_38789)"/>
    <path d="M37.2733 7.60848C33.4134 2.96042 27.5819 0 21.0565 0C16.3022 0 11.9148 1.57207 8.39062 4.22282C16.1833 0.576378 22.1901 3.58421 25.8673 11.1882C31.7256 12.2358 35.7044 9.32872 37.2733 7.60848Z" fill="url(#paint2_linear_13990_38789)"/>
    <path d="M12.8239 40.3255C16.1608 41.7375 19.841 42.2828 23.487 41.862C22.9863 40.1358 21.8423 34.7603 26.2609 29.4484C25.6948 28.9417 24.1303 28.0482 20.5527 28.8631C15.7627 29.9522 10.4764 29.8277 7.38014 27.5029C6.11133 32.3303 7.73672 36.7917 12.8239 40.327V40.3255Z" fill="url(#paint3_linear_13990_38789)"/>
    <path d="M1.32211 13.6861C1.32211 13.6861 1.32211 13.6861 1.32063 13.6861C-0.783175 19.3446 -0.438484 25.8626 2.82419 31.4975C5.20137 35.6047 8.7597 38.6066 12.8247 40.3254C5.76149 35.418 5.37074 28.7252 10.134 21.7464C8.11489 16.162 3.60123 14.1795 1.3236 13.6846L1.32211 13.6861Z" fill="url(#paint4_linear_13990_38789)"/>
    <path d="M41.9577 18.4474C41.515 14.8588 40.1481 11.4094 37.9596 8.46973C36.7116 9.76621 32.6154 13.4408 25.7944 12.2806C25.6369 13.023 25.6443 14.8217 28.1404 17.5036C31.4803 21.0967 34.0165 25.724 33.5455 29.5616C38.3711 28.2444 41.4318 24.6098 41.9577 18.4474Z" fill="url(#paint5_linear_13990_38789)"/>
    <path d="M24.5739 41.7004C30.5407 40.6885 36.026 37.1309 39.2887 31.496C41.6659 27.3888 42.4949 22.8148 41.9541 18.4453C41.2231 26.9991 35.6085 30.6841 27.168 30.0588C23.3289 34.5957 23.8638 39.4853 24.5739 41.7004Z" fill="url(#paint6_linear_13990_38789)"/>
    <path d="M48.9673 4.40918H48.5469V37.5902H48.9673V4.40918Z" fill="#414042"/>
    <path d="M58.2912 13.6341C57.8469 13.6341 57.4473 13.5585 57.0907 13.4059C56.7356 13.2548 56.431 13.0369 56.1784 12.7554C55.9259 12.4739 55.7327 12.1287 55.599 11.7227C55.4653 11.3167 55.3984 10.8618 55.3984 10.358C55.3984 9.85427 55.4683 9.39199 55.6079 8.98897C55.7476 8.58595 55.9437 8.24219 56.1963 7.96067C56.4489 7.67915 56.7519 7.46134 57.1041 7.31021C57.4562 7.15908 57.8425 7.08203 58.2629 7.08203C58.7369 7.08203 59.1559 7.16204 59.5169 7.32355C59.8779 7.48505 60.1825 7.70879 60.4291 7.99623C60.6758 8.28368 60.863 8.6304 60.9907 9.03342C61.1185 9.43644 61.1824 9.87798 61.1824 10.358C61.1824 10.8974 61.1096 11.373 60.964 11.782C60.8184 12.1909 60.6178 12.5332 60.3623 12.8058C60.1067 13.0784 59.8022 13.2844 59.45 13.4251C59.0964 13.5644 58.7116 13.6341 58.2912 13.6341ZM58.3729 12.8695C58.9746 12.8695 59.4486 12.6591 59.7918 12.2368C60.135 11.816 60.3073 11.1893 60.3073 10.358C60.3073 9.92095 60.2598 9.5446 60.1662 9.23048C60.0726 8.91488 59.9359 8.65411 59.7606 8.44815C59.5838 8.2422 59.3698 8.08958 59.1172 7.99327C58.8647 7.89696 58.5809 7.84807 58.2644 7.84807C57.9851 7.84807 57.7236 7.89992 57.48 8.00216C57.2363 8.10588 57.0268 8.25998 56.85 8.46593C56.6732 8.67189 56.5335 8.93266 56.4295 9.24826C56.3255 9.56386 56.275 9.93429 56.275 10.358C56.275 10.7818 56.3255 11.1433 56.4251 11.4589C56.5261 11.7745 56.6673 12.0368 56.85 12.2457C57.0327 12.4546 57.2526 12.6117 57.5112 12.7139C57.7697 12.8177 58.0564 12.868 58.3729 12.868V12.8695Z" fill="#414042"/>
    <path d="M64.285 10.0859L67.096 7.17433H68.1271L65.1987 10.1585L68.3187 13.5426H67.2148L64.6059 10.6489L64.2865 10.9586V13.5426H63.4648V7.17285H64.2865V10.0844L64.285 10.0859Z" fill="#414042"/>
    <path d="M70.0898 13.5436V7.17383H72.0332C72.4046 7.17383 72.7211 7.21235 72.9826 7.28792C73.2441 7.36348 73.4565 7.47313 73.6214 7.61537C73.7864 7.75761 73.9037 7.92801 73.9765 8.12507C74.0493 8.32214 74.0865 8.53847 74.0865 8.77554C74.0865 9.15189 73.9825 9.466 73.776 9.71789C73.5694 9.96978 73.2649 10.1387 72.8637 10.2231V10.2409C73.3198 10.3076 73.6645 10.4765 73.8993 10.7462C74.134 11.0159 74.2499 11.3448 74.2499 11.733C74.2499 11.9701 74.2068 12.1968 74.1221 12.4161C74.0374 12.6339 73.9037 12.8265 73.721 12.9939C73.5382 13.1613 73.3035 13.2947 73.0182 13.394C72.733 13.4947 72.3853 13.5436 71.9782 13.5436H70.0898ZM70.9115 9.92236H71.788C72.0614 9.92236 72.2932 9.89569 72.4819 9.84087C72.6706 9.78605 72.8221 9.71048 72.938 9.61269C73.0539 9.51638 73.1371 9.39933 73.1891 9.26301C73.2411 9.1267 73.2663 8.98001 73.2663 8.82147C73.2663 8.53698 73.1668 8.31769 72.9647 8.16656C72.7642 8.01543 72.418 7.93838 71.9247 7.93838H70.9115V9.92236ZM70.9115 12.7791H72.0332C72.9276 12.7791 73.3748 12.4338 73.3748 11.7419C73.3748 11.3418 73.2485 11.0529 72.9959 10.8781C72.7434 10.7017 72.3615 10.6143 71.8504 10.6143H70.91V12.7805L70.9115 12.7791Z" fill="#414042"/>
    <path d="M55.3984 21.5426C55.3984 21.0032 55.4712 20.5232 55.6168 20.1053C55.7624 19.686 55.9719 19.3348 56.2468 19.0504C56.5202 18.7659 56.85 18.5495 57.2363 18.4043C57.6226 18.2591 58.0535 18.1865 58.5274 18.1865C58.8929 18.1865 59.1886 18.2117 59.4174 18.2636C59.6462 18.3154 59.8348 18.3717 59.9879 18.4325L59.8334 19.1511C59.6328 19.0785 59.4292 19.0266 59.2227 18.997C59.0162 18.9674 58.8127 18.9511 58.6121 18.9511C57.8514 18.9511 57.272 19.163 56.8738 19.5882C56.4756 20.0134 56.2765 20.6372 56.2765 21.4625C56.2765 22.2878 56.4905 22.8746 56.9198 23.3147C57.3492 23.7547 57.9465 23.974 58.7131 23.974C58.829 23.974 58.9583 23.9636 59.1009 23.9414C59.2435 23.9207 59.3817 23.891 59.5154 23.8555V22.1086H58.1738V21.344H60.3355V24.3652C60.1231 24.4807 59.8735 24.5711 59.5867 24.6378C59.3015 24.7045 58.9716 24.7386 58.6017 24.7386C58.1203 24.7386 57.6835 24.6689 57.2883 24.5296C56.8931 24.3904 56.5558 24.1844 56.275 23.9103C55.9957 23.6377 55.7788 23.3028 55.6272 22.9042C55.4757 22.5057 55.3984 22.0537 55.3984 21.544V21.5426Z" fill="#414042"/>
    <path d="M63.5286 24.6452H62.707V18.2754H63.5286V24.6452Z" fill="#414042"/>
    <path d="M66.082 24.6452V18.2754H67.9882C68.529 18.2754 68.9956 18.3435 69.3848 18.4799C69.7741 18.6162 70.095 18.8132 70.3476 19.0711C70.6002 19.3289 70.7859 19.6489 70.9047 20.0312C71.0236 20.4135 71.083 20.8506 71.083 21.341C71.083 21.8937 71.0072 22.3752 70.8542 22.7871C70.7027 23.199 70.4843 23.5443 70.202 23.8199C69.9197 24.0955 69.578 24.3014 69.1798 24.4392C68.7816 24.5755 68.3329 24.6437 67.8337 24.6437H66.082V24.6452ZM66.9036 23.8806H67.752C69.3878 23.8806 70.2064 23.0553 70.2064 21.4062C70.2064 20.9884 70.1634 20.6283 70.0787 20.3275C69.994 20.0267 69.8588 19.7823 69.6731 19.5911C69.4873 19.4 69.2466 19.2607 68.9525 19.1718C68.6568 19.0844 68.3002 19.0399 67.8798 19.0399H66.9036V23.8806Z" fill="#414042"/>
    <path d="M73.3623 18.2749H75.2685C75.998 18.2749 76.5507 18.4186 76.9251 18.7076C77.2995 18.9965 77.4867 19.4069 77.4867 19.9403C77.4867 20.1893 77.4511 20.4071 77.3813 20.5952C77.3114 20.7834 77.2149 20.9464 77.0945 21.0872C76.9727 21.2264 76.8301 21.342 76.6651 21.4324C76.5002 21.5228 76.3249 21.5909 76.1362 21.6324C76.2818 21.6873 76.4155 21.8102 76.5374 22.0014C76.6592 22.1925 76.781 22.4266 76.9029 22.7022C77.0247 22.9778 77.1539 23.2845 77.2906 23.6209C77.4273 23.9572 77.5833 24.298 77.7601 24.6447H76.8107C76.6161 24.2565 76.4556 23.9172 76.3323 23.6253C76.209 23.3334 76.102 23.0845 76.0174 22.8741C75.9327 22.6652 75.8569 22.4918 75.7885 22.3555C75.7217 22.2192 75.6459 22.111 75.5597 22.0325C75.4751 21.954 75.374 21.8977 75.2581 21.8636C75.1423 21.831 74.9937 21.8132 74.8109 21.8132H74.181V24.6432H73.3594V18.2734L73.3623 18.2749ZM74.184 21.0501H75.325C75.7143 21.0501 76.0263 20.9731 76.2595 20.8175C76.4928 20.6619 76.6102 20.3982 76.6102 20.0218C76.6102 19.7062 76.5017 19.4632 76.2863 19.2943C76.0708 19.1239 75.7157 19.0395 75.224 19.0395H74.184V21.0501Z" fill="#414042"/>
    <path d="M82.4513 24.7366C82.0071 24.7366 81.6074 24.661 81.2508 24.5084C80.8958 24.3573 80.5912 24.1395 80.3386 23.858C80.086 23.5764 79.8929 23.2312 79.7592 22.8252C79.6255 22.4192 79.5586 21.9644 79.5586 21.4606C79.5586 20.9568 79.6284 20.4945 79.7681 20.0915C79.9077 19.6885 80.1039 19.3447 80.3564 19.0632C80.609 18.7817 80.9121 18.5639 81.2642 18.4127C81.6163 18.2616 82.0026 18.1846 82.4231 18.1846C82.897 18.1846 83.316 18.2646 83.6771 18.4261C84.0381 18.5876 84.3427 18.8113 84.5893 19.0988C84.8359 19.3877 85.0231 19.7329 85.1509 20.136C85.2787 20.539 85.3426 20.9805 85.3426 21.4606C85.3426 21.9999 85.2698 22.4755 85.1242 22.8845C84.9786 23.2934 84.778 23.6357 84.5224 23.9083C84.2669 24.181 83.9623 24.3884 83.6102 24.5277C83.2566 24.667 82.8718 24.7366 82.4513 24.7366ZM82.533 23.9721C83.1348 23.9721 83.6087 23.7617 83.9519 23.3394C84.2951 22.9186 84.4675 22.2918 84.4675 21.4606C84.4675 21.0235 84.4199 20.6471 84.3263 20.3315C84.2327 20.0159 84.096 19.7552 83.9207 19.5492C83.7439 19.3433 83.53 19.1906 83.2774 19.0943C83.0248 18.998 82.741 18.9491 82.4246 18.9491C82.1453 18.9491 81.8838 19.001 81.6401 19.1032C81.3965 19.2069 81.187 19.361 81.0102 19.567C80.8334 19.7729 80.6937 20.0337 80.5897 20.3493C80.4857 20.6649 80.4352 21.0353 80.4352 21.4591C80.4352 21.8829 80.4857 22.2444 80.5852 22.56C80.6848 22.8756 80.8274 23.1379 81.0102 23.3468C81.1929 23.5557 81.4128 23.7128 81.6713 23.815C81.9298 23.9187 82.2166 23.9691 82.533 23.9691V23.9721Z" fill="#414042"/>
    <path d="M88.4466 24.6452H87.625V18.2754H89.5044C89.8699 18.2754 90.1968 18.3139 90.485 18.3895C90.7733 18.465 91.0214 18.5851 91.2279 18.7495C91.4344 18.914 91.5934 19.12 91.7019 19.3689C91.8118 19.6178 91.8668 19.9141 91.8668 20.2609C91.8668 20.9217 91.6528 21.4269 91.2234 21.7751C90.7941 22.1233 90.1968 22.2982 89.4302 22.2982H88.4451V24.6452H88.4466ZM88.4466 21.5336H89.4317C89.9427 21.5336 90.3305 21.4299 90.595 21.224C90.8594 21.018 90.9917 20.7024 90.9917 20.2772C90.9917 19.8519 90.8594 19.5245 90.595 19.3304C90.3305 19.1363 89.9427 19.0385 89.4317 19.0385H88.4466V21.5321V21.5336Z" fill="#414042"/>
    <path d="M93.9639 18.2749H95.8701C96.5996 18.2749 97.1523 18.4186 97.5267 18.7076C97.9011 18.9965 98.0883 19.4069 98.0883 19.9403C98.0883 20.1893 98.0527 20.4071 97.9828 20.5952C97.913 20.7834 97.8164 20.9464 97.6961 21.0872C97.5742 21.2264 97.4316 21.342 97.2667 21.4324C97.1018 21.5228 96.9265 21.5909 96.7378 21.6324C96.8834 21.6873 97.0171 21.8102 97.1389 22.0014C97.2608 22.1925 97.3826 22.4266 97.5044 22.7022C97.6263 22.9778 97.7555 23.2845 97.8922 23.6209C98.0289 23.9572 98.1849 24.298 98.3617 24.6447H97.4123C97.2177 24.2565 97.0572 23.9172 96.9339 23.6253C96.8106 23.3334 96.7036 23.0845 96.6189 22.8741C96.5342 22.6652 96.4585 22.4918 96.3901 22.3555C96.3233 22.2192 96.2475 22.111 96.1613 22.0325C96.0766 21.954 95.9756 21.8977 95.8597 21.8636C95.7438 21.831 95.5952 21.8132 95.4125 21.8132H94.7826V24.6432H93.9609V18.2734L93.9639 18.2749ZM94.7855 21.0501H95.9266C96.3158 21.0501 96.6278 20.9731 96.8611 20.8175C97.0944 20.6619 97.2117 20.3982 97.2117 20.0218C97.2117 19.7062 97.1033 19.4632 96.8878 19.2943C96.6724 19.1239 96.3173 19.0395 95.8255 19.0395H94.7855V21.0501Z" fill="#414042"/>
    <path d="M104.122 24.6452H100.555V18.2754H104.122V19.0399H101.376V20.9691H103.886V21.7159H101.376V23.8821H104.122V24.6467V24.6452Z" fill="#414042"/>
    <path d="M106.357 23.5176C106.503 23.6214 106.702 23.7221 106.954 23.8229C107.207 23.9236 107.497 23.9725 107.825 23.9725C108.251 23.9725 108.566 23.8821 108.774 23.6999C108.981 23.5176 109.085 23.2598 109.085 22.9265C109.085 22.7442 109.063 22.5916 109.017 22.4671C108.971 22.3427 108.898 22.233 108.798 22.1397C108.699 22.0463 108.569 21.9619 108.415 21.8893C108.26 21.8167 108.073 21.7381 107.853 21.6522L107.635 21.5707C107.403 21.4803 107.201 21.384 107.029 21.2847C106.855 21.1855 106.712 21.0669 106.599 20.9306C106.486 20.7943 106.402 20.6343 106.344 20.4535C106.286 20.2713 106.258 20.0594 106.258 19.8164C106.258 19.3008 106.439 18.9007 106.8 18.6147C107.161 18.3302 107.662 18.1865 108.3 18.1865C108.495 18.1865 108.678 18.1999 108.847 18.228C109.018 18.2547 109.168 18.2873 109.299 18.3228C109.43 18.3599 109.538 18.3969 109.623 18.4369C109.707 18.4769 109.768 18.5081 109.806 18.5332L109.596 19.2341C109.364 19.1244 109.131 19.0503 108.893 19.0118C108.656 18.9718 108.44 18.9526 108.246 18.9526C107.886 18.9526 107.611 19.0237 107.419 19.1659C107.228 19.3082 107.133 19.526 107.133 19.8164C107.133 19.9438 107.148 20.0579 107.179 20.1572C107.21 20.2564 107.268 20.3528 107.357 20.4431C107.445 20.5335 107.568 20.6239 107.727 20.7113C107.886 20.7987 108.09 20.8921 108.339 20.9884L108.577 21.0788C108.856 21.1884 109.086 21.304 109.266 21.424C109.446 21.5455 109.587 21.6789 109.691 21.8241C109.795 21.9693 109.866 22.1293 109.905 22.3012C109.945 22.4745 109.965 22.6642 109.965 22.8701C109.965 23.1739 109.914 23.4406 109.814 23.6703C109.715 23.9014 109.572 24.0955 109.39 24.2526C109.207 24.4111 108.99 24.5296 108.737 24.6126C108.485 24.6941 108.207 24.7356 107.902 24.7356C107.562 24.7356 107.238 24.6911 106.931 24.6037C106.623 24.5163 106.357 24.3993 106.133 24.254L106.362 23.5176H106.357Z" fill="#414042"/>
    <path d="M112.06 23.5176C112.206 23.6214 112.405 23.7221 112.658 23.8229C112.91 23.9236 113.2 23.9725 113.528 23.9725C113.955 23.9725 114.27 23.8821 114.478 23.6999C114.684 23.5176 114.788 23.2598 114.788 22.9265C114.788 22.7442 114.766 22.5916 114.72 22.4671C114.674 22.3427 114.601 22.233 114.501 22.1397C114.402 22.0463 114.273 21.9619 114.118 21.8893C113.964 21.8167 113.776 21.7381 113.556 21.6522L113.338 21.5707C113.106 21.4803 112.904 21.384 112.732 21.2847C112.558 21.1855 112.415 21.0669 112.302 20.9306C112.19 20.7943 112.105 20.6343 112.047 20.4535C111.989 20.2713 111.961 20.0594 111.961 19.8164C111.961 19.3008 112.142 18.9007 112.503 18.6147C112.864 18.3302 113.365 18.1865 114.004 18.1865C114.198 18.1865 114.381 18.1999 114.55 18.228C114.721 18.2547 114.871 18.2873 115.002 18.3228C115.133 18.3599 115.241 18.3969 115.326 18.4369C115.411 18.4769 115.472 18.5081 115.509 18.5332L115.299 19.2341C115.067 19.1244 114.834 19.0503 114.596 19.0118C114.359 18.9718 114.143 18.9526 113.949 18.9526C113.589 18.9526 113.314 19.0237 113.123 19.1659C112.931 19.3082 112.836 19.526 112.836 19.8164C112.836 19.9438 112.851 20.0579 112.882 20.1572C112.913 20.2564 112.971 20.3528 113.06 20.4431C113.148 20.5335 113.271 20.6239 113.43 20.7113C113.589 20.7987 113.793 20.8921 114.042 20.9884L114.28 21.0788C114.559 21.1884 114.79 21.304 114.969 21.424C115.149 21.5455 115.29 21.6789 115.394 21.8241C115.498 21.9693 115.57 22.1293 115.608 22.3012C115.648 22.4745 115.668 22.6642 115.668 22.8701C115.668 23.1739 115.617 23.4406 115.518 23.6703C115.418 23.9014 115.275 24.0955 115.093 24.2526C114.91 24.4111 114.693 24.5296 114.44 24.6126C114.188 24.6941 113.91 24.7356 113.605 24.7356C113.265 24.7356 112.941 24.6911 112.634 24.6037C112.326 24.5163 112.06 24.3993 111.836 24.254L112.065 23.5176H112.06Z" fill="#414042"/>
    <path d="M55.3984 29.334H56.9258C57.5097 29.334 57.9524 29.4496 58.2511 29.6807C58.5512 29.9118 58.7012 30.2408 58.7012 30.6675C58.7012 30.8661 58.673 31.0409 58.6165 31.192C58.5601 31.3432 58.4843 31.4735 58.3863 31.5862C58.2882 31.6973 58.1738 31.7906 58.043 31.8632C57.9108 31.9358 57.7697 31.9892 57.6196 32.0233C57.737 32.0662 57.844 32.1655 57.9405 32.3181C58.0386 32.4707 58.1352 32.6574 58.2332 32.8797C58.3313 33.1004 58.4338 33.3464 58.5437 33.6161C58.6537 33.8857 58.7785 34.1584 58.9196 34.4354H58.1604C58.0044 34.1243 57.8766 33.8531 57.7771 33.619C57.6776 33.3864 57.5929 33.1849 57.5245 33.0175C57.4562 32.85 57.3953 32.7108 57.3418 32.6026C57.2883 32.4929 57.2274 32.407 57.159 32.3433C57.0907 32.2796 57.0105 32.2351 56.9183 32.2085C56.8262 32.1818 56.7059 32.1685 56.5603 32.1685H56.0566V34.434H55.3984V29.334ZM56.0566 31.5565H56.9703C57.2824 31.5565 57.532 31.4943 57.7192 31.3713C57.9064 31.2468 58 31.035 58 30.7342C58 30.4823 57.9138 30.2867 57.74 30.1519C57.5676 30.0156 57.2838 29.9474 56.8886 29.9474H56.0551V31.558L56.0566 31.5565Z" fill="#035FA2"/>
    <path d="M62.6014 34.5069C62.2463 34.5069 61.9254 34.4462 61.6401 34.3247C61.3549 34.2032 61.1112 34.0298 60.9092 33.8031C60.7071 33.5779 60.5526 33.3023 60.4456 32.9764C60.3386 32.6504 60.2852 32.2874 60.2852 31.8829C60.2852 31.4784 60.3416 31.1094 60.453 30.7864C60.5645 30.4634 60.722 30.1893 60.924 29.9626C61.1261 29.7359 61.3683 29.5625 61.6505 29.441C61.9328 29.3195 62.2419 29.2588 62.5776 29.2588C62.958 29.2588 63.2923 29.324 63.582 29.4514C63.8717 29.5803 64.1154 29.7596 64.313 29.9907C64.5106 30.2219 64.6606 30.499 64.7617 30.822C64.8642 31.145 64.9147 31.4991 64.9147 31.8814C64.9147 32.314 64.8568 32.6933 64.7394 33.0223C64.622 33.3497 64.4616 33.6238 64.2565 33.8417C64.0515 34.0609 63.8078 34.2254 63.5255 34.3365C63.2433 34.4477 62.9342 34.504 62.5984 34.504L62.6014 34.5069ZM62.6668 33.895C63.1497 33.895 63.527 33.7261 63.8034 33.3883C64.0782 33.0504 64.2164 32.5496 64.2164 31.8843C64.2164 31.5347 64.1793 31.2339 64.1035 30.9805C64.0277 30.7286 63.9193 30.5197 63.7781 30.3538C63.637 30.1893 63.4646 30.0678 63.2626 29.9893C63.0605 29.9122 62.8332 29.8722 62.5791 29.8722C62.3548 29.8722 62.1453 29.9137 61.9507 29.9967C61.756 30.0796 61.5881 30.2026 61.447 30.3686C61.3059 30.5345 61.1929 30.742 61.1112 30.9953C61.0295 31.2487 60.9864 31.5436 60.9864 31.8843C60.9864 32.2251 61.0265 32.5141 61.1068 32.766C61.187 33.0193 61.2999 33.2282 61.447 33.3957C61.5926 33.5631 61.7694 33.689 61.9759 33.7705C62.1824 33.8535 62.4127 33.895 62.6668 33.895Z" fill="#035FA2"/>
    <path d="M66.5983 33.5324C66.7157 33.6154 66.8746 33.6969 67.0767 33.7769C67.2788 33.8569 67.512 33.8969 67.775 33.8969C68.1152 33.8969 68.3693 33.8243 68.5342 33.6776C68.6991 33.5324 68.7823 33.325 68.7823 33.0583C68.7823 32.9131 68.7645 32.7901 68.7273 32.6908C68.6902 32.5915 68.6323 32.5041 68.552 32.4286C68.4718 32.353 68.3693 32.2863 68.2445 32.2285C68.1197 32.1707 67.9711 32.107 67.7958 32.0389L67.6205 31.9737C67.4348 31.9011 67.2728 31.824 67.1346 31.744C66.9965 31.664 66.8806 31.5692 66.7914 31.4595C66.7008 31.3499 66.6325 31.2225 66.5864 31.0773C66.5404 30.9321 66.5166 30.7617 66.5166 30.5676C66.5166 30.1542 66.6607 29.8341 66.9519 29.6059C67.2416 29.3778 67.6428 29.2637 68.1539 29.2637C68.3099 29.2637 68.4555 29.274 68.5922 29.2963C68.7288 29.3185 68.8492 29.3437 68.9532 29.3733C69.0572 29.403 69.1448 29.4326 69.2132 29.4637C69.2815 29.4948 69.3306 29.5215 69.3588 29.5407L69.1909 30.1023C69.0052 30.0149 68.818 29.9556 68.6278 29.9245C68.4376 29.8934 68.2653 29.8771 68.1093 29.8771C67.8211 29.8771 67.6012 29.9349 67.4481 30.049C67.2951 30.1631 67.2178 30.3364 67.2178 30.5705C67.2178 30.6728 67.2297 30.7631 67.255 30.8432C67.2802 30.9232 67.3263 31.0002 67.3976 31.0728C67.4674 31.1454 67.567 31.218 67.6933 31.2877C67.8196 31.3588 67.983 31.4329 68.1821 31.5099L68.3723 31.5825C68.5966 31.6699 68.7808 31.7618 68.9235 31.8596C69.0676 31.9574 69.1805 32.0641 69.2637 32.1796C69.3469 32.2967 69.4034 32.4241 69.436 32.5619C69.4672 32.6997 69.4836 32.8523 69.4836 33.0168C69.4836 33.2598 69.4435 33.4732 69.3632 33.6584C69.283 33.8436 69.1701 33.9977 69.023 34.1251C68.8774 34.251 68.7021 34.3474 68.5 34.4125C68.298 34.4777 68.0751 34.5103 67.8315 34.5103C67.5581 34.5103 67.2996 34.4748 67.0529 34.4051C66.8063 34.334 66.5938 34.2407 66.4141 34.1251L66.5968 33.5354L66.5983 33.5324Z" fill="#035FA2"/>
    <path d="M74.0709 33.0728H71.8869L71.3907 34.4359H70.7266L72.6773 29.3359H73.3355L75.2491 34.4359H74.5627L74.0739 33.0728H74.0709ZM72.0979 32.4756H73.8585L73.0042 30.0783H72.9745L72.0979 32.4756Z" fill="#035FA2"/>
    <path d="M77.7839 34.434H77.1258V29.9459H75.6133V29.334H79.2949V29.9459H77.7825V34.434H77.7839Z" fill="#035FA2"/>
    <path d="M82.6366 34.5069C82.2815 34.5069 81.9606 34.4462 81.6753 34.3247C81.39 34.2032 81.1464 34.0298 80.9443 33.8031C80.7423 33.5779 80.5877 33.3023 80.4808 32.9764C80.3738 32.6504 80.3203 32.2874 80.3203 31.8829C80.3203 31.4784 80.3768 31.1094 80.4882 30.7864C80.5996 30.4634 80.7571 30.1893 80.9592 29.9626C81.1612 29.7359 81.4034 29.5625 81.6857 29.441C81.968 29.3195 82.277 29.2588 82.6143 29.2588C82.9946 29.2588 83.3289 29.324 83.6186 29.4514C83.9084 29.5803 84.152 29.7596 84.3496 29.9907C84.5472 30.2219 84.6958 30.499 84.7983 30.822C84.9008 31.145 84.9514 31.4991 84.9514 31.8814C84.9514 32.314 84.8934 32.6933 84.776 33.0223C84.6587 33.3497 84.4982 33.6238 84.2932 33.8417C84.0881 34.0609 83.8445 34.2254 83.5622 34.3365C83.2799 34.4477 82.9709 34.504 82.6336 34.504L82.6366 34.5069ZM82.702 33.895C83.1848 33.895 83.5622 33.7261 83.8385 33.3883C84.1134 33.0504 84.2516 32.5496 84.2516 31.8843C84.2516 31.5347 84.2144 31.2339 84.1387 30.9805C84.0629 30.7286 83.9544 30.5197 83.8133 30.3538C83.6721 30.1893 83.4998 30.0678 83.2977 29.9893C83.0957 29.9122 82.8683 29.8722 82.6143 29.8722C82.3899 29.8722 82.1805 29.9137 81.9858 29.9967C81.7912 30.0796 81.6233 30.2026 81.4822 30.3686C81.341 30.5345 81.2281 30.742 81.1464 30.9953C81.0632 31.2472 81.0216 31.5436 81.0216 31.8843C81.0216 32.2251 81.0617 32.5141 81.1419 32.766C81.2222 33.0193 81.3351 33.2282 81.4822 33.3957C81.6278 33.5631 81.8046 33.689 82.0126 33.7705C82.2191 33.8535 82.4494 33.895 82.7034 33.895H82.702Z" fill="#035FA2"/>
    <path d="M91.7032 34.434H91.045L90.7315 30.4482L89.439 34.434H88.7525L87.4674 30.463L87.1465 34.434H86.4883L86.9266 29.334H87.7304L89.1106 33.7198L90.4626 29.334H91.2664L91.7047 34.434H91.7032Z" fill="#035FA2"/>
    <defs>
    <radialGradient id="paint0_radial_13990_38789" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(20.1211 19.9483) rotate(-0.0598366) scale(6.2041 6.18721)">
    <stop stopColor="#4495D1"/>
    <stop offset="1" stopColor="#15256D"/>
    </radialGradient>
    <linearGradient id="paint1_linear_13990_38789" x1="6.12583" y1="18.0334" x2="16.5557" y2="1.92809" gradientUnits="userSpaceOnUse">
    <stop stopColor="#4495D1"/>
    <stop offset="1" stopColor="#15256D"/>
    </linearGradient>
    <linearGradient id="paint2_linear_13990_38789" x1="10.3028" y1="-0.496367" x2="36.2921" y2="10.0314" gradientUnits="userSpaceOnUse">
    <stop stopColor="#4495D1"/>
    <stop offset="1" stopColor="#15256D"/>
    </linearGradient>
    <linearGradient id="paint3_linear_13990_38789" x1="25.9479" y1="35.3787" x2="6.75341" y2="34.3702" gradientUnits="userSpaceOnUse">
    <stop stopColor="#4495D1"/>
    <stop offset="1" stopColor="#15256D"/>
    </linearGradient>
    <linearGradient id="paint4_linear_13990_38789" x1="7.76827" y1="41.0346" x2="3.88443" y2="13.327" gradientUnits="userSpaceOnUse">
    <stop stopColor="#4495D1"/>
    <stop offset="1" stopColor="#15256D"/>
    </linearGradient>
    <linearGradient id="paint5_linear_13990_38789" x1="31.0998" y1="9.58599" x2="39.7889" y2="26.6866" gradientUnits="userSpaceOnUse">
    <stop stopColor="#4495D1"/>
    <stop offset="1" stopColor="#15256D"/>
    </linearGradient>
    <linearGradient id="paint6_linear_13990_38789" x1="45.1" y1="22.4587" x2="23.0383" y2="39.741" gradientUnits="userSpaceOnUse">
    <stop stopColor="#4495D1"/>
    <stop offset="1" stopColor="#15256D"/>
    </linearGradient>
    </defs>
    </svg>
  )
}

export default LogoMNTKEn
