import React, { FC } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Grid, Space } from 'antd'
import { Link as RouterLink } from 'react-router-dom'

import { Avatar, Button, Link } from '../../../common/index'
import { AdminIcon, LogOutIcon } from '../../../common/icons/'
import { RootState } from '../../../../store/store'
import styles from './top-profile.module.scss'
import { accountActions } from '../../../../store/slices/accountSlice'
import { useTranslation } from 'react-i18next'

const TopProfile: FC = () => {
  const user = useSelector((state: RootState) => state.account.user)
  const dispatch = useDispatch()
  
  const { i18n } = useTranslation()

  const handleClickLogout = () => {
    dispatch(accountActions.fetchLogOut())
  }

  const handleLangChange = () => {
    const changeLang = i18n.resolvedLanguage === "Ru" ? "Eng" : "Ru"
    localStorage.setItem('locale', changeLang)
    i18n.changeLanguage(changeLang)
    window.location.reload()
  }

  const getStringWithName = () => {
    let string = '...'
    if (user) {
      const { firstName, lastName, middleName } = user
      string = [lastName, firstName, middleName]
        .map((name) => name?.trim())
        .filter((name) => !!name)
        .map((name, index) => (index === 0 ? name : name?.slice(0, 1).toUpperCase() + '.'))
        .join(' ')
      // if (!string) string = username
    }
    return string
  }

  const screens = Grid.useBreakpoint()

  return (
    <div className={styles.wrapper}>
      {user && (
        <>
          <RouterLink to={'/user-profile'} className={styles.link}>
            <Avatar image={user?.avatar} size={!screens.xl ? 'large' : 'medium'} />
            <div className={styles.userinfo}>
              <div className={styles.userinfo_name}>{getStringWithName()}</div>
            </div>
          </RouterLink>
          <Space>
            {!screens.xl &&
              <Button type='ghost' onClick={handleLangChange}>
                {i18n.resolvedLanguage === "Ru" ? "Eng" : "Ru"}
              </Button>
            }
            <div className={styles.usermenu_btn}>
              {user?.adminRoute && !screens.xl && (
                <Link to={user?.adminRoute} external>
                  <AdminIcon size={40} />
                </Link>
              )}
            </div>
            <div className={styles.usermenu_btn}>
              <LogOutIcon onClick={handleClickLogout} />
            </div>
          </Space>
        </>
      )}
    </div>
  )
}

export default TopProfile
