type GetAcceptFormatsOptionValue = 'file' | 'image' | 'video' | 'all' | 'target_blank'

export const getAcceptFormats = (optionValue: GetAcceptFormatsOptionValue): string => {
  const files = '.doc,.docx,.xlsx,.xls,.pdf,.xps,.vsd,.vsdx,.rar,.zip,.pptx,.ppt,.xltx,.xlsm,.xls,.xlt,.ods,.ots,.csv,.tsv,.tab,.scsv,.txt,.xods,.xots,.odf,.odt,.odp'
  const image = '.png,.jpeg,.jpg,.bmp,.tif,.tiff'
  const video = '.avi,.wmv,.mov,.mp4'
  const target_blank_formats = '.png,.jpeg,.jpg,.bmp,.pdf'
  const all_formats = [files, image, video].join(',')

  switch (optionValue) {
    case 'file':
      return files
    case 'image':
      return image
    case 'video':
      return video
    case 'target_blank':
      return target_blank_formats
    case 'all':
      return all_formats
    default:
      return ''
  }
}
