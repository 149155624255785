import React, { FC, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Row, Col, Spin } from 'antd'
import { PayloadAction } from '@reduxjs/toolkit'
import { useForm } from 'antd/lib/form/Form'

import * as Models from '../../../api/model/models'
import { Form, Input, Button, Modal } from '../../common/index'
import styles from './recovery.module.scss'
import './recovery.ant.scss'
import { accountActions } from '../../../store/slices/accountSlice'
import { Title } from '../../common/text'
import useNeedCaptcha from '../../../hooks/useNeedCaptcha'
import { emailRecoveryValidator } from '../../../utils/ant-form-validators'
import { validateMessages } from '../../../utils/constants'
import { useTranslation } from 'react-i18next'

const { Item } = Form

type Props<T> = {
  selectTab: (tab: T) => void
}

const Recovery: FC<Props<TabHeader>> = ({ selectTab }) => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)

  const { t } = useTranslation()

  const [form] = useForm()

  const { needCaptcha, checkCaptchaResponse } = useNeedCaptcha()

  const handleSubmit = async (fields: Models.SendEmailReset) => {
    setLoading(true)
    const response = (await dispatch<unknown>(accountActions.fetchResetPassword(fields))) as PayloadAction<
      unknown,
      string,
      { payload: void; type: string },
      { message: string }
    >
    setLoading(false)

    if (checkCaptchaResponse(response)) {
      form.resetFields()
      return
    }

    if (response.type === 'fetchResetPassword/fulfilled') {
      Modal.success({
        content: (
          <>
            <Title level={2}>Успешно!</Title>
            <span>Проверьте e-mail и следуйте инструкциям в письме.</span>
          </>
        ),
        maskClosable: true,
        okButtonProps: {
          style: { display: 'none' },
        },
      })
      selectTab('hidden')
    }
  }
  const handleCancel = () => selectTab('login')

  return (
    <>
      <Spin spinning={loading}>
        <Row justify="start" className="form-recovery">
          <Col flex="328px">
            <p className={styles.p}>{t("passwordRecoveryDescription")}</p>
            <Form
              form={form}
              name="recovery"
              layout="vertical"
              onFinish={handleSubmit}
              validateMessages={validateMessages}
            >
              <Item
                name="email"
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    validator: emailRecoveryValidator,
                  },
                ]}
              >
                <Input autoFocus placeholder={'e-mail'} type="email" />
              </Item>
              {needCaptcha && <Form.Captcha form={form} />}
              <Item className={styles.groupButtons}>
                <Button type="primary" htmlType="submit">
                  {t("toSend")}
                </Button>
                <Button type="link" onClick={handleCancel} className={styles.buttonCancel}>
                  {t("cancel")}
                </Button>
              </Item>
            </Form>
          </Col>
        </Row>
      </Spin>
    </>
  )
}

export default Recovery
