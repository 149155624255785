import React, { FC } from "react"

const Folder: FC<DefaultPropsIcon> = (props) => {
  const { size = 24, onClick, className, color = '#818C99' } = props
  return (
    <span className={['anticon', 'anticon-setting', className || ''].join(' ')} onClick={onClick}>
      <svg width={size} height={size} viewBox={`0 0 24 24`} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path 
          fillRule="evenodd" clipRule="evenodd" d="M20.9655 7.5C20.7061 5.52478 19.0162 4 16.9701 4H12H11H10H7.02985C4.80423 4 3 5.80423 3 8.02985V15.9701C3 18.1958 4.80423 20 7.02985 20H16.9701C19.1958 20 21 18.1958 21 15.9701V9.5V8.5V8.02985C21 7.85025 20.9883 7.67339 20.9655 7.5ZM17.6248 7.5C18.2829 7.5 18.7389 6.83668 18.2207 6.43085C17.876 6.16091 17.4419 6 16.9701 6H14.1592C13.3865 6 12.9791 6.83059 13.6693 7.17806C14.0784 7.38402 14.5406 7.5 15.0299 7.5H17.6248ZM9.50617 6C9.94141 6 10.3177 6.28661 10.509 6.67756C11.3266 8.34895 13.0438 9.5 15.0299 9.5H18C18.5523 9.5 19 9.94772 19 10.5V15.9701C19 17.0912 18.0912 18 16.9701 18H7.02985C5.9088 18 5 17.0912 5 15.9701V8.02985C5 6.9088 5.9088 6 7.02985 6H9.50617Z" 
          fill={color}/>
      </svg>
    </span>
  )
}

export default Folder
