import { Row, Col, Checkbox, Button } from 'antd'
import React, { FC, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import {
  emailRuValidator,
  MIN_LEN_PASSWORD,
  passwordValidator,
  fullNameValidator,
  phoneValidator,
  responsibilitiesValidator,
} from '../../../../utils/ant-form-validators'
import { validateMessages } from '../../../../utils/constants'
import FieldSelect from '../field-select'
import styles from '../registration.module.scss'
import { Form } from '../../../common'
import useHandleSubmit from '../hooks/useHandleSubmit'
import useChangeCompany from '../hooks/useChangeCompany'
import { useDispatch, useSelector } from 'react-redux'
import { Company } from '../../../../api/model/company'
import { RootState } from '../../../../store/store'
import { RuleObject } from 'antd/lib/form'
import { NamePath } from 'antd/lib/form/interface'
import Field from '../field'
import { adminsRoles } from '../../../../constants/roles'
import { sectionsActions } from '../../../../store/slices/sectionsConferenceSlice'
import { companiesType } from '../../../../constants/companiesType'
import { companiesActions } from '../../../../store/slices/companiesSlice'
import useGetSections, { SectionListItemType } from '../../../../hooks/useGetSections'

const { Item, useForm } = Form

interface Props<T> {
  selectTab: (tab: T) => void
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
}

interface GetFieldValue {
  getFieldValue: (name: NamePath) => string
}

const ForAdmin: FC<Props<TabHeader>> = ({ selectTab, setLoading }) => {
  const [form] = useForm()

  const dispatch = useDispatch()
  const [selectedSection, setSelectedSection] = useState<string>('')
  const { handleSubmit, companyNotExistOption, needCaptcha } = useHandleSubmit({ selectTab, setLoading })
  const { onChangeCompany, needNewField } = useChangeCompany({ companyNotExistOption, form })

  const { sectionList } = useGetSections('visiting')

  const companies = useSelector<RootState, Company[]>((state) => state.companies.companies)
  const companiesOptions: Company[] = [companyNotExistOption, ...companies]

  const [conditionsAreAccepted, setConditionsAreAccepted] = useState<boolean>(true)
  const [missingMiddleNameConfirm, setMissingMiddleNameConfirm] = useState<boolean>(true)
  const [middleNameNotEntered, setMiddleNameNotEntered] = useState<boolean>(true)
  const [subSectionArr, setSubSectionArr] = useState<SectionListItemType[]>([])
  const [selectedRole, setSelectedRole] = useState<string>('')


  const onChangeRole = () => {
    const formFields = form.getFieldsValue()
    if (formFields.role) setSelectedRole(formFields.role)
  }

  const onChangeCompanyType = () => {
    const formFields = form.getFieldsValue()
    if(formFields.companiesType) dispatch(companiesActions.setCompaniesType(formFields.companiesType)) 
  }

  const onChangeSection = () => {
    const formFields = form.getFieldsValue()
    if (formFields.parentSection) setSelectedSection(formFields.parentSection)
  }

  useEffect(() => {
    if (selectedSection) {
      const subSectionList = sectionList.find((item) => item.id === Number(selectedSection))
      subSectionList ? setSubSectionArr(subSectionList.children) : setSubSectionArr([])
    }
  }, [selectedSection]) // eslint-disable-line

  useEffect(() => {
    dispatch(sectionsActions.fetchSections())
  }, [dispatch])

  return (
    <Row justify="start">
      <Col className={styles.form}>
        <Form
          form={form}
          name="registration"
          layout="vertical"
          validateMessages={validateMessages}
          onFinish={handleSubmit}
          autoComplete="off"
          onChange={() => {
            const textEntered = form.getFieldValue('middleName')
            setMiddleNameNotEntered(!textEntered)
            if (textEntered) setMissingMiddleNameConfirm(true)
          }}
        >
          <Field
            label="Электронная почта*"
            name="email"
            rules={[
              {
                required: true,
                whitespace: true,
                max: 250,
                validator: emailRuValidator,
              },
            ]}
            placeholder="Почта"
          />
          <Field
            label="Фамилия*"
            name="lastName"
            autoComplete={'family-name'}
            rules={[{ required: true, whitespace: true, max: 30, validator: fullNameValidator }]}
            placeholder="Фамилия"
          />
          <Field
            label="Имя*"
            name="firstName"
            autoComplete={'family-name'}
            rules={[{ required: true, whitespace: true, max: 30, validator: fullNameValidator }]}
            placeholder="Имя"
          />
          <Field
            label="Отчество"
            name="middleName"
            autoComplete={'family-name'}
            rules={[{ whitespace: true, max: 30, validator: responsibilitiesValidator }]}
            placeholder="Отчество"
          />
          <FieldSelect
            label="Тип организации"
            name="companiesType"
            placeholder="Тип организации"
            data={companiesType}
            value="id"
            content="name"
            rules={[{ whitespace: true }]}
            showSearch
            onChange={onChangeCompanyType}
          />
          <FieldSelect
            label="Организация*"
            name="company"
            placeholder="Организация"
            data={companiesOptions}
            value="id"
            content="name"
            rules={[{ required: true, whitespace: true }]}
            showSearch
            onChange={onChangeCompany}
          />
          {needNewField &&
            <Field
              label="Название организации*"
              name="customCompany"
              rules={[{ required: true, whitespace: true }]}
              placeholder="Укажите название"
            />
          }
          <Field
            label="Должность*"
            name="position"
            autoComplete={'family-name'}
            rules={[{ required: true, whitespace: true, max: 30, validator: responsibilitiesValidator }]}
            placeholder="Должность"
          />
          <Field
            label="Контактный телефон*"
            name="phone"
            rules={[
              {
                required: true,
                whitespace: true,
                max: 250,
                validator: phoneValidator,
              },
            ]}
            placeholder="Контактный телефон"
          />
          <FieldSelect
            label="Тип учетной записи*"
            name="role"
            placeholder="Тип учетной записи"
            data={adminsRoles}
            value="id"
            content="name"
            rules={[{ required: true, whitespace: true }]}
            showSearch
            onChange={onChangeRole}
          />
          {selectedRole === "conference_admin" && 
            <FieldSelect
              label="Секция*"
              name="parentSection"
              placeholder="Секция"
              data={sectionList}
              value="id"
              content="name"
              rules={[{ required: true, whitespace: true }]}
              showSearch
              onChange={onChangeSection}
            />
          }
          {selectedSection && subSectionArr.length > 0 && (
              <FieldSelect
                label="Подсекция"
                name="section"
                placeholder="Подсекция"
                data={subSectionArr}
                value="id"
                content="name"
                rules={[{ required: true, whitespace: true }]}
                showSearch
              />
            )}
          <Field
            label="Пароль*"
            name="password"
            rules={[
              {
                required: true,
                whitespace: true,
                min: MIN_LEN_PASSWORD,
              },
              {
                validator: passwordValidator,
              },
            ]}
            type="password"
            placeholder="Пароль"
            autoComplete="new-password"
          />
          <Field
            label="Подтверждение пароля*"
            name="confirm"
            dependencies={['password']}
            type="password"
            placeholder="Подтверждение пароля"
            autoComplete="new-password"
            rules={[
              {
                required: true,
                whitespace: true,
              },
              ({ getFieldValue }: GetFieldValue) => ({
                validator: (rule: RuleObject, value: string) => {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve()
                  }
                  return Promise.reject('Два введенных вами пароля не совпадают!')
                },
              }),
            ]}
          />

          {needCaptcha && <Form.Captcha form={form} />}
          <Item>
            <label>
              <Checkbox onChange={() => setConditionsAreAccepted((pre) => !pre)} />
              <span className={styles.conditions}>Я принимаю</span>
            </label>
            <Link to={'/confidentiality-policy'}> Условия политики обработки персональных данных </Link>
            и <Link to={'/terms-of-service'}>Пользовательское соглашение</Link>
          </Item>
          {middleNameNotEntered &&
            <Item>
              <label>
                <Checkbox onChange={() => setMissingMiddleNameConfirm((pre) => !pre)} />
                <span className={styles.conditions}>Я подтверждаю, что у меня отсутствует отчество</span>
              </label>
            </Item>
          }
          <Item>
            <Button 
              disabled={middleNameNotEntered ? conditionsAreAccepted || missingMiddleNameConfirm : conditionsAreAccepted} 
              type="primary" 
              htmlType="submit" 
              className={styles.submitButton}
            >
              ЗАРЕГИСТРИРОВАТЬСЯ
            </Button>
          </Item>
        </Form>
      </Col>
    </Row>
  )
}

export default ForAdmin
