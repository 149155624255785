import React from 'react'

const Picture: React.FC<DefaultPropsIcon> = (props) => {
  const { size = 24, onClick, className, color = '#818C99' } = props
  return (
    <span className={['anticon', 'anticon-setting', className || ''].join(' ')} onClick={onClick}>
      <svg width={size} height={size} viewBox={`0 0 24 24`} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M18 3C19.6569 3 21 4.34315 21 6V18C21 19.6569 19.6569 21 18 21H6C4.34315 21 3 19.6569 3 18V6C3 4.34315 4.34315 3 6 3H18ZM14.7248 12.322C14.5612 12.195 14.3256 12.2247 14.1987 12.3883L11 16.51L8.79807 13.8589C8.78075 13.838 8.76122 13.8191 8.73982 13.8025C8.57634 13.6753 8.34074 13.7048 8.21359 13.8682L5.47073 17.3948C5.41954 17.4606 5.39174 17.5416 5.39174 17.625C5.39174 17.8321 5.55963 18 5.76674 18H18.25C18.3311 18 18.4101 17.9737 18.475 17.925C18.6407 17.8007 18.6743 17.5657 18.55 17.4L14.7949 12.3932C14.7748 12.3664 14.7513 12.3425 14.7248 12.322Z"
          fill={color}
        />
      </svg>
    </span>
  )
}

export default Picture
