import React, { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch, useAppSelector } from '../../../../store/store'
import { Spin } from 'antd'
import { Link as RouterLink, useHistory } from 'react-router-dom'
import { addCountlyEvent } from '../../../../utils/stats'
import Logo from '../../../common/icons/logo/logo'
import { Button, Dropdown, Link } from '../../../common'
import TopProfile from '../top-profile/top-profile'
import { AdminIcon } from '../../../common/icons'
import styles from './header-survey.module.scss'
import FormsModal from '../../../forms/forms-modal/forms-modal'
import { headerSurveyActions } from '../../../../store/slices/surveyHeaderSlice'
import Input from '../../../common/input/input'
import { LeftOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'

const HeaderSurvey: FC = () => {
  const loading = useSelector((state: RootState) => state.account.loading)
  const user = useSelector((state: RootState) => state.account.user)
  const { title, editable, backCount } = useAppSelector((s) => s.surveyHeader)
  const [tab, setTab] = useState<TabHeader>('hidden')
  const selectTab = (tab: TabHeader): void => setTab(tab)
  const dispatch = useAppDispatch()

  const { i18n } = useTranslation()

  const history = useHistory()

  useEffect(() => {
    history.listen((loc, action) => {
      if (action === 'POP') {
        dispatch(headerSurveyActions.minusBack())
      }
    })
  }, [history, dispatch])

  const onHandleChangeTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(headerSurveyActions.headerEditText(e.target.value))
  }

  const onHandleBack = () => {
    dispatch(headerSurveyActions.minusBack())
    history.goBack()
  }

  const handleDropdown = (key: string, value: string) => {
    localStorage.setItem('locale', value)
    i18n.changeLanguage(value)
    window.location.reload()
  }

  return (
    <div className={[styles.header, styles.sticked].join(' ')}>
      <div className={styles.content}>
        {backCount !== 0 && (
          <div onClick={onHandleBack} className={styles.arrowContainer}>
            <LeftOutlined className={styles.arrow} />
          </div>
        )}
        <div className={styles.logoWrapper}>
          <RouterLink to={'/'} onClick={() => addCountlyEvent('Клик по логотипу КРЭА')}>
            <Logo />
          </RouterLink>
        </div>
        {editable ? (
          <div className={styles.editTitle}>
            <Input value={title} onChange={onHandleChangeTitle} />
          </div>
        ) : (
          <span className={styles.titleHead} title={title}>
            {title}
          </span>
        )}

        {!loading && (
          <div className={styles.authBtnWrapper}>
            {user?.adminRoute && (
              <Link to={user.adminRoute} className={styles.icon} external>
                <AdminIcon size={40} />
              </Link>
            )}

            <Dropdown type="link" trigger={['click']} title={i18n.resolvedLanguage} onItemClick={handleDropdown}>
              <Dropdown.Option key={1} value="Ru">
                Ru
              </Dropdown.Option>
              <Dropdown.Option key={2} value="Eng">
                Eng
              </Dropdown.Option>
            </Dropdown>

            {!user && (
              <>
                <Button
                  type={'primary'}
                  size={'small'}
                  onClick={() => {
                    selectTab('login')
                    addCountlyEvent(`Авторизация`, { Действие: 'Форма открыта' })
                  }}
                >
                  Вход
                </Button>
                <Button
                  type={'link'}
                  size={'small'}
                  onClick={() => {
                    selectTab('registration')
                    addCountlyEvent(`Регистрация`, { Действие: 'Форма открыта' })
                  }}
                >
                  Регистрация
                </Button>
              </>
            )}
            {user && <TopProfile />}
          </div>
        )}
        {loading && (
          <div className={styles.box_spin}>
            <Spin />
          </div>
        )}
        <FormsModal tab={tab} selectTab={selectTab} />
      </div>
    </div>
  )
}

export default HeaderSurvey
