import { RoleEnum } from "../api/model/models"
import i18next from "../i18n"

const Eng = i18next.resolvedLanguage === "Eng"

export const participantsRoles: RolesListType[] = [
  {
    id: 'exhibition',
    name: 'Участник выставки',
  },
  {
    id: 'conference',
    name: 'Участник конференции',
  },
]

export const adminsRoles: RolesListType[] = [
  {
    id: 'exhibition_admin',
    name: 'Администратор выставки',
  },
  {
    id: 'conference_admin',
    name: 'Администратор секции',
  },
  {
    id: 'mntk_admin',
    name: 'Администратор МНТК',
  },
]

export const staffRoles: RolesListType[] = [
  {
    id: 'mass_media',
    name: 'СМИ',
  },
  {
    id: 'organizer',
    name: 'Организатор',
  },
  {
    id: 'translator',
    name: 'Переводчик',
  },
]


export const rolesList: RolesListType[] = [
  ...participantsRoles,
  ...adminsRoles,
  ...staffRoles,
]

export const rolesListNames: Record<RoleEnum, string> = {
  exhibition: Eng ? "Participant of the exhibition" : "Участник выставки",
  conference: Eng ? "Conference participant" : "Участник конференции",
  exhibition_admin: Eng ? "Exhibition Administrator" : "Администратор выставки",
  conference_admin: Eng ? "Section Administrator" : "Администратор секции",
  mntk_admin: Eng ? "MNTK Administrator" : "Администратор МНТК",
  mass_media: Eng ? "MEDIA" : "СМИ",
  organizer: Eng ? "Organizer" : "Организатор",
  translator: Eng ? "Translator" : "Переводчик",
}

export const participantStatus: Record<string, string> = {
  vip: "VIP",
  delegate: "Делегат",
  participant: "Участник",
}

export type RolesListType = {
  id: RoleEnum
  name: string
}