import i18next from '../i18n'

export const companiesType = [
  {
    id: "foreign",
    name: i18next.resolvedLanguage === "Eng" ? "Foreign" : "Зарубежная",
  },
  {
    id: "russian",
    name: i18next.resolvedLanguage === "Eng" ? "Russian" : "Российская",
  },
]