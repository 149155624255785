import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { UploadFile } from 'antd/es/upload/interface'
import appAPI from '../../api/api'
import { CompetenceDocumentDirectoryWithParent } from '../../api/model/competenceDocumentDirectoryWithParent'
import { RootState } from '../store'
import { DownloadsDataType } from '../../components/popovers/statistic-popover/types/statisticType'
import { DocumentDownloadStatistics } from '../../api/model/models'

type CompetenceDocsState = {
  docs: Array<DirectoryItem>
  competenceId?: number
  currentDirId?: number
  rootDirId?: number
  page: number
  pages?: number
  loading: boolean
  breadCrumbs: Array<BreadCrumb>
  competenceDocsStatistic: { [N: string]: Array<DownloadsDataType> | undefined }
  loadingCompetenceDocsStatistic: boolean
  downloadDockPending: boolean
}

export type BreadCrumb = {
  id: string | ''
  title: string
}

const rootDirectory = { id: '', title: 'Все документы' }

export const initialCompetenceDocsState: CompetenceDocsState = {
  docs: [],
  competenceId: undefined,
  currentDirId: undefined,
  rootDirId: undefined,
  page: 0,
  pages: 1,
  loading: false,
  breadCrumbs: [rootDirectory],
  competenceDocsStatistic: {},
  loadingCompetenceDocsStatistic: false,
  downloadDockPending: false,
}

const fetchCompetenceDocsStatistic = createAsyncThunk(
  '/fetchCompetenceDocsStatistic',
  async (payload: { id: string }) => {
    const docsStatistic = await appAPI.directory.getCompetenceDocsStatistic(payload.id)
    return {
      docsStatistic,
      docId: payload.id,
    }
  },
)

const addCompetenceDocsStatistic = createAsyncThunk('/addCompetenceDocsStatistic', async (payload: { id: string }) => {
  return await appAPI.directory.addCompetenceDocsStatistic(payload.id)
})

const fetchCompetenceDocs = createAsyncThunk('/fetchCompetenceDocs', async (payload: GetDirectoryContentPayload) => {
  const response = await appAPI.directory.getDirectoryContent(payload)

  return { docs: response.data, competenceId: payload.competenceId, dirId: payload.dirId }
})

const fetchCompetenceDirectory = createAsyncThunk('/fetchCompetenceDirectory', async (payload: string) => {
  return appAPI.directory.getDirectory(payload)
})

const addCompetenceDocument = createAsyncThunk(
  '/addCompetenceDocument',
  async (payload: { files: UploadFile[]; parent: number; owner: boolean }) => {
    const list = payload.files
      .filter((file) => file.originFileObj)
      .map((item) => {
        let file = item.originFileObj as File
        const pointIndex = file.name.lastIndexOf('.')

        if (pointIndex >= 20) {
          const newName = `${file.name.substring(0, 19)}${file.name.substring(pointIndex, file.name.length)}`
          file = new File([file], newName, { type: file.type })
        }

        return appAPI.directory.postDocument({
          file,
          parent: payload.parent,
        })
      })

    const items = await Promise.all(list)

    return { files: items, parent: payload.parent, owner: payload.owner }
  },
)

const movingCompetenceDocument = createAsyncThunk(
  '/movingCompetenceDocument',
  async (payload: MovingRequest, { getState }) => {
    const rootState = getState() as RootState
    const competenceId = rootState.competenceDocs.competenceId as number

    let parentId = payload.parent

    if (!parentId) {
      const res = await appAPI.directory.getDirectoryContent({ competenceId: competenceId?.toString(), dirId: '' })

      parentId = res.data.results![0].parent as number
    }

    await appAPI.directory.movingDocument({ id: payload.id, parent: parentId })

    return { data: payload }
  },
)

const deleteCompetenceDocument = createAsyncThunk('/deleteCompetenceDocument', async (payload: string) => {
  await appAPI.directory.deleteDocument(payload)
  return payload
})

const addCompetenceDirectory = createAsyncThunk('/addCompetenceDirectory', async (payload: DirectoryPostResponse) => {
  const res = await appAPI.directory.postDirectory(payload)
  return res.data
})

const renameCompetenceDirectory = createAsyncThunk(
  '/renameCompetenceDirectory',
  async (payload: DirectoryPostResponse) => {
    const { id, name } = payload

    if (id) {
      const res = await appAPI.directory.renameDirectory(String(id), name)
      return { ...res.data, id }
    }
  },
)

const movingCompetenceDirectory = createAsyncThunk(
  '/movingCompetenceDirectory',
  async (payload: MovingRequest, { getState }) => {
    const rootState = getState() as RootState
    const competenceId = rootState.competenceDocs.competenceId as number

    let parentId = payload.parent

    if (!parentId) {
      const res = await appAPI.directory.getDirectoryContent({ competenceId: competenceId?.toString(), dirId: '' })

      parentId = res.data.results![0].parent as number
    }

    await appAPI.directory.movingDirectory({ id: payload.id, parent: parentId })

    return { data: payload }
  },
)

const deleteCompetenceDirectory = createAsyncThunk('/deleteCompetenceDirectory', async (payload: string) => {
  await appAPI.directory.deleteDirectory(payload)
  return payload
})

const competenceDocsSlice = createSlice({
  name: 'competenciesDocs',
  initialState: initialCompetenceDocsState,
  reducers: {
    setPage: (state: CompetenceDocsState, { payload }: PayloadAction<number>) => {
      state.page = payload
    },
    setBreadCrumbs: (state: CompetenceDocsState, { payload }: PayloadAction<Array<BreadCrumb>>) => {
      state.breadCrumbs = payload
    },
    clearBreadCrumbs: (state) => {
      state.breadCrumbs = [rootDirectory]
    },
    clearCompetenceDocs: (state) => {
      state.docs = []
      state.page = 0
      state.pages = 1
      state.currentDirId = undefined
      state.competenceId = undefined
      state.rootDirId = undefined
    },
  },
  extraReducers: (builder) => {
    builder
      // получение изначального стейта
      .addCase(fetchCompetenceDocs.pending, (state: CompetenceDocsState) => {
        state.loading = true
      })
      .addCase(fetchCompetenceDocs.fulfilled, (state: CompetenceDocsState, { payload }) => {
        state.currentDirId = Number(payload.dirId)

        const results = payload.docs.results

        if (!!results?.length) {
          state.rootDirId = results[0].parent

          const rootDir = results.find((result) => {
            return result.id === result.parent
          })

          if (rootDir === undefined) {
            state.docs = [...state.docs, ...results]
          }
        }

        state.competenceId = Number(payload.competenceId)
        state.pages = payload.docs.pages
        state.loading = false
      })
      .addCase(fetchCompetenceDocs.rejected, (state: CompetenceDocsState) => {
        state.loading = false
      })

      // получение содержимого конкретной папки
      .addCase(fetchCompetenceDirectory.pending, (state: CompetenceDocsState) => {
        state.loading = true
      })
      .addCase(fetchCompetenceDirectory.fulfilled, (state: CompetenceDocsState, { payload }) => {
        state.breadCrumbs = []
        const recurDirectories = (directory: CompetenceDocumentDirectoryWithParent) => {
          if (directory.parent !== null) {
            state.breadCrumbs.unshift({ id: '' + directory.id, title: directory.name })
            recurDirectories(directory.parent)
          }
        }
        recurDirectories(payload.data)
        state.breadCrumbs.unshift(rootDirectory)
        state.loading = false
      })
      .addCase(fetchCompetenceDirectory.rejected, (state: CompetenceDocsState) => {
        state.loading = false
      })

      // добавление документа
      .addCase(addCompetenceDocument.pending, (state: CompetenceDocsState) => {
        state.loading = true
      })
      .addCase(addCompetenceDocument.fulfilled, (state: CompetenceDocsState, { payload }) => {
        const { files, parent, owner } = payload

        if (owner) {
          files.forEach((item) => {
            state.docs.push({
              id: item.data.id,
              name: item.data.title as string,
              document: item.data.document,
              parent: item.data.parent,
              size: item.data.size?.toString(),
              type: 'document',
              dateCreated: item.data.dateCreated,
              statusNew: item.data.statusNew,
            })
          })
        } else {
          const parentDirectoryId = state.docs.findIndex((doc) => doc.id === Number(parent))
          if (parentDirectoryId !== -1) {
            state.docs[parentDirectoryId].items! += files.length
          }
        }

        state.loading = false
      })
      .addCase(addCompetenceDocument.rejected, (state: CompetenceDocsState) => {
        state.loading = false
      })

      // перемещение документа
      .addCase(movingCompetenceDocument.pending, (state: CompetenceDocsState) => {
        state.loading = true
      })
      .addCase(movingCompetenceDocument.fulfilled, (state: CompetenceDocsState, { payload }) => {
        const { id, parent } = payload.data
        const documentId = state.docs.findIndex((doc) => doc.id === Number(id))
        const parentDirectoryId = state.docs.findIndex((doc) => doc.id === Number(parent))
        if (documentId !== -1) {
          state.docs.splice(documentId, 1)
          if (parentDirectoryId !== -1) {
            state.docs[parentDirectoryId].items! += 1
          }
        }
        state.loading = false
      })
      .addCase(movingCompetenceDocument.rejected, (state: CompetenceDocsState) => {
        state.loading = false
      })

      // удаление документа
      .addCase(deleteCompetenceDocument.pending, (state: CompetenceDocsState) => {
        state.loading = true
      })
      .addCase(deleteCompetenceDocument.fulfilled, (state: CompetenceDocsState, { payload }) => {
        const document = state.docs.findIndex((doc) => doc.id === Number(payload))
        if (document !== -1) state.docs.splice(document, 1)
        state.loading = false
      })
      .addCase(deleteCompetenceDocument.rejected, (state: CompetenceDocsState) => {
        state.loading = false
      })

      // добавление папки
      .addCase(addCompetenceDirectory.pending, (state: CompetenceDocsState) => {
        state.loading = true
      })
      .addCase(addCompetenceDirectory.fulfilled, (state: CompetenceDocsState, { payload }) => {
        state.docs.unshift({ ...payload, type: 'directory', items: 0 })
        state.loading = false
      })
      .addCase(addCompetenceDirectory.rejected, (state: CompetenceDocsState) => {
        state.loading = false
      })

      // переименование папки
      .addCase(renameCompetenceDirectory.pending, (state: CompetenceDocsState) => {
        state.loading = true
      })
      .addCase(renameCompetenceDirectory.fulfilled, (state: CompetenceDocsState, { payload }) => {
        if (payload === undefined) return
        const directory = state.docs.find((doc) => doc.id === Number(payload.id))
        if (directory !== undefined) directory.name = payload.name
        state.loading = false
      })
      .addCase(renameCompetenceDirectory.rejected, (state: CompetenceDocsState) => {
        state.loading = false
      })

      // перемещение папки
      .addCase(movingCompetenceDirectory.pending, (state: CompetenceDocsState) => {
        state.loading = true
      })
      .addCase(movingCompetenceDirectory.fulfilled, (state: CompetenceDocsState, { payload }) => {
        const { id, parent } = payload.data
        const directoryId = state.docs.findIndex((doc) => doc.id === Number(id))
        const parentDirectoryId = state.docs.findIndex((doc) => doc.id === Number(parent))
        if (directoryId !== -1) {
          state.docs.splice(directoryId, 1)
          if (parentDirectoryId !== -1) {
            state.docs[parentDirectoryId].items! += 1
          }
        }
        state.loading = false
      })
      .addCase(movingCompetenceDirectory.rejected, (state: CompetenceDocsState) => {
        state.loading = false
      })

      // удаление папки
      .addCase(deleteCompetenceDirectory.pending, (state: CompetenceDocsState) => {
        state.loading = true
      })
      .addCase(deleteCompetenceDirectory.fulfilled, (state: CompetenceDocsState, { payload }) => {
        const directory = state.docs.findIndex((doc) => doc.id === Number(payload))
        if (directory !== -1) state.docs.splice(directory, 1)
        state.loading = false
      })
      .addCase(deleteCompetenceDirectory.rejected, (state: CompetenceDocsState) => {
        state.loading = false
      })

      .addCase(fetchCompetenceDocsStatistic.pending, (state) => {
        state.loadingCompetenceDocsStatistic = true
      })
      .addCase(fetchCompetenceDocsStatistic.fulfilled, (state, { payload }) => {
        const formattingForStatistics = (
          payloadData: DocumentDownloadStatistics[],
        ): DownloadsDataType[] | undefined => {
          const newData = payloadData?.map((item) => ({
            key: item.id,
            name: item.fullName ?? 'Пользователь не зарегистрирован',
          }))

          return newData.length > 0 ? newData : undefined
        }

        state.competenceDocsStatistic = {
          ...state.competenceDocsStatistic,
          [payload.docId]: formattingForStatistics(payload.docsStatistic.data),
        }
        state.loadingCompetenceDocsStatistic = false
      })
      .addCase(fetchCompetenceDocsStatistic.rejected, (state) => {
        state.loadingCompetenceDocsStatistic = false
      })

      .addCase(addCompetenceDocsStatistic.pending, (state) => {
        state.downloadDockPending = true
      })
      .addCase(addCompetenceDocsStatistic.fulfilled, (state) => {
        state.downloadDockPending = false
      })
      .addCase(addCompetenceDocsStatistic.rejected, (state) => {
        state.downloadDockPending = false
      })
  },
})

export default competenceDocsSlice.reducer

export const competenceDocsSliceActions = {
  ...competenceDocsSlice.actions,
  fetchCompetenceDocs,
  fetchCompetenceDirectory,
  addCompetenceDocument,
  movingCompetenceDocument,
  deleteCompetenceDocument,
  addCompetenceDirectory,
  renameCompetenceDirectory,
  movingCompetenceDirectory,
  deleteCompetenceDirectory,
  fetchCompetenceDocsStatistic,
  addCompetenceDocsStatistic,
}
