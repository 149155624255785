import React, { FC } from 'react'

import styles from './styles.module.scss'
import { SearchRow } from '../../../../api/model/models'
import { Text, Title } from '../../../common/text'
import useItemGlobalSearch from './hooks/useItemGlobalSearch'
import { File } from '../../../common/icons'

type PropsItemGlobalSearch = SearchRow & {
  groupType: string
}

const ItemGlobalSearch: FC<PropsItemGlobalSearch> = (props) => {
  const { headline = '', excerpt = '', img, groupType, id_, downloadLink  } = props

  const competence = {name: ""}
  const { goLinkOrDownload } = useItemGlobalSearch(groupType, id_, downloadLink)
  const isDocument = groupType === 'CompetenceDocument'
  const isCompetence = groupType === 'Competence'
  const isOther = !isDocument && !isCompetence

  return (
    <div className={styles.container} onClick={goLinkOrDownload}>
      <div className={styles.leftSide}>
        {isOther && <img className={styles.image} src={img} alt={headline} />}
        {isCompetence && <img className={styles.icon} src={img} alt={headline} />}
        {isDocument && <File />}
      </div>

      <div className={`${styles.rightSide} ${!!img || isDocument ? styles.withPicture : ''}`}>
        <Title level={5}>{headline}</Title>
        <div className={styles.description}>
          <Text className={styles.excerpt}>{excerpt}</Text>
          {!!competence?.name && <Text>{competence?.name || ''}</Text>}
        </div>
      </div>
    </div>
  )
}

export default ItemGlobalSearch
