import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { Company } from '../../api/model/company'
import appAPI from '../../api/api'

type CompaniesState = {
  companies: Company[]
  companiesOneList: Record<number, string>
  companiesType: 'foreign' | 'cis' | 'russian' | undefined
  loading: boolean
  concreteCompany: Company[]
  concreteCompanyLoading: boolean
}

const initialCompaniesState: CompaniesState = {
  companies: [],
  companiesOneList: {},
  companiesType: undefined,
  loading: false,
  concreteCompany: [],
  concreteCompanyLoading: false,
}

const fetchAllCompanies = createAsyncThunk('fetchAllCompanies', async (payload: CompanyType, { rejectWithValue }) => {
  try {
    return await appAPI.companies.get(payload)
  } catch (err: any) {
    return rejectWithValue({ messages: err.response.data, status: err.response.status })
  }
})

const fetchConcreteCompany = createAsyncThunk('fetchConcreteCompany', async (payload: CompanyType, { rejectWithValue }) => {
  try {
    return await appAPI.companies.get(payload)
  } catch (err: any) {
    return rejectWithValue({ messages: err.response.data, status: err.response.status })
  }
})

const companiesSlice = createSlice({
  name: 'companies',
  initialState: initialCompaniesState,
  reducers: {
    setCompaniesType: (state, { payload }: PayloadAction<'foreign' | 'cis' | 'russian' | undefined>) => {
      state.companiesType = payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllCompanies.pending, (state) => {
        state.loading = true
      })
      .addCase(fetchAllCompanies.fulfilled, (state, { payload }) => {
        const sortData = payload.data?.sort((a, b) => a.name > b.name ? 1 : -1)

        sortData.forEach(item => {
          state.companiesOneList[item.id] = item.name
        })

        state.companies = sortData
        state.loading = false
      })
      .addCase(fetchAllCompanies.rejected, (state) => {
        state.loading = false
      })
      .addCase(fetchConcreteCompany.pending, (state) => {
        state.concreteCompanyLoading = true
      })
      .addCase(fetchConcreteCompany.fulfilled, (state, { payload }) => {
        const sortData = payload.data?.sort((a, b) => a.name > b.name ? 1 : -1)

        state.concreteCompany = sortData
        state.concreteCompanyLoading = false
      })
      .addCase(fetchConcreteCompany.rejected, (state) => {
        state.concreteCompanyLoading = false
      })
  },
})

export const companiesActions = {
  ...companiesSlice.actions,
  fetchAllCompanies,
  fetchConcreteCompany,
}

export default companiesSlice.reducer
