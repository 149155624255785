import React from 'react'

const Applications: React.FC<DefaultPropsIcon> = (props) => {
  const { size = 24, onClick, className, color = '#818C99' } = props
  return (
    <span className={['anticon', 'anticon-setting', className || ''].join(' ')} onClick={onClick}>
      <svg width={size} height={size} viewBox={`0 0 24 24`} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path 
          d="M20 8C20 5.79 18.21 4 16 4C13.79 4 12 5.79 12 8C12 10.21 13.79 12 16 12C18.21 12 20 10.21 20 8ZM5 6.5C4.44772 6.5 4 6.94772 4 7.5L4 10H1.5C0.947715 10 0.5 10.4477 0.5 11C0.5 11.5523 0.947715 12 1.5 12H4V14.5C4 15.0523 4.44772 15.5 5 15.5C5.55228 15.5 6 15.0523 6 14.5L6 12H8.5C9.05229 12 9.5 11.5523 9.5 11C9.5 10.4477 9.05229 10 8.5 10H6V7.5C6 6.94772 5.55228 6.5 5 6.5ZM8 18V19C8 19.5523 8.44772 20 9 20L23 20C23.5523 20 24 19.5523 24 19V18C24 14.5 18.67 13.5 16 13.5C13.33 13.5 8 14.5 8 18Z" 
          fill={color}
        />
      </svg>
    </span>
  )
}

export default Applications
