import React, { FC, useEffect, useState } from 'react'

import styles from './avatar.module.scss'
import { AvatarIcon } from '../icons'

type AvatarProps = {
  image?: string | undefined | null
  size?: 'small' | 'medium' | 'large'
}

const Avatar: FC<AvatarProps> = (props) => {
  const { image: link, size } = props
  const [loaded, setLoaded] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  const sizes = {
    small: 18,
    medium: 40,
    large: 56,
  }

  useEffect(() => {
    if (link && !error) {
      setLoading(true)
      const image = new Image()
      image.onload = () => {
        setLoaded(true)
        setLoading(false)
      }
      image.onerror = () => {
        console.error('Error loading pictures:', link)
        setLoaded(false)
        setError(true)
        setLoading(false)
      }
      image.src = link
    }
  }, [link, setLoaded, setLoading, setError, error])
  return (
    <div className={`${styles.avatar_wrapper} ${styles[size || 'medium']}`}>
      {link && loaded && !loading && !error ? (
        <img src={link} alt={'avatar'} data-testid={'avatar'} />
      ) : (
        <AvatarIcon color='#A4B3C6' size={sizes[size || 'medium']} />
      )}
    </div>
  )
}

export default Avatar
