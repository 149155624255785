import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import appAPI from '../../api/api'
import { Section } from '../../api/model/models'

type SectionsState = {
  sections: Section[]
  subSections: string[]
}

const initialSectionsState: SectionsState = {
  sections: [],
  subSections: [],
}

const fetchSections = createAsyncThunk('fetchSections', async (payload, { rejectWithValue }) => {
  try {
    return await appAPI.sectionsMNTK.getAllSections()
  } catch (err: any) {
    return rejectWithValue({ messages: err.response.data, status: err.response.status })
  }
})

const sectionsConferenceSlice = createSlice({
  name: 'sectionsConference',
  initialState: initialSectionsState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchSections.fulfilled, (state, { payload }) => {
      state.sections = payload.data
    })
  },
}) 

export const sectionsActions = {
  ...sectionsConferenceSlice.actions,
  fetchSections,
}

export default sectionsConferenceSlice.reducer
