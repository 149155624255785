import React, { FC, useState } from 'react'
import { Layout } from 'antd'
import { Header, Menu } from '../../components/navigation'
import ErrorHandler from '../../components/navigation/error-handler/error-handler'
import Alert from '../../components/common/alert/alert'
import Footer from '../../components/navigation/footer/footer'
import useCheckUserProfile from '../../hooks/useCheckUserProfile'
import useCheckPathForUserProfile from '../../hooks/useCheckPathForUserProfile'

const DefaultApp: FC = (props) => {
  const { children } = props
  useCheckUserProfile()
  const isUserProfile = useCheckPathForUserProfile()
  const [sticked, setSticked] = useState(false)

  return (
    <>
      <Layout>
        <Layout.Header role={'heading'} className={sticked ? 'bigHeader' : undefined}>
          <Header onScrollHeader={(val) => setSticked(val)} />
        </Layout.Header>
        <Layout>
          {!isUserProfile && (
            <>
              <Layout.Sider width={292} breakpoint={'xl'} collapsedWidth={0}>
                <Menu />
              </Layout.Sider>
            </>
          )}
          <Layout.Content>
            <ErrorHandler>{children}</ErrorHandler>
          </Layout.Content>
        </Layout>
        <Alert />
      </Layout>
      <Layout.Footer>
        <Footer />
      </Layout.Footer>
    </>
  )
}

export default DefaultApp
