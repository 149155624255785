import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import appAPI from '../../api/api'
import { Address } from '../../api/model/address'
import { Championship } from '../../api/model/championship'
import { ChampionshipType } from '../../api/model/championshipType'
import { Competence } from '../../api/model/competence'
import { ExternalEmployee } from '../../api/model/externalEmployee'
import { ChampionshipDetail } from '../../api/model/models'
import { News } from '../../api/model/news'
import { UserWSForChampionship } from '../../api/model/userWSForChampionship'

export type ChampionshipsState = {
  championships: Array<Championship>
  championshipsByNews: Array<Championship>
  championshipsTypes: Array<ChampionshipType>
  breadcrumbs: Array<Breadcrumb>
  championshipsList: Array<Championship>
  championshipId: number | undefined
  subTypeId: number | undefined
  hasDocs: boolean
  title: string
  content: string
  dateStart: string
  dateEnd: string
  championshipImg: string
  addresses: Array<Address>
  competences: Array<Competence>
  registeredCurators: Array<UserWSForChampionship>
  externalCurators: Array<ExternalEmployee>
  loadingTypes: boolean
  loadingChampionships: boolean
  loadingChampionshipDetail: boolean
  loadingChampionshipsByNews: boolean
  typesPage: number
  typesPages: number
  listPage: number
  listPages: number
  lastChampionshipId: number
}

export const initialChampionshipsState: ChampionshipsState = {
  championships: [],
  championshipsByNews: [],
  championshipsTypes: [],
  breadcrumbs: [],
  championshipsList: [],
  championshipId: undefined,
  subTypeId: undefined,
  hasDocs: false,
  title: '',
  content: '',
  dateStart: '',
  dateEnd: '',
  championshipImg: '',
  addresses: [],
  competences: [],
  registeredCurators: [],
  externalCurators: [],
  loadingTypes: false,
  loadingChampionships: false,
  loadingChampionshipDetail: false,
  loadingChampionshipsByNews: false,
  typesPage: 0,
  typesPages: 1,
  listPage: 0,
  listPages: 1,
  lastChampionshipId: 0,
}

const fetchAllChampionships = createAsyncThunk(
  'fetchAllChampionships',
  async (payload: GetChampionshipsTypesPayload) => {
    const typesData = (await appAPI.championships.getChampionshipsTypes(payload)).data.results ?? []

    let subTypeIds = typesData.map((type: any) => type.subTypes.map(({ id }: any) => id)).flat()
    subTypeIds = Array.from(new Set(subTypeIds))

    const champsPromises = subTypeIds.map(async (subTypeId: any) => {
      try {
        const response = await appAPI.championships.getChampionshipsList({ subType: `${subTypeId}`, periods: '' })
        return response.data.results ?? []
      } catch (err: any) {
        return []
      }
    })
    const championships = (await Promise.all(champsPromises)).flat()
    return championships
  },
)

const fetchChampionshipsTypes = createAsyncThunk(
  'fetchChampionshipsTypes',
  async (payload: GetChampionshipsTypesPayload, { rejectWithValue }) => {
    try {
      return appAPI.championships.getChampionshipsTypes(payload)
    } catch (err: any) {
      return rejectWithValue({ messages: err.response.data, status: err.response.status })
    }
  },
)

const fetchChampionshipsSubType = createAsyncThunk(
  'fetchChampionshipsSubType',
  async (payload: string, { rejectWithValue }) => {
    try {
      return appAPI.championships.getChampionshipsSubType(payload)
    } catch (err: any) {
      return rejectWithValue({ messages: err.response.data, status: err.response.status })
    }
  },
)

const fetchChampionshipsList = createAsyncThunk(
  'fetchChampionshipsList',
  async (payload: GetChampionshipsListPayload, { rejectWithValue }) => {
    try {
      return appAPI.championships.getChampionshipsList(payload)
    } catch (err: any) {
      return rejectWithValue({ message: err.response.data, status: err.response.status })
    }
  },
)

const fetchChampionshipDetail = createAsyncThunk(
  'fetchChampionshipDetail',
  async (payload: { id: number }, { rejectWithValue }) => {
    try {
      return appAPI.championships.getChampionshipDetail(payload)
    } catch (err: any) {
      return rejectWithValue({ message: err.response.data, status: err.response.status })
    }
  },
)

const fetchChampionshipsByNews = createAsyncThunk(
  'fetchChampionshipsDetail',
  async (news: News[], { rejectWithValue }) => {
    try {
      const resultPromises = news.map(async (newsItem) => {
        if (!newsItem.title) {
          return
        }
        return (await appAPI.championships.getChampionshipDetail({ id: 1 })).data
      })
      const result = await Promise.all(resultPromises)
      return result.filter((item) => item) as ChampionshipDetail[]
    } catch (err: any) {
      return rejectWithValue({ message: err.response.data, status: err.response.status })
    }
  },
)
const fetchLastChampionship = createAsyncThunk('fetchLastChampionship', async () => {
  return await appAPI.championships.getLastChampionship()
})

const championshipsSlice = createSlice({
  name: 'championships',
  initialState: initialChampionshipsState,
  reducers: {
    setTypePage: (state: ChampionshipsState, { payload }: PayloadAction<number>) => {
      state.typesPage = payload
    },
    setListPage: (state: ChampionshipsState, { payload }: PayloadAction<number>) => {
      state.listPage = payload
    },
    clearChampionshipsTypes: (state: ChampionshipsState) => {
      state.championshipsTypes = initialChampionshipsState.championshipsTypes
      state.typesPage = initialChampionshipsState.typesPage
      state.typesPages = initialChampionshipsState.typesPages
      state.loadingTypes = initialChampionshipsState.loadingTypes
    },
    clearChampionshipsList: (state: ChampionshipsState) => {
      state.championshipsList = initialChampionshipsState.championshipsList
      state.breadcrumbs = initialChampionshipsState.breadcrumbs
      state.listPage = initialChampionshipsState.listPage
      state.listPages = initialChampionshipsState.listPages
      state.loadingChampionships = initialChampionshipsState.loadingChampionships
    },
    clearChampionshipDetail: (state: ChampionshipsState) => {
      state.championshipId = initialChampionshipsState.championshipId
      state.subTypeId = initialChampionshipsState.subTypeId
      state.hasDocs = initialChampionshipsState.hasDocs
      state.dateStart = initialChampionshipsState.dateStart
      state.dateEnd = initialChampionshipsState.dateEnd
      state.registeredCurators = initialChampionshipsState.registeredCurators
      state.externalCurators = initialChampionshipsState.externalCurators
      state.competences = initialChampionshipsState.competences
      state.title = initialChampionshipsState.title
      state.content = initialChampionshipsState.content
      state.championshipImg = initialChampionshipsState.championshipImg
      state.loadingChampionshipDetail = initialChampionshipsState.loadingChampionshipDetail
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllChampionships.pending, (state: ChampionshipsState) => {
        state.loadingChampionships = true
      })
      .addCase(fetchAllChampionships.fulfilled, (state: ChampionshipsState, { payload }) => {
        state.championships = payload
        state.loadingChampionships = false
      })
      .addCase(fetchAllChampionships.rejected, (state: ChampionshipsState) => {
        state.loadingChampionships = false
      })

      .addCase(fetchChampionshipsTypes.pending, (state: ChampionshipsState) => {
        state.loadingTypes = true
      })
      .addCase(fetchChampionshipsTypes.fulfilled, (state: ChampionshipsState, { payload }) => {
        const data = payload.data
        state.championshipsTypes = data.results || []
        state.typesPages = data.pages || 1
        state.loadingTypes = false
      })
      .addCase(fetchChampionshipsTypes.rejected, (state: ChampionshipsState) => {
        state.loadingTypes = false
      })

      .addCase(fetchChampionshipsList.pending, (state: ChampionshipsState) => {
        state.loadingChampionships = true
      })
      .addCase(fetchChampionshipsList.fulfilled, (state: ChampionshipsState, { payload, meta }) => {
        const data = payload.data
        if (meta.arg.offset === 0) state.championshipsList = data.results || []
        else state.championshipsList = [...state.championshipsList, ...(data.results || [])]
        state.listPages = data.pages || 1
        state.loadingChampionships = false
      })
      .addCase(fetchChampionshipsList.rejected, (state: ChampionshipsState) => {
        state.loadingChampionships = false
      })

      .addCase(fetchChampionshipsSubType.pending, (state: ChampionshipsState) => {
        state.loadingChampionships = true
      })
      .addCase(fetchChampionshipsSubType.fulfilled, (state: ChampionshipsState, { payload }) => {
        state.breadcrumbs = [{ ...payload.data.champType }, { ...payload.data }]
        state.loadingChampionships = false
      })
      .addCase(fetchChampionshipsSubType.rejected, (state: ChampionshipsState) => {
        state.loadingChampionships = false
      })

      .addCase(fetchChampionshipDetail.pending, (state: ChampionshipsState) => {
        state.loadingChampionshipDetail = true
      })
      .addCase(fetchChampionshipDetail.fulfilled, (state: ChampionshipsState, { payload }) => {
        const data = payload.data
        state.championshipId = data.id
        state.subTypeId = data.subTypeId
        state.hasDocs = data.hasDocs
        state.title = data.title
        state.content = data.content
        state.dateStart = data.startDate
        state.dateEnd = data.endDate
        state.championshipImg = data.image
        state.addresses = data.addresses
        state.competences = data.competences
        state.registeredCurators = data.registeredCurators
        state.externalCurators = data.externalCurators
        state.loadingChampionshipDetail = false
      })
      .addCase(fetchChampionshipDetail.rejected, (state: ChampionshipsState) => {
        state.loadingChampionshipDetail = false
      })

      .addCase(fetchChampionshipsByNews.pending, (state: ChampionshipsState) => {
        state.loadingChampionshipsByNews = true
      })
      .addCase(fetchChampionshipsByNews.fulfilled, (state: ChampionshipsState, { payload }) => {
        state.championshipsByNews = payload
        state.loadingChampionshipsByNews = false
      })
      .addCase(fetchLastChampionship.fulfilled, (state: ChampionshipsState, { payload }) => {
        if (payload.data) state.lastChampionshipId = payload.data.id
      })
      .addCase(fetchChampionshipsByNews.rejected, (state: ChampionshipsState) => {
        state.loadingChampionshipsByNews = false
      })
  },
})

export default championshipsSlice.reducer

export const championshipsSliceActions = {
  ...championshipsSlice.actions,
  fetchAllChampionships,
  fetchChampionshipsTypes,
  fetchChampionshipsList,
  fetchChampionshipDetail,
  fetchChampionshipsByNews,
  fetchChampionshipsSubType,
  fetchLastChampionship,
}
