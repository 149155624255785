import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import api from '../../api/api'
import { SetPassword } from '../../api/model/setPassword'
import { UserWSDetail } from '../../api/model/userWSDetail'
import { UserProfileSectionKey } from '../../components/user-profile/constants/navigation-menus'
import { UploadFile } from 'antd/lib/upload/interface'
import { DockTypes } from '../../components/user-profile/components/uploading-documents/components/item-file/item-file'
import { RootState } from '../store'
import { DocumentApproveRequest } from '../../api/model/models'

interface AddDocumentType {
  files: UploadFile<any>[],
  types: DockTypes
}

interface ChangeStatusType {
  id: number,
  viewedUserId: number,
  requestData?: DocumentApproveRequest
}

export type UserProfileState = {
  loadingMain: boolean
  loadingForm: boolean
  needToUpdate: boolean
  profile: UserWSDetail | undefined
  goToSection: UserProfileSectionKey | undefined
  activeSection: UserProfileSectionKey | undefined
  loadingChangeEmailNotify: boolean
  viewedUserId: number | undefined
}

const initialUserProfileState: UserProfileState = {
  loadingMain: false,
  loadingForm: false,
  needToUpdate: false,
  profile: undefined,
  goToSection: undefined,
  activeSection: 'MainInfo',
  loadingChangeEmailNotify: false,
  viewedUserId: undefined,
}

export const addUserDocks = createAsyncThunk(
  'addUserDocks', 
  async (payload: AddDocumentType, { rejectWithValue, getState, dispatch }) => {
    try {
      const promiseList = payload.files
        .filter((file) => file.originFileObj)
        .map((item) => {
          const file = item.originFileObj as File

          return api.userDocks.addUserDocks({ document: file, type: payload.types[item.uid]})
        })

        await Promise.all(promiseList)
        const state = getState() as RootState
        if (state.account.user?.id) {
          dispatch(fetchUserProfile({id: state.account.user?.id}))
        }

    } catch (err: any) {
      return rejectWithValue({ messages: err.response.data, status: err.response.status })
    }
  },
)

export const deleteUserDocks = createAsyncThunk(
  'deleteUserDocks', 
  async (payload: number, { rejectWithValue, getState, dispatch }) => {
    try {
      await api.userDocks.deleteFile(payload)

      const state = getState() as RootState
      if (state.account.user?.id) {
        dispatch(fetchUserProfile({id: state.account.user?.id}))
      }

    } catch (err: any) {
      return rejectWithValue({ messages: err.response.data, status: err.response.status })
    }
  },
)

export const changeStatusUserDocks = createAsyncThunk(
  'changeStatusUserDocks', 
  async (payload: ChangeStatusType, { rejectWithValue, getState, dispatch }) => {
    try {
      await api.userDocks.changeStatus(payload)

      // const state = getState() as RootState
      // if (state.account.user?.id) {
        dispatch(fetchUserProfile({id: payload.viewedUserId}))
      // }

    } catch (err: any) {
      return rejectWithValue({ messages: err.response.data, status: err.response.status })
    }
  },
)

export const fetchUserProfile = createAsyncThunk(
  'fetchUserProfile',
  async (payload: GetUserProfilePayload, { rejectWithValue }) => {
    try {
      return await api.userProfile.get(payload)
    } catch (err: any) {
      return rejectWithValue({ messages: err.response.data, status: err.response.status })
    }
  },
)

export const setUserProfile = createAsyncThunk(
  'setUserProfile',
  async (payload: UserWSDetail & { id?: number }, { rejectWithValue }) => {
    try {
      const id = payload.id
      if (!id) return Promise.resolve()
      delete payload.id
      return await api.userProfile.put(id, payload)
    } catch (err: any) {
      return rejectWithValue({ messages: err.response.data, status: err.response.status })
    }
  },
)

export const setPasswordUserProfile = createAsyncThunk(
  'setPasswordUserProfile',
  async (payload: SetPassword, { rejectWithValue }) => {
    try {
      return await api.userProfile.postSetPassword(payload)
    } catch (err: any) {
      return rejectWithValue({ messages: err.response.data, status: err.response.status })
    }
  },
)

export const setAvatarUserProfile = createAsyncThunk(
  'setAvatarUserProfile',
  async (payload: PutUserProfilePayloadChangeAvatar, { rejectWithValue }) => {
    try {
      return await api.userProfile.putCreateAvatar(payload)
    } catch (err: any) {
      return rejectWithValue({ messages: err.response.data, status: err.response.status })
    }
  },
)

export const setNotifySetting = createAsyncThunk('setNotifySetting', async () => {
  return await api.userProfile.putNotifySetting()
})

const userProfileSlice = createSlice({
  name: 'userProfile',
  initialState: initialUserProfileState,
  reducers: {
    setViewedUserId: (state, { payload }: PayloadAction<number>) => {
      state.viewedUserId = payload
    },
    clearAll: () => {
      return initialUserProfileState
    },
    goToSection: (state, { payload }: PayloadAction<UserProfileSectionKey>) => {
      state.goToSection = payload
      // state.activeSection = payload
    },
    scrolledTo: (state, { payload }: PayloadAction<UserProfileSectionKey>) => {
      state.activeSection = payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserProfile.pending, (state) => {
        state.loadingMain = true
      })
      .addCase(fetchUserProfile.fulfilled, (state, { payload }) => {
        state.loadingMain = false
        state.needToUpdate = false
        state.profile = payload.data
      })
      .addCase(fetchUserProfile.rejected, (state) => {
        state.loadingMain = false
      })

      .addCase(setUserProfile.pending, (state) => {
        state.loadingForm = true
      })
      .addCase(setUserProfile.fulfilled, (state) => {
        state.loadingForm = false
        state.needToUpdate = true
      })
      .addCase(setUserProfile.rejected, (state) => {
        state.loadingForm = false
        state.needToUpdate = false
      })

      .addCase(setPasswordUserProfile.pending, (state) => {
        state.loadingForm = true
      })
      .addCase(setPasswordUserProfile.fulfilled, (state) => {
        state.loadingForm = false
        state.needToUpdate = true
      })
      .addCase(setPasswordUserProfile.rejected, (state) => {
        state.loadingForm = false
        state.needToUpdate = false
      })

      .addCase(setAvatarUserProfile.pending, (state) => {
        state.loadingForm = true
      })
      .addCase(setAvatarUserProfile.fulfilled, (state) => {
        state.loadingForm = false
        state.needToUpdate = true
      })
      .addCase(setAvatarUserProfile.rejected, (state) => {
        state.loadingForm = false
        state.needToUpdate = false
      })

      .addCase(setNotifySetting.pending, (state) => {
        state.loadingChangeEmailNotify = true
      })
      .addCase(setNotifySetting.fulfilled, (state) => {
        state.loadingChangeEmailNotify = false
      })
      .addCase(setNotifySetting.rejected, (state) => {
        state.loadingChangeEmailNotify = false
      })
  },
})

export const userProfileActions = {
  ...userProfileSlice.actions,
  addUserDocks,
  deleteUserDocks,
  changeStatusUserDocks,
  fetchUserProfile,
  setUserProfile,
  setPasswordUserProfile,
  setAvatarUserProfile,
  setNotifySetting,
}

export default userProfileSlice.reducer
