import React, { useEffect, useState } from 'react'
import styles from './survey-footer.module.scss'
import { addCountlyEvent } from '../../../../utils/stats'
import { useAppSelector } from '../../../../store/store'
import { Col, Row, Typography } from 'antd'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const SurveyFooter = () => {
  const [visibleFeedback, setVisibleFeedback] = useState(false)
  const successSend = useAppSelector((state) => state.feedback.successSend)
  const { t } = useTranslation()

  useEffect(() => {
    if (successSend) {
      setVisibleFeedback(false)
    }
  }, [successSend])

  const handleClickFeedback = () => {
    if (!visibleFeedback) {
      setVisibleFeedback(true)
      addCountlyEvent('Обратная связь', { Действие: 'Форма открыта', Источник: 'Футер' })
    }
  }

  /*
  const handleClickCloseFeedback = () => {
    setVisibleFeedback(false)
    addCountlyEvent('Обратная связь', { Действие: 'Форма закрыта без отправки', Источник: 'Футер' })
  }
*/
  return (
    <div className={styles.container}>
      <Row align={'middle'} justify={'start'} wrap={false} style={{ height: '100%' }}>
        <Col className={styles.menuColF}>
          <Link to={'/terms-of-service'}>{t("userAgreement")}</Link>
        </Col>

        <Col className={styles.menuCol}>
          <Link to={'/confidentiality-policy'}>{t("personalDataProcessingPolicy")}</Link>
        </Col>
        <Col className={styles.createCol} onClick={handleClickFeedback}>
          <Typography className={styles.feedback}>
            <a
              href="https://digitalatom.ru"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.digitalatom}
              onClick={() => addCountlyEvent('Клик по ссылке Digital Atom')}
            >
              {t("digitalAtom")}
            </a>
          </Typography>
        </Col>
        <Col className={styles.copy}>
          <Typography>© {new Date().getFullYear()}{t("footerLabel")}</Typography>
        </Col>
      </Row>
    </div>
  )
}

export default SurveyFooter
