import React, { FC } from "react"

const Warning: FC<DefaultPropsIcon> = (props) => {
  const { size = 24, onClick, className, color = '#818C99' } = props
  return (
    <span className={['anticon', 'anticon-setting', className || ''].join(' ')} onClick={onClick}>
      <svg width={size} height={size} viewBox={`0 0 24 24`} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path 
          d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM11 16V12C11 11.4477 11.4477 11 12 11C12.5523 11 13 11.4477 13 12V16C13 16.5523 12.5523 17 12 17C11.4477 17 11 16.5523 11 16ZM10.7502 8.24998V7.7502C10.7502 7.19791 11.1979 6.7502 11.7502 6.7502H12.25C12.8023 6.7502 13.25 7.19791 13.25 7.7502V8.24998C13.25 8.80226 12.8023 9.24998 12.25 9.24998H11.7502C11.1979 9.24998 10.7502 8.80226 10.7502 8.24998Z" 
          fill={color}
        />
      </svg>
    </span>
  )
}

export default Warning

