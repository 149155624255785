import React from 'react'

const Conference: React.FC<DefaultPropsIcon> = (props) => {
  const { size = 24, onClick, className, color = '#818C99' } = props
  return (
    <span className={['anticon', 'anticon-setting', className || ''].join(' ')} onClick={onClick}>
      <svg width={size} height={size} viewBox={`0 0 24 24`} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M13.6667 21.1667C13.6667 21.6267 13.2933 22 12.8333 22H2.83333C2.37333 22 2 21.6267 2 21.1667C2 17.945 4.61167 15.3333 7.83333 15.3333C11.055 15.3333 13.6667 17.945 13.6667 21.1667ZM7.83333 7C5.9925 7 4.5 8.4925 4.5 10.3333C4.5 12.1742 5.9925 13.6667 7.83333 13.6667C9.67417 13.6667 11.1667 12.1742 11.1667 10.3333C11.1667 8.4925 9.67417 7 7.83333 7ZM22 6.16667V12.8333C22 15.1342 20.1342 17 17.8333 17H14.0617C13.2742 15.8283 12.1658 14.8892 10.8625 14.3108C12.06 13.3975 12.8333 11.9558 12.8333 10.3333C12.8333 7.57167 10.595 5.33333 7.83333 5.33333C6.93 5.33333 6.08167 5.57333 5.35083 5.9925C5.44417 3.7775 7.2625 2 9.5 2H17.8333C20.1342 2 22 3.86583 22 6.16667ZM18.6667 14.5C18.6667 14.0392 18.2933 13.6667 17.8333 13.6667H14.9167C14.4567 13.6667 14.0833 14.0392 14.0833 14.5C14.0833 14.9608 14.4567 15.3333 14.9167 15.3333H17.8333C18.2933 15.3333 18.6667 14.9608 18.6667 14.5Z"
          fill={color}
        />
      </svg>
    </span>
  )
}

export default Conference
