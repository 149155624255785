import React, { FC } from "react"

const Fix: FC<DefaultPropsIcon> = (props) => {
  const { size = 24, onClick, className, color = '#818C99' } = props
  return (
    <span className={['anticon', 'anticon-setting', className || ''].join(' ')} onClick={onClick}>
      <svg width={size} height={size} viewBox={`0 0 24 24`} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path 
          d="M17.3558 14.1978C17.7077 13.8468 18.2776 13.8474 18.6286 14.1993C18.9797 14.5512 18.979 15.1211 18.6271 15.4721L14.0643 20.0242C11.5536 22.5289 7.48776 22.5241 4.98298 20.0134C4.95572 19.986 4.95572 19.986 4.92868 19.9583C2.34868 17.3092 2.35833 13.084 4.95042 10.4466L11.7862 3.49139C13.588 1.65817 16.5347 1.63266 18.3679 3.43441C18.407 3.47288 18.4455 3.51204 18.4833 3.55187L18.5148 3.58518C20.3243 5.49398 20.304 8.49085 18.4689 10.375L11.5768 17.4515C10.5166 18.5401 8.79579 18.6257 7.63278 17.6476L7.55711 17.584C6.39786 16.6091 6.24841 14.879 7.22332 13.7197C7.25904 13.6772 7.29605 13.6359 7.33428 13.5956L11.9727 8.71487C12.3151 8.35456 12.8847 8.34006 13.245 8.68247C13.6053 9.02488 13.6198 9.59454 13.2774 9.95485L8.63906 14.8356C8.62592 14.8494 8.6132 14.8637 8.60092 14.8783C8.26586 15.2767 8.31722 15.8713 8.71565 16.2064L8.79132 16.27C9.23246 16.641 9.88516 16.6085 10.2873 16.1956L17.1795 9.11912C18.3404 7.9271 18.3532 6.03113 17.2085 4.82352L17.1769 4.7902C17.1538 4.76578 17.1302 4.74176 17.1062 4.71817C15.982 3.61325 14.1749 3.6289 13.07 4.75312L6.23418 11.7083C4.32817 13.6476 4.32107 16.7545 6.2182 18.7025C6.23766 18.7224 6.23766 18.7224 6.25728 18.7421C8.05994 20.5491 10.9861 20.5525 12.793 18.7499L17.3558 14.1978Z" 
          fill={color}
        />
      </svg>
    </span>
  )
}

export default Fix
