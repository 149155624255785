import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import appAPI from '../../api/api'
import { ManualMailingMsg } from "../../api/model/models"


interface ManualMailingState {
  mailingList: React.Key[]
  mailingStatus: 'success' | 'error' | undefined
}

const initialManualMailingState: ManualMailingState = {
  mailingList: [],
  mailingStatus: undefined,
}

export const sendMails = createAsyncThunk('sendMails', async (payload: ManualMailingMsg, { rejectWithValue }) => {
  try {
    return await appAPI.mailing.sendMails(payload)
  } catch (err: any) {
    return rejectWithValue({ messages: err.response.data, status: err.response.status })
  }
})

const manualMailingSlice = createSlice({
  name: 'manualMailing',
  initialState: initialManualMailingState,
  reducers: {
    setMailingList: (state: ManualMailingState, { payload }: PayloadAction<React.Key[]>) => {
      state.mailingList = payload
    },
    setMailingStatus: (state: ManualMailingState, { payload }: PayloadAction<'success' | 'error' | undefined>) => {
      state.mailingStatus = payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(sendMails.fulfilled, (state, { payload }) => {
        if(payload.status < 400) {
          state.mailingStatus = "success"
        } else {
          state.mailingStatus = "error"
        }
      })
      .addCase(sendMails.rejected, (state, data) => {
        state.mailingStatus = "error"
      })
  },
})

export const manualMailingActions = {
  ...manualMailingSlice.actions,
  sendMails,
}

export default manualMailingSlice.reducer