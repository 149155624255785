import React, { FC } from 'react'

type Props = {
  size?: string | number
  className?: string
  onClick?: () => void
  color?: string
}

const Avatar: FC<Props> = (props) => {
  const { size = '40', className = '', color = "#AC97CD", ...restProps } = props

  return (
    <svg
      width={size}
      className={className}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <circle cx="20" cy="20" r="20" fill={color} />
      <circle cx="20" cy="16" r="3" stroke="white" strokeWidth="2" />
      <path
        d="M13 26C14.8576 24.1344 17.3108 23 20 23C22.6892 23 25.1424 24.1344 27 26"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  )
}

export default Avatar
