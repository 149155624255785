import React from 'react'

const Exhibition: React.FC<DefaultPropsIcon> = (props) => {
  const { size = 24, onClick, className, color = '#818C99' } = props
  return (
    <span className={['anticon', 'anticon-setting', className || ''].join(' ')} onClick={onClick}>
      <svg width={size} height={size} viewBox={`0 0 24 24`} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M15.3435 8.48496L19.773 8.90994C21.3143 9.05781 21.787 10.5634 20.5968 11.5525L17.129 14.4347L18.4164 19.13C18.8391 20.6719 17.5275 21.6053 16.2137 20.6774L12 17.701L7.78631 20.6774C6.47785 21.6016 5.16081 20.6721 5.58365 19.13L6.87104 14.4347L3.40318 11.5525C2.20801 10.5592 2.67885 9.05847 4.22675 8.90994L8.65551 8.48496L10.6067 3.98632C11.2177 2.57752 12.7826 2.57822 13.3932 3.98646L15.3435 8.48496Z"
          fill={color}
        />
      </svg>
    </span>
  )
}

export default Exhibition
