import React, { FC } from 'react'

const LogoFullEn: FC = () => {
  return (
    <svg width="142" height="42" viewBox="0 0 142 42" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M24.2716 23.9482C22.6415 25.7562 19.8554 25.9044 18.0326 24.2743C16.2247 22.6441 16.0765 19.8581 17.7066 18.0353C19.3368 16.2273 22.1376 16.0791 23.9456 17.7241C25.7684 19.3542 25.9018 22.1403 24.2716 23.9482Z" fill="url(#paint0_radial_13990_38823)"/>
    <path d="M8.37268 4.22312C5.48289 6.40158 3.18587 9.30619 1.71875 12.6702C3.46744 13.1 8.68387 14.8042 11.0846 21.2803C11.8108 21.0432 13.3668 20.1392 14.4338 16.6418C15.8861 11.9589 18.6277 7.45376 22.1844 5.94218C18.6425 2.41515 13.9744 1.58526 8.37268 4.22312Z" fill="url(#paint1_linear_13990_38823)"/>
    <path d="M37.18 7.60237C33.327 2.94907 27.5178 0 20.9972 0C16.255 0 11.8833 1.57086 8.37109 4.22354C16.1513 0.577958 22.1383 3.5863 25.7987 11.1887C31.6376 12.226 35.6092 9.32142 37.18 7.60237Z" fill="url(#paint2_linear_13990_38823)"/>
    <path d="M12.7871 40.3239C16.1214 41.7317 19.7818 42.28 23.4274 41.8651C22.9236 40.1312 21.7825 34.7666 26.1986 29.4464C25.6355 28.9425 24.0795 28.0534 20.508 28.8536C15.7361 29.9354 10.4604 29.8169 7.36315 27.4902C6.08868 32.3362 7.71882 36.7968 12.7871 40.3239Z" fill="url(#paint3_linear_13990_38823)"/>
    <path d="M1.31821 13.6787C-0.78615 19.3397 -0.430469 25.8603 2.81499 31.4917C5.18609 35.5966 8.74275 38.605 12.7884 40.324C5.74922 35.4188 5.3491 28.7204 10.1061 21.7405C8.09069 16.1536 3.58558 14.1826 1.31821 13.6787Z" fill="url(#paint4_linear_13990_38823)"/>
    <path d="M41.8496 18.45C41.405 14.8637 40.0416 11.4108 37.8632 8.47656C36.6183 9.76585 32.5282 13.4559 25.7261 12.2852C25.563 13.0261 25.5779 14.8193 28.0675 17.5164C31.4019 21.1175 33.9212 25.7412 33.4618 29.5794C38.2633 28.2457 41.3161 24.6001 41.8496 18.45Z" fill="url(#paint5_linear_13990_38823)"/>
    <path d="M24.5088 41.702C30.4662 40.6943 35.9346 37.1377 39.18 31.4915C41.5511 27.3865 42.381 22.8073 41.8327 18.4355C41.1066 26.9864 35.5048 30.6764 27.0874 30.054C23.264 34.5887 23.7975 39.494 24.5088 41.702Z" fill="url(#paint6_linear_13990_38823)"/>
    <path d="M48.829 4.40137H48.4141V37.5821H48.829V4.40137Z" fill="#414042"/>
    <path d="M55.2609 12.7295H57.1578C58.6101 12.7295 59.3659 13.3371 59.3659 14.3893C59.3659 15.3674 58.7583 15.9009 58.0173 16.0787C58.6101 16.301 58.9213 17.7088 59.6326 19.087H58.6842C57.5135 16.7604 57.795 16.2565 56.6836 16.2565H56.0612V19.087H55.2461V12.7295H55.2609ZM56.076 15.5007H57.217C58.0025 15.5007 58.5064 15.234 58.5064 14.4782C58.5064 13.8558 58.1062 13.5001 57.1282 13.5001H56.0908V15.5007H56.076Z" fill="#414042"/>
    <path d="M61.4414 15.9157C61.4414 13.8706 62.6121 12.6406 64.3016 12.6406C66.1985 12.6406 67.221 13.9892 67.221 15.9157C67.221 18.0794 66.0058 19.1908 64.3312 19.1908C62.5529 19.1908 61.4414 17.9312 61.4414 15.9157ZM66.3318 15.9157C66.3318 14.167 65.5464 13.3964 64.2868 13.3964C63.1605 13.3964 62.3009 14.2115 62.3009 15.9157C62.3009 17.5903 63.1457 18.435 64.3905 18.435C65.5909 18.4202 66.3318 17.5607 66.3318 15.9157Z" fill="#414042"/>
    <path d="M69.3856 17.9611C69.682 18.1686 70.2006 18.4205 70.8527 18.4205C71.6974 18.4205 72.1123 18.0352 72.1123 17.3684C72.1123 16.6274 71.7567 16.4347 70.8823 16.0939L70.66 16.005C69.7412 15.6493 69.2818 15.2195 69.2818 14.2415C69.2818 13.2189 70.0525 12.6113 71.3269 12.6113C72.1124 12.6113 72.6903 12.8633 72.8237 12.9522L72.6162 13.6487C72.1568 13.4264 71.6677 13.3671 71.2676 13.3671C70.5563 13.3671 70.1562 13.6635 70.1562 14.2266C70.1562 14.7305 70.3637 15.0121 71.3566 15.3974L71.5936 15.4863C72.7051 15.9161 72.9719 16.4496 72.9719 17.2794C72.9719 18.4798 72.1272 19.1467 70.912 19.1467C70.2451 19.1467 69.593 18.954 69.1484 18.6577L69.3856 17.9611Z" fill="#414042"/>
    <path d="M78.8106 19.1018H75.2539V12.7295H78.8106V13.5001H76.069V15.4266H78.5734V16.1676H76.069V18.3312H78.8106V19.1018Z" fill="#414042"/>
    <path d="M85.9983 19.1018H84.9461L81.9674 13.7965V19.1018H81.1523V12.7295H82.2935L85.1833 17.9015V12.7295H85.9983V19.1018Z" fill="#414042"/>
    <path d="M92.1035 19.1018H88.5469V12.7295H92.1035V13.5001H89.3619V15.4266H91.8664V16.1676H89.3619V18.3312H92.1035V19.1018Z" fill="#414042"/>
    <path d="M94.4289 12.7295H96.3258C97.7781 12.7295 98.5339 13.3371 98.5339 14.3893C98.5339 15.3674 97.9263 15.9009 97.1853 16.0787C97.7781 16.301 98.0893 17.7088 98.8006 19.087H97.8522C96.6815 16.7604 96.963 16.2565 95.8516 16.2565H95.2291V19.087H94.4141V12.7295H94.4289ZM95.244 15.5007H96.3851C97.1705 15.5007 97.6743 15.234 97.6743 14.4782C97.6743 13.8558 97.2742 13.5001 96.2962 13.5001H95.2588V15.5007H95.244Z" fill="#414042"/>
    <path d="M103.839 12.626C104.565 12.626 104.995 12.7593 105.291 12.8779L105.143 13.6041C104.743 13.4559 104.328 13.3966 103.928 13.3966C102.416 13.3966 101.601 14.2709 101.601 15.9159C101.601 17.5016 102.505 18.4352 104.031 18.4352C104.254 18.4352 104.565 18.3907 104.832 18.3166V16.5679H103.498V15.7973H105.661V18.8205C105.246 19.0428 104.669 19.191 103.928 19.191C102.001 19.191 100.727 18.0499 100.727 15.99C100.727 13.8263 101.942 12.626 103.839 12.626Z" fill="#414042"/>
    <path d="M107.738 15.9157C107.738 13.8706 108.909 12.6406 110.598 12.6406C112.495 12.6406 113.518 13.9892 113.518 15.9157C113.518 18.0794 112.303 19.1908 110.628 19.1908C108.865 19.1908 107.738 17.9312 107.738 15.9157ZM112.629 15.9157C112.629 14.167 111.843 13.3964 110.584 13.3964C109.457 13.3964 108.598 14.2115 108.598 15.9157C108.598 17.5903 109.443 18.435 110.687 18.435C111.903 18.4202 112.629 17.5607 112.629 15.9157Z" fill="#414042"/>
    <path d="M119.132 17.3976H116.405L115.783 19.1018H114.953L117.383 12.7295H118.199L120.585 19.1018H119.725L119.132 17.3976ZM116.672 16.6418H118.865L117.798 13.6483H117.769L116.672 16.6418Z" fill="#414042"/>
    <path d="M123.846 19.1018H123.03V13.5001H121.148V12.7295H125.742V13.5001H123.86V19.1018H123.846Z" fill="#414042"/>
    <path d="M127.09 15.9157C127.09 13.8706 128.261 12.6406 129.95 12.6406C131.847 12.6406 132.869 13.9892 132.869 15.9157C132.869 18.0794 131.654 19.1908 129.98 19.1908C128.216 19.1908 127.09 17.9312 127.09 15.9157ZM131.995 15.9157C131.995 14.167 131.21 13.3964 129.95 13.3964C128.824 13.3964 127.964 14.2115 127.964 15.9157C127.964 17.5903 128.809 18.435 130.054 18.435C131.254 18.4202 131.995 17.5607 131.995 15.9157Z" fill="#414042"/>
    <path d="M141.362 19.1018H140.547L140.162 14.1225L138.546 19.1018H137.687L136.086 14.1373L135.686 19.1018H134.871L135.419 12.7295H136.427L138.146 18.2127L139.836 12.7295H140.843L141.362 19.1018Z" fill="#414042"/>
    <path d="M55.2617 23.7705H56.7881C57.944 23.7705 58.5665 24.2595 58.5665 25.1043C58.5665 25.8897 58.0922 26.3195 57.4846 26.4528C57.9589 26.6307 58.2108 27.7569 58.7887 28.8684H58.0329C57.0993 27.0011 57.3216 26.601 56.4325 26.601H55.9286V28.8684H55.2766V23.7705H55.2617ZM55.9138 25.9934H56.8178C57.4402 25.9934 57.8403 25.7711 57.8403 25.1635C57.8403 24.6597 57.5143 24.3781 56.7289 24.3781H55.899V25.9934H55.9138Z" fill="#035FA2"/>
    <path d="M60.1367 26.334C60.1367 24.7038 61.0703 23.7109 62.4189 23.7109C63.9305 23.7109 64.7455 24.7928 64.7455 26.334C64.7455 28.0679 63.7675 28.957 62.4337 28.957C61.0259 28.957 60.1367 27.9493 60.1367 26.334ZM64.049 26.334C64.049 24.941 63.4266 24.3185 62.4189 24.3185C61.5297 24.3185 60.8332 24.9706 60.8332 26.334C60.8332 27.6677 61.5001 28.3494 62.5078 28.3494C63.4563 28.3346 64.049 27.6529 64.049 26.334Z" fill="#035FA2"/>
    <path d="M66.4208 27.9789C66.658 28.1419 67.0729 28.3494 67.5916 28.3494C68.2733 28.3494 68.5993 28.0382 68.5993 27.5047C68.5993 26.9119 68.3177 26.7489 67.6212 26.4822L67.4434 26.4229C66.7024 26.1413 66.3467 25.8005 66.3467 25.015C66.3467 24.1852 66.9692 23.7109 67.9769 23.7109C68.5993 23.7109 69.0735 23.9036 69.1773 23.9925L69.0142 24.5556C68.6438 24.3778 68.2436 24.3334 67.9324 24.3334C67.3693 24.3334 67.0432 24.5705 67.0432 25.0299C67.0432 25.43 67.2063 25.6671 68.0065 25.9635L68.1992 26.0376C69.0883 26.3784 69.3106 26.8082 69.3106 27.4751C69.3106 28.4383 68.6437 28.9718 67.6657 28.9718C67.1322 28.9718 66.6135 28.8236 66.2578 28.5865L66.4208 27.9789Z" fill="#035FA2"/>
    <path d="M73.8734 27.5197H71.695L71.2059 28.883H70.5391L72.4804 23.7852H73.1325L75.0442 28.883H74.3625L73.8734 27.5197ZM71.9173 26.9121H73.6808L72.8212 24.5113H72.7916L71.9173 26.9121Z" fill="#035FA2"/>
    <path d="M77.5816 28.883H76.9295V24.3928H75.418V23.7852H79.0932V24.3928H77.5816V28.883Z" fill="#035FA2"/>
    <path d="M80.1133 26.334C80.1133 24.7038 81.0469 23.7109 82.3955 23.7109C83.9071 23.7109 84.7221 24.7928 84.7221 26.334C84.7221 28.0679 83.744 28.957 82.4103 28.957C81.0024 28.957 80.1133 27.9493 80.1133 26.334ZM84.0256 26.334C84.0256 24.941 83.4032 24.3185 82.3955 24.3185C81.5063 24.3185 80.8098 24.9706 80.8098 26.334C80.8098 27.6677 81.4767 28.3494 82.4844 28.3494C83.4477 28.3346 84.0256 27.6529 84.0256 26.334Z" fill="#035FA2"/>
    <path d="M91.4673 28.883H90.8152L90.504 24.8966L89.2147 28.883H88.533L87.2437 24.9114L86.9177 28.883H86.2656L86.7102 23.7852H87.5105L88.8887 28.1717L90.2372 23.7852H91.0375L91.4673 28.883Z" fill="#035FA2"/>
    <defs>
    <radialGradient id="paint0_radial_13990_38823" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(19.5799 18.5313) scale(6.21032 6.21032)">
    <stop stopColor="#4495D1"/>
    <stop offset="1" stopColor="#15256D"/>
    </radialGradient>
    <linearGradient id="paint1_linear_13990_38823" x1="6.10779" y1="18.0293" x2="16.551" y2="1.94808" gradientUnits="userSpaceOnUse">
    <stop stopColor="#4495D1"/>
    <stop offset="1" stopColor="#15256D"/>
    </linearGradient>
    <linearGradient id="paint2_linear_13990_38823" x1="10.2746" y1="-0.503638" x2="36.2172" y2="9.97782" gradientUnits="userSpaceOnUse">
    <stop stopColor="#4495D1"/>
    <stop offset="1" stopColor="#15256D"/>
    </linearGradient>
    <linearGradient id="paint3_linear_13990_38823" x1="25.8723" y1="35.3823" x2="6.72556" y2="34.3791" gradientUnits="userSpaceOnUse">
    <stop stopColor="#4495D1"/>
    <stop offset="1" stopColor="#15256D"/>
    </linearGradient>
    <linearGradient id="paint4_linear_13990_38823" x1="7.74105" y1="41.0396" x2="3.84644" y2="13.3306" gradientUnits="userSpaceOnUse">
    <stop stopColor="#4495D1"/>
    <stop offset="1" stopColor="#15256D"/>
    </linearGradient>
    <linearGradient id="paint5_linear_13990_38823" x1="31.0183" y1="9.57846" x2="39.723" y2="26.663" gradientUnits="userSpaceOnUse">
    <stop stopColor="#4495D1"/>
    <stop offset="1" stopColor="#15256D"/>
    </linearGradient>
    <linearGradient id="paint6_linear_13990_38823" x1="44.9818" y1="22.4551" x2="22.9327" y2="39.6813" gradientUnits="userSpaceOnUse">
    <stop stopColor="#4495D1"/>
    <stop offset="1" stopColor="#15256D"/>
    </linearGradient>
    </defs>
    </svg>
  )
}

export default LogoFullEn
