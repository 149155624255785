import { useState } from 'react'
import { FormInstance } from 'antd'

interface PropsType {
  companyNotExistOption: { id: number; name: string }
  form: FormInstance<any>
}

const useChangeCompany = (props: PropsType) => {
  const { companyNotExistOption, form } = props

  const [showCustomCompanyField, setShowCustomCompanyField] = useState(false)
  const [needNewField, setNeedNewField] = useState(false)
  const [selectedCompany, setSelectedCompany] = useState<undefined | number>(undefined)

  const onChangeCompany = () => {
    const { getFieldValue } = form
    const companyNotExistOptionSelected = parseInt(getFieldValue('company')) === companyNotExistOption.id
    const companyId = parseInt(getFieldValue('company'))
    setSelectedCompany(companyId)

    if (companyNotExistOptionSelected) {
      setShowCustomCompanyField(true)
      setNeedNewField(true)
    } else if (!companyNotExistOptionSelected && showCustomCompanyField) {
      setShowCustomCompanyField(false)
      setNeedNewField(false)
    }
  }

  return {
    onChangeCompany,
    needNewField,
    selectedCompany,
  }
}

export default useChangeCompany
