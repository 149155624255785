import React from 'react'

const SectionApplications: React.FC<DefaultPropsIcon> = (props) => {
  const { size = 24, onClick, className, color = '#818C99' } = props
  return (
    <span className={['anticon', 'anticon-setting', className || ''].join(' ')} onClick={onClick}>
      <svg width={size} height={size} viewBox={`0 0 24 24`} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.5 0C3.94772 0 3.5 0.447715 3.5 1L3.5 3.5L1 3.5C0.447715 3.5 0 3.94772 0 4.5C0 5.05228 0.447715 5.5 1 5.5L3.5 5.5L3.5 8C3.5 8.55228 3.94772 9 4.5 9C5.05228 9 5.5 8.55228 5.5 8V5.5H8C8.55229 5.5 9 5.05228 9 4.5C9 3.94772 8.55229 3.5 8 3.5L5.5 3.5L5.5 1C5.5 0.447715 5.05228 0 4.5 0Z" fill={color}/>
        <path d="M15.9384 5.58334C17.2034 5.58334 18.2284 4.55759 18.2284 3.29169C18.2284 2.02575 17.2034 1 15.9384 1C14.6734 1 13.6484 2.02575 13.6484 3.29165C13.6484 4.55759 14.6734 5.58334 15.9384 5.58334Z" fill={color}/>
        <path d="M22.1177 11.25H9.88237C8.84423 11.25 8 12.0722 8 13.0833C8 14.0944 8.84423 14.9167 9.88237 14.9167H9.93129L10.8264 22.7588C10.8527 22.9916 11.0541 23.1667 11.2941 23.1667H20.7059C20.9459 23.1667 21.1473 22.9916 21.1737 22.7588L22.0687 14.9166H22.1176C23.1558 14.9166 24 14.0944 24 13.0833C24 12.0722 23.1558 11.25 22.1177 11.25Z" fill={color}/>
        <path d="M12.8212 10.2497H19.1741C19.3097 10.2497 19.4377 10.1928 19.5271 10.0948C19.6165 9.99575 19.6579 9.86465 19.641 9.7345L19.4941 8.5905C19.3473 7.4456 18.344 6.58301 17.16 6.58301H14.8362C13.6523 6.58301 12.648 7.4456 12.5021 8.5905L12.3553 9.7345C12.3383 9.86465 12.3798 9.99575 12.4692 10.0948C12.5577 10.1928 12.6857 10.2497 12.8212 10.2497Z" fill={color}/>
      </svg>
    </span>
  )
}

export default SectionApplications
