import { Button, Row } from 'antd'
import { UploadFile } from 'antd/es/upload/interface'
import React, { FC, useEffect, useState } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { competenceDocsSliceActions } from '../../../store/slices/competenceDocsSlice'
import { Dropdown, Modal } from '../../common'
import { Text } from '../../common/text'
import CompetenceDocsAddAndEditFolderModal from '../../competencies/competence-detail/competence-docs/competence-docs-modals/competence-docs-add-and-edit-folder-modal'
import CompetenceDocsAddDocModal from '../../competencies/competence-detail/competence-docs/competence-docs-modals/competence-docs-add-doc-modal'
import { File, More, TrashBin } from '../icons'
import { Title } from '../text'
import styles from './document-card.module.scss'
import { StatisticPopover } from '../../popovers'
import { RootState } from '../../../store/store'
import { useTranslation } from 'react-i18next'

type CompetenceCardProps = {
  id?: number
  title: string
  size?: string
  link?: string
  type?: 'document' | 'directory'
  items?: number
  competenceId?: string
  canEdit?: boolean
  canViewStatistic?: boolean
  setVisibleAcceptBox?: (visibleAcceptBox: boolean) => void
  notificationsAllowed?: boolean
  viewingStatus?: boolean
}

const DocumentCard: FC<CompetenceCardProps> = (props: CompetenceCardProps) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const {
    id,
    title,
    size,
    link,
    type,
    items,
    competenceId,
    canEdit,
    canViewStatistic,
    setVisibleAcceptBox,
    notificationsAllowed,
    viewingStatus,
  } = props

  const format = (link: string): string => {
    const result = /[^.]+$/.exec(link)
    return result !== null ? result[0] : ''
  }

  const handleNumberLabel = (amount: number): string => {
    const lastDigit = amount % 10
    if ((lastDigit > 4 && lastDigit < 9) || lastDigit === 0 || (amount > 10 && amount < 15))
      return `${amount} элементов`
    else if (lastDigit === 1) return `${amount} элемент`
    else if (lastDigit > 1 && lastDigit < 5) return `${amount} элемента`
    else return `${amount} элементов`
  }

  const [visibleAddDocument, setVisibleAddDocument] = useState<boolean>(false)
  const [visibleRenameFolder, setVisibleRenameFolder] = useState<boolean>(false)
  const { competenceDocsStatistic, downloadDockPending } = useSelector((state: RootState) => state.competenceDocs)

  const hideModal = () => {
    setVisibleAddDocument(false)
    setVisibleRenameFolder(false)
  }

  const addDocument = async (files: UploadFile[]) => {
    await dispatch(competenceDocsSliceActions.addCompetenceDocument({ files, parent: id as number, owner: false }))
  }

  const deleteFile = async () => {
    await dispatch(competenceDocsSliceActions.deleteCompetenceDocument(String(id)))
  }

  const renameFolder = async (folderName: string) => {
    await dispatch(
      competenceDocsSliceActions.renameCompetenceDirectory({
        id: id,
        name: folderName,
      }),
    )
  }

  const deleteFolder = async () => {
    await dispatch(competenceDocsSliceActions.deleteCompetenceDirectory(String(id)))
  }

  const showConfirmDelete = (onDelete: () => void, directory: string) => {
    Modal.confirm({
      title: 'Подтверждение удаления',
      onOk: () => onDelete(),
      content: `Вы действительно хотите удалить ${directory}?`,
      bodyStyle: { whiteSpace: 'pre-wrap' },
      cancelText: 'Отмена',
      okText: 'Подтвердить',
      okType: 'dashed',
    })
  }

  const handleDropdown = (key: string, value: string) => {
    if (value === 'addDocument') setVisibleAddDocument(true)
    else if (value === 'renameFolder') setVisibleRenameFolder(true)
    else if (value === 'deleteFolder') showConfirmDelete(deleteFolder, 'папку')
  }

  const movieFile = async (draggableId: number, targetId: number) => {
    await dispatch(competenceDocsSliceActions.movingCompetenceDocument({ id: draggableId, parent: targetId }))
  }

  const movieFolder = async (draggableId: number, targetId: number) => {
    await dispatch(competenceDocsSliceActions.movingCompetenceDirectory({ id: draggableId, parent: targetId }))
  }

  const downloadDock = () => {
    dispatch(competenceDocsSliceActions.addCompetenceDocsStatistic({ id: String(id) }))
  }

  const [{ isActive }, drop] = useDrop(() => ({
    accept: 'box',
    drop: () => ({ id }),
    collect: (monitor) => ({
      isActive: monitor.canDrop() && monitor.isOver(),
    }),
  }))

  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'box',
    item: { id },
    end: (item, monitor) => {
      const targetId = monitor.getDropResult<{ id: number }>()?.id
      const draggableId = item.id

      if (targetId === undefined || !draggableId || draggableId === targetId) return

      if (type && type === 'document') {
        movieFile(draggableId, targetId)
      } else if (type && type === 'directory') {
        movieFolder(draggableId, targetId)
      }
    },
    options: {
      dropEffect: 'move',
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }))

  useEffect(() => {
    if (setVisibleAcceptBox) {
      setVisibleAcceptBox(isDragging)
    }
  }, [isDragging, setVisibleAcceptBox])

  useEffect(() => {
    if (id && type === 'document' && !downloadDockPending) {
      dispatch(competenceDocsSliceActions.fetchCompetenceDocsStatistic({ id: String(id) }))
    }
  }, [dispatch, type, id, downloadDockPending])

  if (type && type === 'document') {
    return (
      <article
        className={`${styles.card} ${styles.cardFile} ${isDragging ? styles.cardFileOpacity : ''} ${
          notificationsAllowed && viewingStatus ? styles.newDock : ''
        }`}
        data-testid="card"
        ref={canEdit ? drag : undefined}
      >
        <Row className={styles.titleRow}>
          <Title level={4} ellipsis={{ rows: 2 }} className={styles.title}>
            {title}
          </Title>
          {canEdit ? (
            <TrashBin className={styles.icon} onClick={() => showConfirmDelete(deleteFile, 'документ')} />
          ) : (
            <></>
          )}
        </Row>
        <span className={styles.txtSize}>{size}</span>
        <div className={styles.footer}>
          <div className={styles.actionButtons}>
            <File className={styles.icon} format={format(link || '')} />
            {canViewStatistic && (
              <StatisticPopover
                title="Статистика скачивания"
                statisticType="downloads"
                className={styles.statisticButton}
                statisticName={title}
                data={competenceDocsStatistic[String(id)]}
              />
            )}
          </div>
          <Button onClick={() => downloadDock()} href={link} download type={'primary'} className={styles.btnLink}>
            {t("download")}
          </Button>
        </div>
      </article>
    )
  } else if (type && type === 'directory') {
    return (
      <div ref={canEdit ? drop : undefined}>
        <article
          className={`${styles.card} ${styles.cardFolder} ${isActive ? styles.cardFolderActive : ''}`}
          data-testid="card"
          ref={canEdit ? drag : undefined}
        >
          <Row className={styles.titleRow}>
            <Title level={4} ellipsis={{ rows: 2 }} className={styles.title}>
              {title}
            </Title>
            {canEdit ? (
              <Dropdown
                icon={<More />}
                type="ghost"
                trigger={['click']}
                onItemClick={handleDropdown}
                classNameButton={styles.button}
              >
                <Dropdown.Option key={1} value="addDocument" className={styles.oneline}>
                  <Text>Загрузить документ</Text>
                </Dropdown.Option>
                <Dropdown.Option key={2} value="renameFolder" className={styles.oneline}>
                  <Text>Переименовать папку</Text>
                </Dropdown.Option>
                <Dropdown.Option key={3} value="deleteFolder" className={styles.oneline}>
                  <Text>Удалить папку</Text>
                </Dropdown.Option>
              </Dropdown>
            ) : (
              <></>
            )}
          </Row>
          <span className={styles.txtSize}>{`${handleNumberLabel(items || 0)}`}</span>
          <div className={styles.footer}>
            <File className={styles.icon} format={format(link || '')} />
            <Link to={`/competencies/${competenceId}/docs/${id}`}>
              <Button type={'primary'} className={styles.btnLink}>
                ОТКРЫТЬ
              </Button>
            </Link>
          </div>
        </article>

        <CompetenceDocsAddDocModal visibleForm={visibleAddDocument} onCloseForm={hideModal} onSaveForm={addDocument} />
        <CompetenceDocsAddAndEditFolderModal
          visibleForm={visibleRenameFolder}
          onCloseForm={hideModal}
          onSaveForm={renameFolder}
          title="Переименовать папку"
          initialName={title}
          typeModal="edit"
        />
      </div>
    )
  } else {
    return <></>
  }
}

export default DocumentCard
