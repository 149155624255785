export const fullNameSplitting: fullNameSplittingType = (fullName) => {
  const nameItems = fullName.split(' ')

  return {
    lastName: nameItems[0],
    firstName: nameItems[1],
    middleName: nameItems[2],
  }
}

type fullNameSplittingType = (fullName: string) => {
  lastName: string
  firstName: string
  middleName: string | undefined
}
