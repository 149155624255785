import { Empty, Upload as AntUpload } from 'antd'
import { ItemRender, UploadFile } from 'antd/lib/upload/interface'
import React, { FC } from 'react'
import { useDispatch } from 'react-redux'
import { alertActions } from '../../../store/slices/alertSlice'
import { getAcceptFormats } from '../../../utils/getAcceptFormat'
import { Text } from '../../common/text'
import FilePreview from '../file-preview/file-preview'
import { TooltipHelper } from '../index'
import './upload.ant.scss'

type UploadProps = {
  files: UploadFile[]
  onUploadFileAdd: (files: UploadFile[]) => void
  onRemoveFile: (uid: string) => void
  tooltipDescription?: string
  withoutVideo?: boolean
  formatFiles?: string
  description?: React.ReactNode
  itemRender?: ItemRender<any>
  nedUploadBottom?: boolean
  titleItems?: React.ReactNode
}

const MAX_VIDEO_FILE_SIZE_MB = 500
const MAX_VIDEO_FILE_SIZE = MAX_VIDEO_FILE_SIZE_MB * (1024 * 1024)

const MAX_FILE_SIZE_MB = 100
const MAX_FILE_SIZE = MAX_FILE_SIZE_MB * (1024 * 1024)

const Upload: FC<UploadProps> = (props) => {
  const { onUploadFileAdd, nedUploadBottom = true, onRemoveFile, files, description, itemRender, titleItems, tooltipDescription, withoutVideo } = props

  const dispatch = useDispatch()

  const onChange = ({ fileList }: { fileList: UploadFile[] }) => {
    const regexVideoFormat = new RegExp(`(${getAcceptFormats('video').replaceAll(',', '|')})$`, 'i')
    const regexImageFormat = new RegExp(`(${getAcceptFormats('image').replaceAll(',', '|')})$`, 'i')
    const regexFileFormat = new RegExp(`(${getAcceptFormats('file').replaceAll(',', '|')})$`, 'i')
    let hasBigFile = false
    let hasIncorrectFileFormat = false

    const validatedFileList = fileList.slice(0, 15).reduce<UploadFile[]>((acc, file) => {
      // Check regexp that format is supporting
      const isVideo = regexVideoFormat.test(file.name)
      const isImage = regexImageFormat.test(file.name)
      const isOtherFile = regexFileFormat.test(file.name)
      // const isCorrectFileFormat = formatFiles !== 'file' ? isVideo || isImage || isOtherFile : isOtherFile
      const isCorrectFileFormat = isImage || isOtherFile

      // Check file size for it file format
      let isCorrectFileSize = false
      if (file.size) {
        isCorrectFileSize =
          (file.size > MAX_FILE_SIZE && (isImage || isOtherFile)) || (file.size > MAX_VIDEO_FILE_SIZE && isVideo)
      }

      if (!isCorrectFileFormat) {
        hasIncorrectFileFormat = true
      } else if (isCorrectFileSize) {
        hasBigFile = true
      } else {
        acc.push(file)
      }

      return acc
    }, [])

    if (fileList.length > 15) {
      dispatch(
        alertActions.pushAlert({
          message: 'Максимальное число файлов не должно быть больше 15',
          severity: 'warning',
        }),
      )
    }

    if (hasBigFile) {
      dispatch(
        alertActions.pushAlert({
          message: withoutVideo
            ? `Максимально допустимый размер файла ${MAX_FILE_SIZE_MB} Мбайт`
            : `Максимально допустимый размер файла для видео ${MAX_VIDEO_FILE_SIZE_MB} Мбайт, для прочих ${MAX_FILE_SIZE_MB} Мбайт`,
          severity: 'warning',
        }),
      )
    }

    if (hasIncorrectFileFormat) {
      dispatch(
        alertActions.pushAlert({
          message: 'Недопустимый формат файла',
          severity: 'warning',
        }),
      )
    }

    onUploadFileAdd(validatedFileList)
  }

  return (
    <AntUpload.Dragger
      accept={getAcceptFormats('all')}
      name="files"
      listType="picture"
      fileList={files}
      onChange={onChange}
      multiple={true}
      className={'drag-and-drop'}
      // TODO странный callback ? зачем он нужен?
      // странность заключается в API ant'а, которое предлагает нам передавать в этот пропс колбэк,
      // возвращающий false, чтобы отменить POST запрос из компонента, т.к. мы постим файлы сами и позже
      // и POST из компонента нам не нужен.
      beforeUpload={() => false}
      itemRender={
        itemRender ?
        itemRender :
        (originNode, file) => (
          <FilePreview
            key={file.uid}
            name={file.name}
            thumbScr={file.thumbUrl}
            type={file.type}
            url={
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              file.url || URL.createObjectURL(file.originFileObj ?? '')
            }
            onRemove={() => onRemoveFile(file.uid)}
            status={1}
            unclickable
          />
        )
      }
    >
      {description ?
        description :
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={'Перетащите файлы сюда'} />
      }
      {nedUploadBottom && 
        <div className="upload-bottom">
          <Text className="upload-bottom-text">+ Добавить файлы</Text>
          {tooltipDescription && <TooltipHelper description={tooltipDescription} />}
        </div>
      }
      {titleItems && files.length > 0 && 
        <div className="upload-title-items">
          {titleItems}
        </div>
      }
    </AntUpload.Dragger>
  )
}

export default Upload
