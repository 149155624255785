import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import Ru from './locales/Ru/translation.json'
import Eng from './locales/Eng/translation.json'

const currentLanguage = localStorage.getItem('locale') ?? 'Ru'

i18next.use(initReactI18next).init({
  fallbackLng: currentLanguage,
  resources: {
    Ru: { translation: Ru },
    Eng: { translation: Eng },
  },
  react: {
    useSuspense: false,
  },
})

export default i18next
