import React, { FC } from 'react'
import { Text } from '../../../common/text'
import RadioGroup from '../../../common/radio-group/radio-group'
import styles from './registration-tab.module.scss'
import './registration-tab.ant.scss'

interface RegistrationTabProps {
  text: string
  type: 'conference' | 'exhibition' | 'staff'
  checked: string
}

const RegistrationTab: FC<RegistrationTabProps> = ({ text, type, checked }) => {
  return (
    <div className={styles.tabWrap}>
      <RadioGroup.Radio checked={type === checked} />
      <Text className={styles.text}>{text}</Text>
    </div>
  )
}

export default RegistrationTab
