import React, { FC } from 'react'
import { Table, TableColumnsType } from 'antd'
import { DataType, DataTypeArr } from '../types/statisticType'

interface PopoverPropsContent {
  columns: TableColumnsType<DataType> | undefined
  data: DataTypeArr | undefined
}

const StatisticPopoverContent: FC<PopoverPropsContent> = (props) => {
  const { columns, data } = props

  return <Table dataSource={data} columns={columns} pagination={false} scroll={{ y: 240 }} />
}

export default StatisticPopoverContent
