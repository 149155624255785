import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type headerSurveyStateType = {
  editable: boolean
  title: string
  backCount: number
}

const initialState: headerSurveyStateType = {
  title: '',
  editable: false,
  backCount: 0,
}

const headerSurveySlice = createSlice({
  name: 'headerSurveySlice',
  initialState,
  reducers: {
    editableHead: (state, { payload }: PayloadAction<boolean>) => {
      state.editable = payload
    },
    headerEditText: (state, { payload }: PayloadAction<string>) => {
      state.title = payload
    },
    addBack: (state) => {
      state.backCount += 1
    },
    minusBack: (state) => {
      state.backCount -= 1
    },
  },
})

export default headerSurveySlice.reducer
export const headerSurveyActions = headerSurveySlice.actions
