import React from 'react'

const Sections: React.FC<DefaultPropsIcon> = (props) => {
  const { size = 24, onClick, className, color = '#818C99' } = props
  return (
    <span className={['anticon', 'anticon-setting', className || ''].join(' ')} onClick={onClick}>
      <svg width={size} height={size} viewBox={`0 0 24 24`} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M16 8V6C16 4.89 15.11 4 14 4H10C8.89 4 8 4.89 8 6V8H6C4.89543 8 4 8.89543 4 10V17C4 18.1046 4.89543 19 6 19H18C19.1046 19 20 18.1046 20 17V10C20 8.89543 19.1046 8 18 8H16ZM10 8V6H14V8H10Z"
          fill={color}
        />
      </svg>
    </span>
  )
}

export default Sections
