import { useSelector } from 'react-redux'
import { RootState } from '../store/store'
import { useTranslation } from 'react-i18next'

type ListType = "viewing" | "visiting"

type OneListType = {
  [N: number]: string,
}

type OneListArrType = {
  id: number,
  name: string
}

const useGetSections = (listType: ListType = "viewing", withoutSection?: boolean) => {
  const { sections } = useSelector((state: RootState) => state.sectionsConference)

  const { i18n } = useTranslation()

  const Eng = i18n.resolvedLanguage === "Eng"

  const oneList: OneListType = {}
  const oneListArr: OneListArrType[] = []

  if(withoutSection) {
    oneList[0] = Eng ? "Plenary reports (without a section)" : "Пленарные доклады (без секции)"
    oneListArr.push({id: 0, name: Eng ? "Plenary reports (without a section)" : "Пленарные доклады (без секции)"})
  }

  const sectionList = sections.map(itemOne => {
    const allChild: SectionListItemType[] = []

    oneList[itemOne.id] = itemOne.name
    oneListArr.push({id: itemOne.id, name: itemOne.name})

    if(itemOne.children) {

      itemOne.children.forEach(itemTwo => {

        oneList[itemTwo.id] = itemTwo.name

        if (listType === "viewing" || (listType === "visiting" && itemTwo.children.length === 0)) {
          oneListArr.push({id: itemTwo.id, name: itemTwo.name})

          allChild.push({
            id: itemTwo.id,
            name: itemTwo.name,
            admin: itemTwo.admin,
            chairman: itemTwo.chairman,
            phone: itemTwo.phone,
          })
        }

        if(itemTwo.children) {
          itemTwo.children.forEach(itemThree => {

            oneList[itemThree.id] = itemThree.name
            oneListArr.push({id: itemThree.id, name: itemThree.name})

            allChild.push({
              id: itemThree.id,
              name: itemThree.name,
              admin: itemThree.admin,
              chairman: itemThree.chairman,
              phone: itemThree.phone,
            })
          })
        }
      })
    }

    const newItem = {
      id: itemOne.id,
      name: itemOne.name,
      admin: itemOne.admin,
      chairman: itemOne.chairman,
      phone: itemOne.phone,
      children: allChild,
    }
    return newItem
  })

  if(withoutSection) {
    sectionList.push({
      id: 0,
      name: Eng ? "Plenary reports (without a section)" : "Пленарные доклады (без секции)",
      admin: "",
      chairman: "",
      phone: undefined,
      children: [],
    })
  }

  return {
    sectionList,
    oneList,
    oneListArr,
  }
}

export default useGetSections

export type SectionListItemType = {
  id: number
  name: string
  admin: string
  chairman: string
  phone?: string | null
  children?: SectionListItemType[]
}