import React from 'react'
import { Typography } from 'antd'

import styles from './profile-card.module.scss'
import { Text, Title } from '../text'
import { AvatarIcon } from '../icons'
import { UserPublic } from '../../../api/model/userPublic'

type ProfileCardProps = {
  user?: UserPublic
  onClickCompetence?: () => void
}

const ProfileCard: React.FC<ProfileCardProps> = (props) => {
  const { user } = props

  return (
    <div className={styles.wrapper}>
      <div className={styles.avatarContainer}>
        <div className={styles.avatar} style={{ backgroundImage: `url(${user?.avatar})` }}>
          {!user?.avatar && <AvatarIcon color='#A4B3C6' size={200} />}
        </div>
      </div>

      <div className={styles.fullNameContainer}>
        <Typography.Text className={styles.fullName}>{user?.fullName}</Typography.Text>
      </div>

      {/* {user?.competence?.id && (
        <div onClick={onClickCompetence}>
          <RouterLink to={`/competencies/${user?.competence?.id}`}>
            <Space>
              <div className={styles.pictogramContainer}>
                <img className={styles.pictogram} src={user?.competence?.pictogram} alt={'competence-logo'} />
              </div>
              <Title level={5}>{user?.competence?.name}</Title>
            </Space>
          </RouterLink>
        </div>
      )} */}

      {/* {user?.role && (
        <div className={styles.lineContainer}>
          <Title level={5}>Роль</Title>
          <Text size="xl">{user?.role}</Text>
        </div>
      )} */}

      {user?.company && (
        <div className={styles.lineContainer}>
          <Title level={5}>Место работы</Title>
          <Text size="xl">{user?.company}</Text>
        </div>
      )}

      {user?.position && (
        <div className={styles.lineContainer}>
          <Title level={5}>Должность</Title>
          <Text size="xl">{user?.position}</Text>
        </div>
      )}
    </div>
  )
}

export default ProfileCard
