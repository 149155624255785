import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import appAPI from '../../api/api'
import { RegistrationApplication, RoleEnum, SectionStatistic, UserWS } from '../../api/model/models'

type GetPayloadType = {
  approveStatus?: string, 
  applicationStatus?: string, 
  role?: RoleEnum,
  section?: number,
  lecture_type?: string,
  company?: number,
  status?: string,
  companiesType?: "foreign" | "russian"
  citizenship?: number 
  reportUploaded?: boolean
} | undefined

type PutPayloadType = {
  id: string,
  approveStatus?: string,
  status?: string,
  comment?: string
  refreshOptions?: GetPayloadType
}

type ApplicationsState = {
  applicationsMNTK: RegistrationApplication[]
  applicationsMNTKLoading: boolean
  applications: UserWS[]
  applicationsLoading: boolean
  updateMNTKApplicationsError: boolean
  selectedUsers: React.Key[]
  downloadLoading: boolean
  downloadLink: string
  statistics: SectionStatistic[]
  statisticsLoading: boolean
}

const initialSectionsState: ApplicationsState = {
  applicationsMNTK: [],
  applicationsMNTKLoading: false,
  applications: [],
  applicationsLoading: false,
  updateMNTKApplicationsError: false,
  selectedUsers: [],
  downloadLoading: false,
  downloadLink: '',
  statistics: [],
  statisticsLoading: false,
}

export const fetchStatistics = createAsyncThunk(
  'fetchStatistics', 
  async (payload, { rejectWithValue }) => {
    try {
      return await appAPI.applications.getStatistics()
    } catch (err: any) {
      return rejectWithValue({ messages: err.response.data, status: err.response.status })
    }
  },
)

export const downloadStatistics = createAsyncThunk(
  'downloadStatistics', 
  async (payload, { rejectWithValue }) => {
    try {
      const response = await appAPI.applications.downloadStatistics()
      const blob = new Blob(
        [response.data],
        { type: 'application/vnd.ms-excel' },
      )
      const url = window.URL.createObjectURL(blob)
      window.open(url)
    } catch (err: any) {
      return rejectWithValue({ messages: err.response.data, status: err.response.status })
    }
  },
)

export const userApplicationsCreatedExport = createAsyncThunk(
  'userApplicationsCreatedExport',
  async (payload: GetPayloadType & {ids?: React.Key[]}, { rejectWithValue }) => {
    try {
      const response = await appAPI.applications.userApplicationsCreatedExport(payload)
      const blob = new Blob(
          [response.data],
          { type: 'application/vnd.ms-excel' },
      )
      const url = window.URL.createObjectURL(blob)
      window.open(url)
    } catch (err: any) {
      return rejectWithValue({ messages: err.response.data, status: err.response.status })
    }
  },
)

export const userApplicationsExport = createAsyncThunk(
  'userApplicationsExport',
  async (payload: GetPayloadType & {ids?: React.Key[]}, { rejectWithValue }) => {
    try {
      const response = await appAPI.applications.userApplicationsExport(payload)
      const blob = new Blob(
          [response.data],
          { type: 'application/vnd.ms-excel' },
      )
      const url = window.URL.createObjectURL(blob)
      window.open(url)
    } catch (err: any) {
      return rejectWithValue({ messages: err.response.data, status: err.response.status })
    }
  },
)

export const conferenceUserExport = createAsyncThunk(
  'conferenceUserExport',
  async (payload: GetPayloadType & {ids?: React.Key[]}, { rejectWithValue }) => {
    try {
      const response = await appAPI.applications.conferenceUserExport(payload)
      const blob = new Blob(
          [response.data],
          { type: 'application/vnd.ms-excel' },
      )
      const url = window.URL.createObjectURL(blob)
      window.open(url)
    } catch (err: any) {
      return rejectWithValue({ messages: err.response.data, status: err.response.status })
    }
  },
)

export const exhibitionUserExport = createAsyncThunk(
  'exhibitionUserExport',
  async (payload: GetPayloadType & {ids?: React.Key[]}, { rejectWithValue }) => {
    try {
      const response = await appAPI.applications.exhibitionUserExport(payload)
      const blob = new Blob(
          [response.data],
          { type: 'application/vnd.ms-excel' },
      )
      const url = window.URL.createObjectURL(blob)
      window.open(url)
    } catch (err: any) {
      return rejectWithValue({ messages: err.response.data, status: err.response.status })
    }
  },
)

export const downloadApplicationsDocuments = createAsyncThunk(
  'downloadApplicationsDocuments', 
  async (payload: {ids?: React.Key[], type?: string}, { rejectWithValue }) => {
    try {
      const response = await appAPI.applications.documentsZip({ids: payload.ids, type: payload.type})
      const blob = new Blob(
          [response.data],
          { type: 'application/zip' },
      )
      const url = window.URL.createObjectURL(blob)
      window.open(url)
    } catch (err: any) {
      return rejectWithValue({ messages: err.response.data, status: err.response.status })
    }
  },
)

export const fetchMNTKApplications = createAsyncThunk('fetchMNTKApplications', async (payload: GetPayloadType, { rejectWithValue }) => {
  try {
    return await appAPI.applications.getMNTKApplications(payload)
  } catch (err: any) {
    return rejectWithValue({ messages: err.response.data, status: err.response.status })
  }
})

export const fetchUpdateMNTKApplications = createAsyncThunk(
  'fetchUpdateMNTKApplications', 
  async (payload: PutPayloadType, { rejectWithValue, dispatch }) => {
    try {
      await appAPI.applications.updateMNTKApplication({
        id: payload.id,
        approveStatus: payload.approveStatus,
        status: payload.status, 
        comment: payload.comment,
      })

      dispatch(fetchMNTKApplications(payload.refreshOptions))

    } catch (err: any) {
      return rejectWithValue({ messages: err.response.data, status: err.response.status })
    }
  },
)

export const fetchAdminApplications = createAsyncThunk('fetchAdminApplications', async (payload: GetPayloadType, { rejectWithValue }) => {
  try {
    if(!payload?.role && payload?.section === 0) {
      payload = {...payload, role: "conference"}
    }

    return await appAPI.applications.getAdminsApplications(payload)
  } catch (err: any) {
    return rejectWithValue({ messages: err.response.data, status: err.response.status })
  }
})

export const fetchUpdateAdminApplications = createAsyncThunk(
  'fetchUpdateAdminApplications', 
  async (payload: PutPayloadType, { rejectWithValue, dispatch }) => {
    try {
      await appAPI.applications.updateAdminsApplication({
        id: payload.id,
        approveStatus: payload.approveStatus,
        status: payload.status, 
        comment: payload.comment,
      })

      dispatch(fetchAdminApplications(payload.refreshOptions))

    } catch (err: any) {
      return rejectWithValue({ messages: err.response.data, status: err.response.status })
    }
  },
)

const applicationsSlice = createSlice({
  name: 'applications',
  initialState: initialSectionsState,
  reducers: {
    setDownloadLink: (state, { payload }: PayloadAction<string>) => {
      state.downloadLink = payload
    },
    setSelectedUsers: (state: ApplicationsState, { payload }: PayloadAction<React.Key[]>) => {
      state.selectedUsers = payload
    },
    setUpdateMNTKApplicationsError: (state, { payload }: PayloadAction<boolean>) => {
      state.updateMNTKApplicationsError = payload
    },
  },
  extraReducers: (builder) =>  {
    builder
      .addCase(fetchMNTKApplications.pending, (state) => {
        state.applicationsMNTKLoading = true
      })
      .addCase(fetchMNTKApplications.fulfilled, (state, { payload }) => {
        state.applicationsMNTK = payload.data
        state.applicationsMNTKLoading = false
      })
      .addCase(fetchMNTKApplications.rejected, (state) => {
        state.applicationsMNTKLoading = false
      })
      .addCase(fetchAdminApplications.pending, (state) => {
        state.applicationsLoading = true
      })
      .addCase(fetchAdminApplications.fulfilled, (state, { payload }) => {
        state.applications = payload.data
        state.applicationsLoading = false
      })
      .addCase(fetchAdminApplications.rejected, (state) => {
        state.applicationsLoading = false
      })
      .addCase(downloadApplicationsDocuments.pending, (state) => {
        state.downloadLoading = true
        state.downloadLink = ''
      })
      .addCase(downloadApplicationsDocuments.fulfilled, (state, { payload }) => {
        state.downloadLoading = false
        // state.downloadLink = payload?.request?.responseURL
      })
      .addCase(downloadApplicationsDocuments.rejected, (state) => {
        state.downloadLoading = false
        state.downloadLink = ''
      })
      .addCase(userApplicationsExport.pending, (state) => {
        state.downloadLoading = true
        state.downloadLink = ''
      })
      .addCase(userApplicationsExport.fulfilled, (state) => {
        state.downloadLoading = false
        // state.downloadLink = payload?.request?.responseURL
      })
      .addCase(userApplicationsExport.rejected, (state) => {
        state.downloadLoading = false
        state.downloadLink = ''
      })
      .addCase(userApplicationsCreatedExport.pending, (state) => {
        state.downloadLoading = true
        state.downloadLink = ''
      })
      .addCase(userApplicationsCreatedExport.fulfilled, (state) => {
        state.downloadLoading = false
        // state.downloadLink = payload?.request?.responseURL
      })
      .addCase(userApplicationsCreatedExport.rejected, (state) => {
        state.downloadLoading = false
        state.downloadLink = ''
      })
      .addCase(conferenceUserExport.pending, (state) => {
        state.downloadLoading = true
        state.downloadLink = ''
      })
      .addCase(conferenceUserExport.fulfilled, (state) => {
        state.downloadLoading = false
        // state.downloadLink = payload?.request?.responseURL
      })
      .addCase(conferenceUserExport.rejected, (state) => {
        state.downloadLoading = false
        state.downloadLink = ''
      })
      .addCase(exhibitionUserExport.pending, (state) => {
        state.downloadLoading = true
        state.downloadLink = ''
      })
      .addCase(exhibitionUserExport.fulfilled, (state) => {
        state.downloadLoading = false
        // state.downloadLink = payload?.request?.responseURL
      })
      .addCase(exhibitionUserExport.rejected, (state) => {
        state.downloadLoading = false
        state.downloadLink = ''
      })
      .addCase(fetchStatistics.pending, (state) => {
        state.statisticsLoading = true
      })
      .addCase(fetchStatistics.fulfilled, (state, { payload }) => {
        state.statisticsLoading = false
        state.statistics = payload.data
      })
      .addCase(fetchStatistics.rejected, (state) => {
        state.statisticsLoading = false
      })
      .addCase(downloadStatistics.pending, (state) => {
        state.downloadLoading = true
        state.downloadLink = ''
      })
      .addCase(downloadStatistics.fulfilled, (state, { payload }) => {
        state.downloadLoading = false
        // state.downloadLink = payload?.request?.responseURL
      })
      .addCase(downloadStatistics.rejected, (state) => {
        state.downloadLoading = false
        state.downloadLink = ''
      })
  },
})

export const applicationsActions = {
  ...applicationsSlice.actions,
  fetchStatistics,
  downloadStatistics,
  userApplicationsCreatedExport,
  userApplicationsExport,
  conferenceUserExport,
  exhibitionUserExport,
  downloadApplicationsDocuments,
  fetchMNTKApplications,
  fetchUpdateMNTKApplications,
  fetchAdminApplications,
  fetchUpdateAdminApplications,
}

export default applicationsSlice.reducer