import React, { FC } from 'react'

const LogoFull: FC = () => {
  return (
    <svg width="141" height="42" viewBox="0 0 141 42" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M24.2716 23.9473C22.6415 25.7552 19.8554 25.9034 18.0327 24.2733C16.2247 22.6431 16.0765 19.8571 17.7066 18.0343C19.3368 16.2263 22.1376 16.0781 23.9456 17.7231C25.7684 19.3532 25.9018 22.1393 24.2716 23.9473Z" fill="url(#paint0_radial_13114_48954)"/>
    <path d="M8.37268 4.2241C5.48289 6.40255 3.18587 9.30716 1.71875 12.6712C3.46744 13.1009 8.68388 14.8052 11.0846 21.2813C11.8108 21.0442 13.3668 20.1402 14.4338 16.6428C15.8861 11.9598 18.6277 7.45473 22.1844 5.94315C18.6425 2.41613 13.9596 1.58624 8.37268 4.2241Z" fill="url(#paint1_linear_13114_48954)"/>
    <path d="M37.1801 7.60237C33.327 2.94907 27.5178 0 20.9972 0C16.255 0 11.8833 1.57086 8.37109 4.22354C16.1513 0.577958 22.1383 3.5863 25.7987 11.1887C31.6376 12.226 35.6092 9.32142 37.1801 7.60237Z" fill="url(#paint2_linear_13114_48954)"/>
    <path d="M12.7871 40.3239C16.1214 41.7317 19.7818 42.28 23.4274 41.8651C22.9235 40.1312 21.7825 34.7666 26.1986 29.4464C25.6355 28.9425 24.0795 28.0534 20.508 28.8536C15.7361 29.9354 10.4604 29.8169 7.36315 27.4902C6.08868 32.3362 7.71882 36.7968 12.7871 40.3239Z" fill="url(#paint3_linear_13114_48954)"/>
    <path d="M1.31821 13.6777C-0.786145 19.3388 -0.430478 25.8593 2.81498 31.4907C5.18609 35.5957 8.74275 38.604 12.7885 40.3231C5.74922 35.4178 5.3491 28.7195 10.1061 21.7395C8.0907 16.1526 3.58559 14.1816 1.31821 13.6777Z" fill="url(#paint4_linear_13114_48954)"/>
    <path d="M41.8496 18.45C41.405 14.8637 40.0416 11.4108 37.8632 8.47656C36.6183 9.76585 32.5282 13.4559 25.7261 12.2852C25.563 13.0261 25.5779 14.8193 28.0675 17.5164C31.4019 21.1175 33.9212 25.7412 33.4618 29.5794C38.2633 28.2457 41.3161 24.6001 41.8496 18.45Z" fill="url(#paint5_linear_13114_48954)"/>
    <path d="M24.5088 41.702C30.4662 40.6943 35.9346 37.1377 39.18 31.4915C41.5511 27.3865 42.381 22.8073 41.8327 18.4355C41.1066 26.9864 35.5048 30.6764 27.0874 30.054C23.264 34.5887 23.7975 39.494 24.5088 41.702Z" fill="url(#paint6_linear_13114_48954)"/>
    <path d="M48.829 4.40039H48.4141V37.5811H48.829V4.40039Z" fill="#414042"/>
    <path d="M56.0768 19.1028H55.2617V12.7305H57.1438C58.5961 12.7305 59.5001 13.3381 59.5001 14.7163C59.5001 16.0352 58.5961 16.7614 57.0697 16.7614H56.0916V19.1028H56.0768ZM56.0768 15.9759H57.0549C58.0626 15.9759 58.6109 15.561 58.6109 14.7163C58.6109 13.8419 58.0626 13.4714 57.0549 13.4714H56.0768V15.9759Z" fill="#414042"/>
    <path d="M61.3086 15.9177C61.3086 13.8726 62.4793 12.6426 64.1687 12.6426C66.0656 12.6426 67.0882 13.9911 67.0882 15.9177C67.0882 18.0813 65.873 19.1928 64.1984 19.1928C62.4201 19.1928 61.3086 17.9331 61.3086 15.9177ZM66.199 15.9177C66.199 14.169 65.4136 13.3984 64.1539 13.3984C63.0276 13.3984 62.1681 14.2134 62.1681 15.9177C62.1681 17.5923 63.0128 18.437 64.2577 18.437C65.4728 18.4222 66.199 17.5626 66.199 15.9177Z" fill="#414042"/>
    <path d="M73.6542 18.9252C73.3134 19.0882 72.8095 19.192 72.2612 19.192C70.2754 19.192 69.0898 17.9471 69.0898 15.991C69.0898 13.8273 70.305 12.627 72.2167 12.627C72.9429 12.627 73.3727 12.7603 73.669 12.8789L73.5208 13.605C73.1207 13.4568 72.7058 13.3976 72.3057 13.3976C70.7941 13.3976 69.979 14.2719 69.979 15.9169C69.979 17.5025 70.8978 18.4362 72.3946 18.4362C72.7947 18.4362 73.2245 18.3621 73.5357 18.2139L73.6542 18.9252Z" fill="#414042"/>
    <path d="M76.3959 15.413H79.0634C79.0042 14.0348 78.115 13.3976 76.8553 13.3976C76.4848 13.3976 75.9513 13.442 75.4919 13.6199L75.3438 12.953C75.655 12.79 76.3218 12.627 76.9739 12.627C78.7967 12.627 79.9526 13.605 79.9526 15.828C79.9526 17.9175 78.8856 19.192 76.7368 19.192C76.0699 19.192 75.655 19.0734 75.3586 18.9697L75.5068 18.2435C75.8921 18.3769 76.3959 18.4214 76.7368 18.4214C78.1298 18.4214 79.0338 17.6952 79.0634 16.1688H76.3811V15.413H76.3959Z" fill="#414042"/>
    <path d="M87.096 19.1028H86.2809V16.1686H83.0651V19.1028H82.25V12.7305H83.0651V15.4276H86.2809V12.7305H87.096V19.1028Z" fill="#414042"/>
    <path d="M93.2012 19.1028H89.6445V12.7305H93.2012V13.5011H90.4596V15.4276H92.9641V16.1686H90.4596V18.3322H93.2012V19.1028Z" fill="#414042"/>
    <path d="M96.3268 19.1028H95.5117V12.7305H97.3938C98.8461 12.7305 99.7501 13.3381 99.7501 14.7163C99.7501 16.0352 98.8461 16.7614 97.3197 16.7614H96.3416V19.1028H96.3268ZM96.3268 15.9759H97.3049C98.3126 15.9759 98.8609 15.561 98.8609 14.7163C98.8609 13.8419 98.3126 13.4714 97.3049 13.4714H96.3268V15.9759Z" fill="#414042"/>
    <path d="M102.655 19.1028H101.84V12.7305H105.219V13.5011H102.655V19.1028Z" fill="#414042"/>
    <path d="M106.539 15.9177C106.539 13.8726 107.71 12.6426 109.399 12.6426C111.296 12.6426 112.319 13.9911 112.319 15.9177C112.319 18.0813 111.103 19.1928 109.429 19.1928C107.651 19.1928 106.539 17.9331 106.539 15.9177ZM111.429 15.9177C111.429 14.169 110.644 13.3984 109.384 13.3984C108.258 13.3984 107.399 14.2134 107.399 15.9177C107.399 17.5923 108.243 18.437 109.488 18.437C110.703 18.4222 111.429 17.5626 111.429 15.9177Z" fill="#414042"/>
    <path d="M117.933 17.3986H115.206L114.584 19.1028H113.754L116.184 12.7305H116.999L119.385 19.1028H118.526L117.933 17.3986ZM115.473 16.6428H117.666L116.599 13.6493H116.57L115.473 16.6428Z" fill="#414042"/>
    <path d="M122.822 19.1028H122.007V13.5011H120.125V12.7305H124.719V13.5011H122.837V19.1028H122.822Z" fill="#414042"/>
    <path d="M126.172 15.9177C126.172 13.8726 127.343 12.6426 129.032 12.6426C130.929 12.6426 131.951 13.9911 131.951 15.9177C131.951 18.0813 130.736 19.1928 129.062 19.1928C127.283 19.1928 126.172 17.9331 126.172 15.9177ZM131.062 15.9177C131.062 14.169 130.277 13.3984 129.017 13.3984C127.891 13.3984 127.031 14.2134 127.031 15.9177C127.031 17.5923 127.876 18.437 129.121 18.437C130.321 18.4222 131.062 17.5626 131.062 15.9177Z" fill="#414042"/>
    <path d="M140.428 19.1028H139.613L139.228 14.1235L137.613 19.1028H136.753L135.153 14.1383L134.753 19.1028H133.938L134.486 12.7305H135.494L137.213 18.2137L138.902 12.7305H139.91L140.428 19.1028Z" fill="#414042"/>
    <path d="M55.9138 28.883H55.2617V23.7852H56.7585C57.9144 23.7852 58.6406 24.2594 58.6406 25.3708C58.6406 26.423 57.9144 27.001 56.6992 27.001H55.9138V28.883ZM55.9138 26.3934H56.6992C57.5143 26.3934 57.944 26.0674 57.944 25.3857C57.944 24.6891 57.4995 24.3928 56.6992 24.3928H55.9138V26.3934Z" fill="#035FA2"/>
    <path d="M60.0352 26.334C60.0352 24.7038 60.9688 23.7109 62.3174 23.7109C63.8289 23.7109 64.644 24.7928 64.644 26.334C64.644 28.0679 63.6659 28.957 62.3322 28.957C60.9243 28.957 60.0352 27.9493 60.0352 26.334ZM63.9475 26.334C63.9475 24.941 63.3251 24.3185 62.3174 24.3185C61.4282 24.3185 60.7317 24.9706 60.7317 26.334C60.7317 27.6677 61.3985 28.3494 62.4063 28.3494C63.3547 28.3346 63.9475 27.6529 63.9475 26.334Z" fill="#035FA2"/>
    <path d="M69.8284 28.7339C69.5468 28.8673 69.1615 28.9414 68.7169 28.9414C67.1312 28.9414 66.168 27.9485 66.168 26.3776C66.168 24.6438 67.146 23.6953 68.6724 23.6953C69.2504 23.6953 69.5913 23.7991 69.8432 23.888L69.7246 24.4659C69.3986 24.3474 69.0726 24.3029 68.7465 24.3029C67.5314 24.3029 66.8793 24.9994 66.8793 26.3184C66.8793 27.5928 67.6203 28.3338 68.8058 28.3338C69.1319 28.3338 69.4727 28.2745 69.7246 28.156L69.8284 28.7339Z" fill="#035FA2"/>
    <path d="M74.4242 27.5197H72.2458L71.7567 28.883H71.0898L73.0312 23.7852H73.6832L75.5949 28.883H74.9133L74.4242 27.5197ZM72.4532 26.9121H74.2167L73.3572 24.5113H73.3276L72.4532 26.9121Z" fill="#035FA2"/>
    <path d="M78.2613 28.883H77.6092V24.3928H76.0977V23.7852H79.7729V24.3928H78.2613V28.883Z" fill="#035FA2"/>
    <path d="M80.8711 26.334C80.8711 24.7038 81.8047 23.7109 83.1533 23.7109C84.6649 23.7109 85.4799 24.7928 85.4799 26.334C85.4799 28.0679 84.5019 28.957 83.1681 28.957C81.7603 28.957 80.8711 27.9493 80.8711 26.334ZM84.7982 26.334C84.7982 24.941 84.1758 24.3185 83.1681 24.3185C82.2789 24.3185 81.5824 24.9706 81.5824 26.334C81.5824 27.6677 82.2493 28.3494 83.257 28.3494C84.2055 28.3346 84.7982 27.6529 84.7982 26.334Z" fill="#035FA2"/>
    <path d="M92.2211 28.883H91.5691L91.2579 24.8966L89.9686 28.883H89.2869L87.9976 24.9114L87.6716 28.883H87.0195L87.4641 23.7852H88.2644L89.6426 28.1717L90.9911 23.7852H91.7914L92.2211 28.883Z" fill="#035FA2"/>
    <defs>
    <radialGradient id="paint0_radial_13114_48954" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(19.576 18.5303) scale(6.21032 6.21032)">
    <stop stopColor="#4495D1"/>
    <stop offset="1" stopColor="#15256D"/>
    </radialGradient>
    <linearGradient id="paint1_linear_13114_48954" x1="6.10662" y1="18.0303" x2="16.5499" y2="1.94905" gradientUnits="userSpaceOnUse">
    <stop stopColor="#4495D1"/>
    <stop offset="1" stopColor="#15256D"/>
    </linearGradient>
    <linearGradient id="paint2_linear_13114_48954" x1="10.2735" y1="-0.503638" x2="36.216" y2="9.97783" gradientUnits="userSpaceOnUse">
    <stop stopColor="#4495D1"/>
    <stop offset="1" stopColor="#15256D"/>
    </linearGradient>
    <linearGradient id="paint3_linear_13114_48954" x1="25.8756" y1="35.3849" x2="6.72879" y2="34.3817" gradientUnits="userSpaceOnUse">
    <stop stopColor="#4495D1"/>
    <stop offset="1" stopColor="#15256D"/>
    </linearGradient>
    <linearGradient id="paint4_linear_13114_48954" x1="7.74428" y1="41.0412" x2="3.84967" y2="13.3322" gradientUnits="userSpaceOnUse">
    <stop stopColor="#4495D1"/>
    <stop offset="1" stopColor="#15256D"/>
    </linearGradient>
    <linearGradient id="paint5_linear_13114_48954" x1="31.0215" y1="9.57591" x2="39.7262" y2="26.6604" gradientUnits="userSpaceOnUse">
    <stop stopColor="#4495D1"/>
    <stop offset="1" stopColor="#15256D"/>
    </linearGradient>
    <linearGradient id="paint6_linear_13114_48954" x1="44.985" y1="22.4526" x2="22.9359" y2="39.6788" gradientUnits="userSpaceOnUse">
    <stop stopColor="#4495D1"/>
    <stop offset="1" stopColor="#15256D"/>
    </linearGradient>
    </defs>
    </svg>
  )
}

export default LogoFull
