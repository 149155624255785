import { combineReducers } from 'redux'
import mainPageSliderSlice from './slices/mainPageSliderSlice'
import accountSlice from './slices/accountSlice'
import alertSlice from './slices/alertSlice'
import competenciesSlice from './slices/competenciesSlice'
import competenciesDetailSlice from './slices/competenceDetailSlice'
import competenceDocsSlice from './slices/competenceDocsSlice'
import competenceExpertsSlice from './slices/competenceExpertsSlice'
import newsSlice from './slices/newsSlice'
import newsDetailSlice from './slices/newsDetailSlice'
import gallerySlice from './slices/gallerySlice'
import albumSlice from './slices/albumSlice'
import eventsSlice from './slices/eventsSlice'
import eventDetailSlice from './slices/eventDetailSlice'
import contactsSlice from './slices/contactsSlice'
import feedbackSlice from './slices/feedbackSlice'
import captchaSlice from './slices/captchaSlice'
import staticPageSlice from './slices/staticPageSlice'
import topicCRUDSlice from './slices/topicCRUDSlice'
import topicsSlice from './slices/topicsSlice'
import forumTopicSlice from './slices/topicSlice'
import forumSearchSlice from './slices/forumSearchSlice'
import forumCommentsSlice from './slices/commentSlice'
import forumAttachmentsSlice from './slices/attachmentsSlice'
import championshipsSlice from './slices/championshipsSlice'
import globalSearchSlice from './slices/globalSearchSlice'
import userProfileSlice from './slices/userProfileSlice'
import championshipDocsSlice from './slices/championshipDocsSlice'
import participantsSlice from './slices/participantsSlice'
import FAQSlice from './slices/FAQSlice'
import modalProfileCardSlice from './slices/modalProfileCardSlice'
import companiesSlice from './slices/companiesSlice'
import forumGroupsSlice from './slices/forumGroupsSlice'
import topicPollVotesSlice from './slices/topicPollVotesSlice'
import surveySlice from './slices/surveySlice'
import surveyHeaderSlice from './slices/surveyHeaderSlice'
import surveyAdminSlice from './slices/surveyAdminSlice'
import sectionSlice from './slices/sectionSlice'
import questionsAdminSlice from './slices/questionsAdminSlice'
import groupSlice from './slices/groupSlice'
import votingSlice from './slices/voting-slice'
import ResultSlice from './slices/resultSlice'
import sectionsConferenceSlice from './slices/sectionsConferenceSlice'
import applicationsSlice from './slices/applicationsSlice'
import manualMailingSlice from './slices/manualMailingSlice'

/**
 * Root reducer for project
 * @module letters/reducers
 */
const rootReducer = combineReducers({
  account: accountSlice,
  sectionsConference: sectionsConferenceSlice,
  applications: applicationsSlice,
  userProfile: userProfileSlice,
  alert: alertSlice,
  mainPageSlider: mainPageSliderSlice,
  competencies: competenciesSlice,
  competenceDetail: competenciesDetailSlice,
  competenceDocs: competenceDocsSlice,
  competenceExperts: competenceExpertsSlice,
  news: newsSlice,
  newsDetail: newsDetailSlice,
  gallery: gallerySlice,
  album: albumSlice,
  events: eventsSlice,
  eventDetail: eventDetailSlice,
  contacts: contactsSlice,
  feedback: feedbackSlice,
  captcha: captchaSlice,
  topicCRUD: topicCRUDSlice,
  topics: topicsSlice,
  forumTopic: forumTopicSlice,
  forumTopicPollVotes: topicPollVotesSlice,
  forumComments: forumCommentsSlice,
  forumAttachments: forumAttachmentsSlice,
  forumSearch: forumSearchSlice,
  staticPage: staticPageSlice,
  globalSearch: globalSearchSlice,
  championships: championshipsSlice,
  championshipDocs: championshipDocsSlice,
  participants: participantsSlice,
  FAQ: FAQSlice,
  modalProfileCard: modalProfileCardSlice,
  companies: companiesSlice,
  forumGroups: forumGroupsSlice,
  survey: surveySlice,
  surveyHeader: surveyHeaderSlice,
  surveyAdmin: surveyAdminSlice,
  section: sectionSlice,
  voting: votingSlice,
  questionsAdmin: questionsAdminSlice,
  groups: groupSlice,
  result: ResultSlice,
  manualMailing: manualMailingSlice,
})

export default rootReducer
