import React, { FC } from 'react'

type MyPropsIcon = DefaultPropsIcon & {
  width?: string | number,
  height?: string | number,
}

const Ok: FC<MyPropsIcon> = (props) => {
  const { width = 19, height = 14, onClick, className, color = '#3F8AE0' } = props
  return (
    <span className={['anticon', 'anticon-setting', className || ''].join(' ')} onClick={onClick}>
      <svg width={width} height={height} viewBox={`0 0 19 14`} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M16.8636 0.36361C17.215 0.012138 17.7849 0.0121383 18.1364 0.36361C18.4878 0.715082 18.4878 1.28493 18.1364 1.6364L6.63637 13.1364C6.28489 13.4879 5.71505 13.4879 5.36357 13.1364L0.363579 8.1364C0.0121075 7.78493 0.0121078 7.21508 0.36358 6.86361C0.715052 6.51214 1.2849 6.51214 1.63637 6.86361L5.99997 11.2272L16.8636 0.36361Z"
          fill={color}
        />
      </svg>
    </span>
  )
}

export default Ok
