import { FC, useEffect, useState } from 'react'
import { Form } from '../../../common'
import useChangeCompany from '../hooks/useChangeCompany'
import useHandleSubmit from '../hooks/useHandleSubmit'
import { useDispatch, useSelector } from 'react-redux'
import { Company } from '../../../../api/model/company'
import { RootState } from '../../../../store/store'
import { Button, Checkbox, Col, Row } from 'antd'
import React from 'react'
import styles from '../registration.module.scss'
import style from './staff.module.scss'
import { validateMessages } from '../../../../utils/constants'
import Field from '../field'
import { emailRuValidator, emailValidator, fullNameValidator, MIN_LEN_PASSWORD, passwordValidator, phoneValidator, responsibilitiesValidator } from '../../../../utils/ant-form-validators'
import FieldSelect from '../field-select'
import { staffRoles } from '../../../../constants/roles'
import { companiesType } from '../../../../constants/companiesType'
import { companiesActions } from '../../../../store/slices/companiesSlice'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Text } from '../../../common/text'
import { RuleObject } from 'antd/lib/form'
import { NamePath } from 'antd/lib/form/interface'
import { Attention } from '../../../common/icons'

interface Props<T> {
  selectTab: (tab: T) => void
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
}

interface GetFieldValue {
  getFieldValue: (name: NamePath) => string
}

const { Item, useForm } = Form

const Staff: FC<Props<TabHeader>> = ({ selectTab, setLoading }) => {
  const [form] = useForm()
  const dispatch = useDispatch()
  const { t, i18n } = useTranslation()

  const { handleSubmit, companyNotExistOption, needCaptcha } = useHandleSubmit({
    selectTab,
    setLoading,
  })
  const { onChangeCompany, needNewField, selectedCompany } = useChangeCompany({ companyNotExistOption, form })

  const { companies, concreteCompany} = useSelector((state: RootState) => state.companies)
  const companiesOptions: Company[] = [companyNotExistOption, ...companies]

  const [conditionsAreAccepted, setConditionsAreAccepted] = useState<boolean>(true)
  const [missingMiddleNameConfirm, setMissingMiddleNameConfirm] = useState<boolean>(true)
  const [middleNameNotEntered, setMiddleNameNotEntered] = useState<boolean>(true)
  const [foreignCompany, setForeignCompany] = useState<boolean>(false)

  useEffect(() => {
    form.resetFields(["email"])
  }, [form, foreignCompany])

  useEffect(() => {
    const foreign = concreteCompany.some(item => item.id === selectedCompany)
    setForeignCompany(foreign)
  }, [selectedCompany, concreteCompany])

  useEffect(() => {
    dispatch(companiesActions.fetchConcreteCompany('foreign'))
  }, [dispatch])

  const onChangeCompanyType = () => {
    const formFields = form.getFieldsValue()
    if(formFields.companiesType) dispatch(companiesActions.setCompaniesType(formFields.companiesType)) 
  }
  

  return (
    <Row justify="start">
      <Col className={styles.form}>
        <Form
          form={form}
          name="staff"
          layout="vertical"
          validateMessages={validateMessages}
          onFinish={handleSubmit}
          onChange={() => {
            const textEntered = form.getFieldValue('middleName')
            setMiddleNameNotEntered(!textEntered)
            if (textEntered) setMissingMiddleNameConfirm(true)
          }}
        >
          <FieldSelect
            label={t("typeOfOrganization")}
            name="companiesType"
            placeholder={t("typeOfOrganization")}
            data={companiesType}
            value="id"
            content="name"
            rules={[{ whitespace: true }]}
            showSearch
            onChange={onChangeCompanyType}
          />
          <FieldSelect
            label={`${t("organization")}*`}
            name="company"
            placeholder={t("organization")}
            data={companiesOptions}
            value="id"
            content="name"
            rules={[{ required: true, whitespace: true }]}
            showSearch
            onChange={onChangeCompany}
          />
          <Field
            label={`${t("lastName")}*`}
            name="lastName"
            rules={[{ required: true, whitespace: true, max: 30, validator: fullNameValidator }]}
            placeholder={t("lastName")}
          />
          <Field
            label={`${t("firstName")}*`}
            name="firstName"    
            rules={[{ required: true, whitespace: true, max: 30, validator: fullNameValidator }]}
            placeholder={t("firstName")}
          />
          <Field
            label={t("middleName")}
            name="middleName"
            rules={[{ whitespace: true, max: 30, validator: responsibilitiesValidator }]}
            placeholder={t("middleName")}
          />
          <div className={style.emailWrapper}>
            <Field
              label={`${t("emailLabel")}*`}
              name="email"
              dependencies={["company"]}
              rules={[
                {
                  required: true,
                  whitespace: true,
                  max: 250,
                  validator: foreignCompany ? emailValidator : emailRuValidator,
                },
              ]}
              placeholder={t("mail")}
            />
            {foreignCompany && <Attention className={style.attention}/>}
          </div>
          {foreignCompany && 
            <Item valuePropName="checked" name="academicDegree">
              <label className={[style.scientist, style.emailForeign].join(" ")}>
                <Text>{t("emailForeign")}</Text>
              </label>
            </Item>
          }
          {needNewField &&
            <Field
              label={`${t("organizationName")}*`}
              name="customCompany"
              rules={[{ required: true, whitespace: true }]}
              placeholder={t("organizationName")}
            />
          }
          <Field
            label={`${t("contactPhoneNumber")}*`}
            name="phone"
            rules={[
              {
                required: true,
                whitespace: true,
                max: 250,
                validator: phoneValidator,
              },
            ]}
            placeholder={t("contactPhoneNumber")}
          />
          <FieldSelect
            label={`${t("typeOfParticipation")}*`}
            name="role"
            placeholder={t("typeOfParticipation")}
            data={staffRoles}
            value="id"
            content="name"
            rules={[{ required: true }]}
            showSearch
          />
          <Field
            label={`${t("responsibilities")}*`}
            name="responsibilities"
            rules={[{ required: true, whitespace: true, max: 30, validator: responsibilitiesValidator }]}
            placeholder={t("specifyTheResponsibilities")}
          />
          {foreignCompany && 
            <>
              <Field
                label={`${t("password")}*`}
                name="password"
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    min: MIN_LEN_PASSWORD,
                  },
                  {
                    validator: passwordValidator,
                  },
                ]}
                type="password"
                placeholder="Пароль"
                autoComplete="new-password"
              />
              <Field
                label={`${t("passwordConfirmation")}*`}
                name="confirm"
                dependencies={['password']}
                type="password"
                placeholder="Подтверждение пароля"
                autoComplete="new-password"
                rules={[
                  {
                    required: true,
                    whitespace: true,
                  },
                  ({ getFieldValue }: GetFieldValue) => ({
                    validator: (rule: RuleObject, value: string) => {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve()
                      }
                      return Promise.reject('Два введенных вами пароля не совпадают!')
                    },
                  }),
                ]}
              />
            </>
          }

          {needCaptcha && <Form.Captcha form={form} />}
          <Item>
            <label>
              <Checkbox onChange={() => setConditionsAreAccepted((pre) => !pre)} />
              <span className={styles.conditions}>{t("iAccept")}</span>
            </label>
            <Link to={'/confidentiality-policy'} target='_blank'>{` ${t("processingConditions")} `}</Link>
            {`${i18n.resolvedLanguage === "Ru" ? "и" : "and"} `}
            <Link to={'/terms-of-service'} target='_blank'>{t("userAgreement")}</Link>
          </Item>
          {middleNameNotEntered &&
            <Item>
              <label>
                <Checkbox onChange={() => setMissingMiddleNameConfirm((pre) => !pre)} />
                <span className={styles.conditions}>{t("notMiddleName")}</span>
              </label>
            </Item>
          }
          <Item>
            <Button 
              disabled={middleNameNotEntered ? conditionsAreAccepted || missingMiddleNameConfirm : conditionsAreAccepted} 
              type="primary" 
              htmlType="submit" 
              className={styles.submitButton}
            >
              {t("register")}
            </Button>
          </Item>
        </Form>
      </Col>
    </Row>
  )
}

export default Staff
