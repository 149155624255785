import { Button, Modal, Typography } from 'antd'
import { UploadFile } from 'antd/es/upload/interface'
import React, { FC, useState } from 'react'
import { getAcceptFormats } from '../../../../../utils/getAcceptFormat'
import { Upload } from '../../../../common'
import './../competence-docs.ant.scss'
import styles from './competence-docs-modal.module.scss'

type PropsCompetenceDocsModal = {
  visibleForm: boolean
  onCloseForm: () => void
  onSaveForm: (files: UploadFile[]) => void
}

const CompetenceDocsAddDocModal: FC<PropsCompetenceDocsModal> = (props) => {
  const { visibleForm, onCloseForm } = props

  const [files, setFiles] = useState<UploadFile[]>([])

  const onSave = () => {
    if (!props.onSaveForm) return

    props.onSaveForm(files)
    onClose()
  }

  const onClose = () => {
    onCloseForm()
    setFiles([])
  }

  const handleUploadFileAdd = (fileList: UploadFile[]) => {
    setFiles([...fileList])
  }

  const handleRemove = (uid: string) => {
    setFiles(files.filter((file) => file.uid !== uid))
  }

  return (
    <Modal
      visible={visibleForm}
      onCancel={onClose}
      destroyOnClose={true}
      maskClosable={true}
      footer={null}
      className={styles.modal}
    >
      <header>
        <Typography.Title level={4}>Загрузить документ</Typography.Title>
      </header>
      <Upload
        files={files}
        onUploadFileAdd={handleUploadFileAdd}
        onRemoveFile={handleRemove}
        tooltipDescription={'Допустимые форматы файлов: ' + getAcceptFormats('file')}
        withoutVideo={true}
        formatFiles="file"
      />
      <footer>
        <Button type={'ghost'} onClick={onSave}>
          Сохранить
        </Button>
        <Button onClick={onClose}>Отменить</Button>
      </footer>
    </Modal>
  )
}

export default CompetenceDocsAddDocModal
