import { Spin } from 'antd'
import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { companiesActions } from '../../../store/slices/companiesSlice'
import { Form, Tabs } from '../../common/index'
import styles from './registration.module.scss'
import { Text } from '../../common/text'
import Exhibition from './exhibition/exhibition'
import Conference from './conference/conference'
import RegistrationTab from './registration-tab/registration-tab'
import { useHistory, useLocation } from 'react-router-dom'
import { ADMIN_REGISTRATION, EXHIBITION_REGISTRATION } from '../../../constants/routes'
import ForAdmin from './for-admin/for-admin'
import Staff from './staff/staff'
import { RootState } from '../../../store/store'
import { useTranslation } from 'react-i18next'

const { useForm } = Form

export interface Props<T> {
  tab: T
  selectTab: (tab: T) => void
}

const { TabPane } = Tabs

const Registration: FC<Props<TabHeader>> = (props) => {
  const { tab, selectTab } = props

  const dispatch = useDispatch()
  const [form] = useForm()
  const [loading, setLoading] = useState(false)
  const [checked, setChecked] = useState('conference')
  const location = useLocation()
  const history = useHistory()

  const { t } = useTranslation()

  const { companiesType } = useSelector((state: RootState) => state.companies)


  useEffect(() => {
    if (tab === 'registration') {
      dispatch(companiesActions.fetchAllCompanies(companiesType))
    }
    return () => {
      form.resetFields()
    }
  }, [form, dispatch, tab, companiesType])

  useEffect(() => {
    if (location.pathname === EXHIBITION_REGISTRATION) {
      setChecked('exhibition')
    }

    return () => {
      if (location.pathname === EXHIBITION_REGISTRATION || location.pathname === ADMIN_REGISTRATION) {
        history.goBack()
      }
    }
  }, [location]) // eslint-disable-line

  return (
    <Spin spinning={loading} className={styles.spin}>
      {location.pathname === ADMIN_REGISTRATION ? (
        <>
          <Text className={styles.text}>Регистрация для администраторов</Text>
          <ForAdmin selectTab={selectTab} setLoading={setLoading} />
        </>
      ) : (
        <div className={styles.subTabsWrapp}>
          <Text className={styles.text}>{t("registerDescription")}</Text>
          <Tabs 
            defaultActiveKey={location.pathname === EXHIBITION_REGISTRATION ? 'exhibition' : 'conference'} 
            onChange={(key) => setChecked(key)} 
            id="subTabs"
          >
            <TabPane
              tab={<RegistrationTab text={t("toConference")} checked={checked} type="conference" />}
              key="conference"
            >
              <Conference selectTab={selectTab} setLoading={setLoading} />
            </TabPane>
            <TabPane
              tab={<RegistrationTab text={t("toExhibition")} checked={checked} type="exhibition" />}
              key="exhibition"
            >
              <Exhibition selectTab={selectTab} setLoading={setLoading} />
            </TabPane>
            <TabPane tab={<RegistrationTab text={t("staff")} checked={checked} type="staff" />} key="staff">
              <Staff selectTab={selectTab} setLoading={setLoading} />
            </TabPane>
          </Tabs>
        </div>
      )}
    </Spin>
  )
}

export default Registration
